import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import FileCopy from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Link, Redirect } from 'react-router-dom';
import  { ItmpH2, ItmpBody } from '../ItmpTypography'
import { fetchSubPageContent, fetchDownloadToken } from '../actions'
import { useSelector, useDispatch } from 'react-redux'
import Parser from 'html-react-parser'
import TanarVagyok from '../TanarVagyok'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';



const SzolgaltatasTartalom = (props)=>{
	const loggedIn = useSelector(state=>Boolean(state.jwt))

	const dispatch = useDispatch()
	const subPageContent = useSelector(state=>state.subPageContent)
	const [snackbarOpen, setSnackbarOpen] = useState(false)
	useEffect(()=>{
		dispatch(fetchSubPageContent(props.pathname.replace("/klub/szolgaltatasok/", "")))
	}, [props.pathname])

	useEffect(()=>{
		if (subPageContent.content && !subPageContent.public) {
			Array.from(document.getElementById("aloldal-tartalom").getElementsByTagName("a")).map(link=>{
				if (link.href.includes("/klub/szolgaltatasok")) {
					link.addEventListener("click", (event)=>{
						if (!link.href.includes("?token")) {
							event.preventDefault()
							dispatch(fetchDownloadToken()).then(res=>{
								let oldUrl = link.href.split("?token")[0]
								let newUrl = oldUrl + "?token=" + res.payload.data.token
								link.href = newUrl
								//link.download = ''
								link.click()
								link.href = oldUrl
							})
						}
					})
				}
			})
		}
	}, [subPageContent.content])

	if (subPageContent.error==401) return <Redirect to={{pathname: '/tanar-vagyok', state: {error: 401}}}/>
	return (
		<Grid container spacing={2}>
				<Grid item xs={12}>
					{loggedIn ? 
						<Link to="/klub/szolgaltatasok">
							<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
								<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
								Vissza a szolgáltatásokhoz
							</Button>
						</Link>
					: null 
					/* <>

						<Typography variant="body1" align="center" color="white" style={{marginTop: '20px', marginBottom: '20px', backgroundColor: "#f55", color: '#fff', borderRadius: "5px", padding: "10px 0"}}>Nem vagy bejelentkezve az ITMP Klubba.<br/> <Link to="/tanar-vagyok" style={{color: '#fff', textDecoration: 'underline'}}>Tudj meg többet a Klubbról!</Link></Typography>
					</> */
					}
				</Grid>
			<Grid item xs={12} md={12} style={{marginBottom: '-10px'}}>
				<ItmpH2 className="kartya-title">{subPageContent.error==404 ? "404" : subPageContent.name ? Parser(subPageContent.name) : null}</ItmpH2>
				{subPageContent.error==404 ? <Typography variant="body1" style={{marginBottom: '40px', color: '#000', fontWeight: '400'}}>A kért oldal nem található.</Typography> : null}
				{subPageContent.error==403 ? 
				<>
					<Typography variant="body1" color="white" style={{}}>
						Még nem jelentkeztél erre a szolgáltatásra.<br/> Jelentkezz most, hogy elérd ezt a tartalmat!
					</Typography>
					<Link to={{pathname: "/klub/szolgaltatasok", state: {autoOpen: subPageContent.id}}}>
						<Button style={{marginTop: '20px', marginBottom: '20px'}} variant="contained" size="large" color="secondary">
							Ugrás a szolgáltatásra
						</Button>
					</Link>
				</>
				: null}
				<div  id="aloldal-tartalom">
					<Typography>
						{subPageContent.content ? Parser(subPageContent.content) : null}
					</Typography>
				</div>
				{subPageContent.public && loggedIn ? 
				<>
				<hr />
				<Typography align="center" style={{marginBottom: "10px", marginTop: '30px'}}>Ezt az oldalt szabadon megoszthatod az alábbi linkkel:</Typography>
				<OutlinedInput
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								onClick={()=>{
									document.getElementById("subpage-url").select()
									document.execCommand("copy")
									setSnackbarOpen(true)
								}}
							>
								<FileCopy />
							</IconButton>
						</InputAdornment>
					}
					value={window.location.href}
					style={{marginBottom: '40px', maxWidth: '100%', width: '600px', left: '50%', transform: 'translateX(-50%)', paddingRight: '5px'}}
					readOnly
					className="readonly-input"
					id="subpage-url"
					inputProps={{style:{textAlign: 'center', color: '#f55'}}}
					onClick={(e)=>{
						document.getElementById("subpage-url").select()
					}}/>
				</>
				:null}
				<Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={(e,r)=>{if (r!=="clickaway")setSnackbarOpen(false)}} message="Vágólapra másolva">
				</Snackbar>
			</Grid>
		</Grid>
	)
}

export default SzolgaltatasTartalom