import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import ItmpLogo from './img/itmp-logo.png'
import TanarVagyokImg from './img/tanar-vagyok.png'

import KlubImg from './img/klub.png'
import CegetKepviselekImg from './img/ceget-kepviselek.png'

import KuldetesunkImg from './img/kuldetesunk.png'
import MimoxImg from './img/mimox.png'
import HttpImg from './img/http.png'
import IvszImg from './img/ivsz.png'
import MicrosoftImg from './img/microsoft.png'
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Menu from '@material-ui/core/Menu';
import AdminIcon from '@material-ui/icons/Settings'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { logout } from './actions'


import Login from './Login'
import Signup from './Signup'
import CegSignup from './CegSignup'
import Klub from './klub/index.js'
import TanarVagyok from './TanarVagyok'
import Cegeknek from './Cegeknek'
import Mentorcegek from './Mentorcegek'
import Kapcsolat from './Kapcsolat'
import Elmenyek from './Elmenyek'
import NotFound from './NotFound'


const Home = (props)=>{
	const menuItems = [
		{name: 'Tanár vagyok', url: '/tanar-vagyok'},
		{name: 'Céget képviselek', url: '/cegeknek'},
		{name: 'Partner cégek', url: '/mentorcegek'},
/*         {name: 'Élmények', url: '/elmenyek'}, */
		{name: 'Kapcsolat', url: '/kapcsolat'},
		/* {name: 'Élmények', url: '/elmenyek'},
		{name: 'Kapcsolat', url: '/kapcsolat'}, */
	]
	const dispatch = useDispatch()
	const pathname = window.location.pathname
	const [loginOpen, setLoginOpen] = useState(false)
	const [resetPasswordRequestSuccessOpen, setResetPasswordRequestSuccessOpen] = useState(false)
	const [signupOpen, setSignupOpen] = useState(false)
	const [cegSignupOpen, setCegSignupOpen] = useState(false)
	const loggedIn = useSelector(state=>Boolean(state.jwt))

	const isAdmin = useSelector(state=>Boolean(state.user.admin))
	
	const [clubDropDownOpen, setClubDropDownOpen] = React.useState(false);
	const [menuOpen, setMenuOpen] = React.useState(false);


	const anchorRef = React.useRef(null);
	const menuRef = React.useRef(null);
	useEffect(()=>{
		if (props.location && props.location.state) {
			if (props.location.state.loginOpen) {
				setLoginOpen(true)
				props.history.replace('/', {...props.location.state, loginOpen: false})
			}
			if (props.location.state.signupOpen) {
				setSignupOpen(true)
				props.history.replace('/', {...props.location.state, signupOpen: false})
			}
		}
	}, [props.location])
	return (
			<>
				<Login passwordResetRequestSuccess={()=>{setLoginOpen(false); setResetPasswordRequestSuccessOpen(true)}} open={loginOpen} onClose={()=>setLoginOpen(false)} openSignup={()=>setSignupOpen(true)} history={props.history} location={props.location}/>
				<Dialog open={resetPasswordRequestSuccessOpen} maxWidth="xs" fullWidth onClose={()=>setResetPasswordRequestSuccessOpen(false)}>
					<DialogTitle>Jelszó helyreállítása</DialogTitle>
					<DialogContent>
						<DialogContentText>
							A jelszavad visszaállításához szükséges lépéseket ellükdtük a megadott email címre.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button size="large" color="secondary" variant="contained" onClick={()=>setResetPasswordRequestSuccessOpen(false)}>
							Bezárás
						</Button>
					</DialogActions>
				</Dialog>
				<Signup open={signupOpen} onClose={()=>setSignupOpen(false)}/>
				<CegSignup open={cegSignupOpen} onClose={()=>setCegSignupOpen(false)}/>
				<AppBar position="fixed" color="primary" style={{zIndex: 1000, maxWidth: '100vw', backgroundColor: '#12485c'}}>
					<Grid container className="container">
						<Grid item xs={12}>
							<Toolbar style={{padding: 0}}>
								<Hidden mdUp>
									<IconButton ref={menuRef} style={{position: 'absolute', left: '-10px', top: '4px'}} onClick={()=>setMenuOpen(!menuOpen)}>
										<MenuIcon style={{color: '#fff'}} />
									</IconButton>
									<Link to="/">
										<img src={ItmpLogo} style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)', height: '40px', top: '10px'}} alt="ITMP"/>
									</Link>
									<Popper open={menuOpen} anchorEl={menuRef.current} transition disablePortal style={{zIndex: 9999}}>
										{({ TransitionProps, placement }) => (
											<Grow
											{...TransitionProps}
											style={{
												transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
											}}
											>
											<Paper style={{width: '180px'}}>
												<ClickAwayListener onClickAway={()=>setMenuOpen(false)}>
												<MenuList>
													{menuItems.map((item, key)=>{
														return  <Link to={item.url} key={key} onClick={()=>setMenuOpen(false)}>
																	<MenuItem selected={pathname===item.url} style={{color: '#000'}}>{item.name}</MenuItem>
																</Link>
													})}
													{!loggedIn ? 
													<Button style={{width: 'calc(100% - 10px)', margin: '5px 5px 0 5px'}} size="large" color="secondary" variant="contained" onClick={()=>{setLoginOpen(true); setMenuOpen(false)}}>ITMP Klub</Button>
													:
													<ButtonGroup style={{width: 'calc(100% - 10px)', margin: '5px 5px 0 5px'}} variant="contained" color="secondary" ref={anchorRef}>
										
														<Button size="large" onClick={()=>{props.history.push('/klub'); setMenuOpen(false)}}>ITMP KLUB</Button>
													
														<Button size="small" onClick={()=>(setClubDropDownOpen(!clubDropDownOpen))}>
															{clubDropDownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
														</Button>
													 </ButtonGroup>}
													<Popper open={clubDropDownOpen} anchorEl={anchorRef.current} transition disablePortal style={{zIndex: 9999}}>
															{({ TransitionProps, placement }) => (
																<Grow
																{...TransitionProps}
																style={{
																	transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
																}}
																>
																<Paper style={{width: '169px'}} style={{zIndex: 10002}} >
																	<ClickAwayListener onClickAway={()=>setClubDropDownOpen(false)}>
																	<MenuList style={{zIndex: 10002}} >
																		<MenuItem
																			onClick={()=>{props.history.push('/klub/mentoralas'); setClubDropDownOpen(false); setMenuOpen(false)}}
																		>
																			Mentorálás
																		</MenuItem>
																		<MenuItem
																			onClick={()=>{props.history.push('/klub/szolgaltatasok'); setClubDropDownOpen(false); setMenuOpen(false)}}
																		>
																			Szolgáltatások
																		</MenuItem>
																		<MenuItem
																			onClick={()=>{props.history.push('/klub/tananyagok'); setClubDropDownOpen(false); setMenuOpen(false)}}
																		>
																			Tananyagok
																		</MenuItem>
																		<MenuItem
																			onClick={()=>{props.history.push('/klub/meetupok'); setClubDropDownOpen(false); setMenuOpen(false)}}
																		>
																			Meetupok
																		</MenuItem>
																		<Divider />
																		<MenuItem
																			onClick={()=>{props.history.push('/klub/profil'); setClubDropDownOpen(false); setMenuOpen(false)}}
																		>
																			<ListItemIcon style={{minWidth: '36px'}}>
																				<AccountCircle />
																			</ListItemIcon>
																			Profil
																		</MenuItem>
																		<MenuItem
																			onClick={()=>{dispatch(logout()); setClubDropDownOpen(false); setLoginOpen(false); setMenuOpen(false)}}
																		>
																			<ListItemIcon style={{minWidth: '36px'}}>
																				<ExitToApp />
																			</ListItemIcon>
																			Kijelentkezés
																		</MenuItem>
																	</MenuList>
																	</ClickAwayListener>
																</Paper>
																</Grow>
															)}
														</Popper>
												</MenuList>
												</ClickAwayListener>
											</Paper>
											</Grow>
										)}
									</Popper>
								</Hidden>
								<Hidden smDown>
								<div style={{flexGrow: 1, position: 'relative'}}>
									<Link to="/">
										<img src={ItmpLogo} style={{height: '50px', marginTop: '3px', marginRight: '10px'}} alt="ITMP"/>
									</Link>
										<div style={{position: 'absolute', top: '-4px', left: '150px'}}>
											{
												menuItems.map((item, key)=>{
													return  <Link to={item.url} key={key}>
																<MenuItem selected={pathname===item.url} style={{display: 'inline-block', height: '64px', lineHeight: '52px', color: "#fff"}}>{item.name}</MenuItem>
															</Link>
												})
											}
										</div>
								</div>
							  
								
								{!loggedIn ? 
									<Button size="large" color="secondary" variant="contained" onClick={()=>setLoginOpen(true)}>ITMP Klub</Button>
									:
									<>
									<ButtonGroup variant="contained" color="secondary" ref={anchorRef}>
										
											<Button size="large" onClick={()=>props.history.push('/klub')}>ITMP KLUB</Button>
										
										<Button size="small" onClick={()=>setClubDropDownOpen(true)}>
											{clubDropDownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
										</Button>
									</ButtonGroup>
									<Popper open={clubDropDownOpen} anchorEl={anchorRef.current} transition disablePortal style={{zIndex: 9999}}>
										{({ TransitionProps, placement }) => (
											<Grow
											{...TransitionProps}
											style={{
												transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
											}}
											>
											<Paper style={{width: '169px'}}>
												<ClickAwayListener onClickAway={()=>setClubDropDownOpen(false)}>
												<MenuList>
													<MenuItem
														onClick={()=>{props.history.push('/klub/mentoralas'); setClubDropDownOpen(false); setMenuOpen(false)}}
													>
														Mentorálás
													</MenuItem>
													<MenuItem
														onClick={()=>{props.history.push('/klub/szolgaltatasok'); setClubDropDownOpen(false); setMenuOpen(false)}}
													>
														Szolgáltatások
													</MenuItem>
													<MenuItem
														onClick={()=>{props.history.push('/klub/tananyagok'); setClubDropDownOpen(false); setMenuOpen(false)}}
													>
														Tananyagok
													</MenuItem>
													<MenuItem
														onClick={()=>{props.history.push('/klub/meetupok'); setClubDropDownOpen(false); setMenuOpen(false)}}
													>
														Meetupok
													</MenuItem>
													<Divider />
													{
														isAdmin ?
														<>
														<MenuItem
															style={{color: '#ff5555', fontWeight: 500}}
															onClick={()=>{props.history.push('/klub/admin'); setClubDropDownOpen(false); setMenuOpen(false)}}
														>
															Admin
															<ListItemIcon style={{minWidth: '36px', position: 'absolute', right: 0}}>
																<AdminIcon style={{color: "#ff5555"}} />
															</ListItemIcon>
														</MenuItem>     
														<Divider /></>: null
													}          
													<MenuItem
														onClick={()=>{props.history.push('/klub/profil'); setClubDropDownOpen(false)}}
													>
														Profil
														<ListItemIcon style={{minWidth: '36px', position: 'absolute', right: 0}}>
															<AccountCircle />
														</ListItemIcon>
													</MenuItem>
													<MenuItem
														onClick={()=>{dispatch(logout()); setClubDropDownOpen(false); setLoginOpen(false)}}
													>
														Kijelentkezés
														<ListItemIcon style={{minWidth: '36px', position: 'absolute', right: 0}}>
															<ExitToApp />
														</ListItemIcon>
													</MenuItem>
												</MenuList>
												</ClickAwayListener>
											</Paper>
											</Grow>
										)}
									</Popper>
									</>
								}
								</Hidden>
								
							</Toolbar>
						</Grid>
					</Grid>
				</AppBar>
				<div style={{width: '100%', backgroundColor: '#fbfbfb', marginTop: '64.5px'}}>
					<Switch>
						<Redirect from="/jelentkezes" to={{pathname: "/", state: {signupOpen: true}}} />
						<Redirect from="/netacademia" to="/klub" />
						<Route exact path="/">
							<Grid container style={{padding: '100px 0 50px 0'}}>
								<Hidden xsDown>
									<Grid item md={2}>
									
									</Grid>
								</Hidden>
								
								<Grid item xs={6} md={3}>
									<Link to="/tanar-vagyok">
										<img src={TanarVagyokImg} className="home-main-img" alt="Tanár vagyok"/>
									</Link>
								</Grid>
								<Hidden xsDown>
									<Grid item md={2} style={{textAlign: 'center', paddingTop: '120px'}}>
										<a href="#kuldetesunk">
											<img src={KuldetesunkImg} className="home-main-img" style={{width: '80%'}} alt="Küldetésünk"/>
										</a>
									</Grid>
								</Hidden>
								<Grid item md={3} xs={6}>
									<Link to="/cegeknek">
										<img src={CegetKepviselekImg} className="home-main-img" alt="Céget képviselek"/>
									</Link>
								</Grid>
								
								<Hidden mdUp>
									<Grid item xs={3}></Grid>
									<Grid item xs={6} style={{textAlign: 'center', paddingTop: '10px'}}>
										<a href="#kuldetesunk">
											<img src={KuldetesunkImg} className="home-main-img" style={{width: '100%'}} alt="Küldetésünk"/>
										</a>
									</Grid>
								</Hidden>
								<Grid item xs={12} style={{padding: '30px 0 0 0'}}>
									<Typography style={{color: '#46c2ce', fontSize: '30px'}} align="center"><b>Közösség az informatikaoktatás megújításáért</b></Typography>
								</Grid>
							</Grid>
							<div style={{width: '100%', backgroundColor: '#f68e54', padding: '40px 0'}}  id="kuldetesunk">
								<Grid container className="container" style={{padding: '30px 10px'}}> 
									<Hidden xsDown>
										<Grid item md={4}>
											<img src={KlubImg} style={{width: '90%', marginTop: '0px'}} alt=""/>
										</Grid>
									</Hidden>
									<Hidden mdUp>
										<Grid item md={4}>
											<img src={KlubImg} style={{width: '100%', marginTop: '0px'}} alt=""/>
										</Grid>
									</Hidden>
									
									<Grid item xs={12} md={8} style={{}} >
										<Typography variant="h2" style={{color: '#fff', fontWeight: '500'}}>Küldetésünk</Typography>
										<Typography variant="body1" align="justify" style={{padding: '30px 10px 0 10px', fontSize: '17px', color: '#fff'}}>
											Az InfoTanár Mentor Programon belül elsődleges célunk, hogy a köznevelésben tanító valamennyi informatika tanár számára inspirációt, támogatást és segítséget nyújtsunk a folyamatosan megújuló szakmai kihívások teljesítéséhez. Ezzel a kezdeményezéssel az új kor követelményeihez alkalmazkodó pedagógus-szakmai-módszertani kérdésekre és az informatikus szakma gyorsuló változásaira szinte valós időben tudunk reagálni. 
											<br />
											<br />
											Fontosnak tartjuk, hogy a jövő informatikusainak képzését már a köznevelésben úgy tudjuk alakítani, hogy az informatikai szakmákhoz szükséges készségeket és tudásanyagot korszerűen adhassák át a tanárok a diákoknak. 
										</Typography>
				
									</Grid>
									<Grid item xs={4}>
									</Grid>
									{/* <Grid item xs={8} style={{padding: '50px 0 0 60px'}}>
										{loggedIn
											? <Button onClick={()=>{props.history.push('/klub'); window.scrollTo(0,0)}} variant="contained" size="large" color="primary" style={{width: '400px', height: '60px', margin: 'auto', display: 'block', fontSize: '20px'}}>Ugrás a Klubba</Button>
											: <Button onClick={()=>setSignupOpen(true)} variant="contained" size="large" color="primary" style={{width: '400px', height: '60px', margin: 'auto', display: 'block', fontSize: '20px'}}>Jelentkezem az ITMP Klubba</Button>
										}
									</Grid> */}
									
								</Grid>
							</div>
							<div style={{width: '100%', backgroundColor: '#eeeded', padding: '40px 0'}}  id="ezt-koszonom">
								<Grid container className="container" style={{padding: '30px 10px'}}> 
									<Grid item xs={12} md={12} style={{}} >
										<Typography variant="h2" align="center" style={{color: '#f68e54', fontWeight: '500'}}>Én ezt köszönöm:</Typography>
										<Typography variant="body1" align="justify" style={{padding: '30px 10px 0 10px', fontSize: '17px', color: '#fff'}}>
											
										</Typography>
										
										<Grid container spacing={2}>
										<Grid item xs={2}></Grid>
										<Grid item xs={8}>
											<Card>
												<CardActionArea>
													<a href="https://youtu.be/vNYTr8LBOAw" target="_blank" rel="noopener noreferrer">
														<CardContent style={{padding: 0}}>
															<iframe style={{marginBottom: '-4px'}} width="100%" height="418" src="https://www.youtube.com/embed/g6Z8d5Tj9zk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
														</CardContent>
													</a>
												</CardActionArea>
											</Card>
										</Grid>
										<Grid item xs={2}></Grid>
										<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/vNYTr8LBOAw" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/merenyi-adam.png" />
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
											</Grid>
											<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/CaW1FwFMB00" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/foti-marcell.png" />
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
											</Grid>
											<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/DVWLP9-w6bk" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/dalos-otto.png" />
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
											</Grid>
											
											<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/OvUGBV6a0wY" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/kiss-tibi.png" />            
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
												
											</Grid>
											<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/ZGOY_RY9pOM" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/jakab-roland.png" />
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
											</Grid>
											<Grid item xs={12} md={4}>
												<Card>
													<CardActionArea>
														<a href="https://youtu.be/9wrdbGUxMAA" target="_blank" rel="noopener noreferrer">
															<CardContent style={{padding: 0}}>
																<img style={{width: '100%', marginBottom: '-4px'}} src="/img/ezt-koszonom/toth-maria.png" />
															</CardContent>
														</a>
													</CardActionArea>
												</Card>
											</Grid>
										   
										</Grid>
										
									</Grid>
									
								</Grid>
							</div>
						</Route>
						<Route exact path="/tanar-vagyok">
							<TanarVagyok location={props.location} setSignupOpen={setSignupOpen} goToKlub={()=>{props.history.push('/klub'); window.scrollTo(0,0)}}/>
						</Route>
						
						<Route exact path="/cegeknek">
							<Cegeknek setSignupOpen={setCegSignupOpen}/>
						</Route>
						<Route path="/klub" component={Klub} />
						<Route path="/mentorcegek" component={Mentorcegek} />
						<Route path="/kapcsolat" component={Kapcsolat} />
						<Route path="/elmenyek" component={Elmenyek} />
						
						<Route component={NotFound} />
						
					</Switch>
				</div>
				<div style={{width: '100%', backgroundColor: '#46c2ce'}}>
					<Grid container className="container" style={{padding: '30px 0'}}>
						<Hidden xsDown>
							<Grid item md={2} />
						</Hidden>
						<Grid item md xs={12}>
							<a href="https://mimox.com/hu/" target="_blank" rel="noopener noreferrer">
								<img src={MimoxImg} style={{display: 'block', margin: '20px auto', width: '180px'}} alt="Mimox"/>
							</a>
						</Grid>
						<Grid item md xs={12}>
							<a href="https://www.http-alapitvany.hu/" target="_blank" rel="noopener noreferrer">
								<img src={HttpImg} style={{display: 'block', margin: '20px auto', width: '150px'}} alt="HTTP Alapítvány"/>
							</a>
						</Grid>
						<Grid item md xs={12}>
							<a href="http://ivsz.hu/" target="_blank" rel="noopener noreferrer">
								<img src={IvszImg} style={{display: 'block', margin: '10px auto 40px', width: '110px'}} alt="IVSZ"/>
							</a>
						</Grid>
						<Hidden xsDown>
							<Grid item md={2} />
						</Hidden>
						<Typography display="block" variant="body1" align="center" style={{color: '#fff', width: '100%'}}>© 2016-{(new Date().getFullYear())} Infotanár Mentor Program</Typography>
					</Grid>
				</div>
			</>
	)
}

export default Home