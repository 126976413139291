import React, { useEffect, forwardRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import { fetchMaterials, newMaterial, deleteMaterial, alterMaterial, newMaterialGroup, deleteMaterialGroup, alterMaterialGroup, reorderMaterialGroups, reorderMaterials } from './actions'
import Close from '@material-ui/icons/Close'
import Delete from '@material-ui/icons/Delete'
import { Redirect, Link } from 'react-router-dom'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import KlubLogoImg from '../../img/klub-logo.png'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import arrayMove from 'array-move'
import IconButton from '@material-ui/core/IconButton'
import { SortableContainer, SortableItem, DragHandle } from './Sortables'

import { useSelector, useDispatch } from 'react-redux'


const AdminTananyagok = (props)=>{

	//const materials = useSelector(s=>s.admin.materials)
	const dispatch = useDispatch()
	const [materialGroups, setMaterialGroups] = useState([])

	useEffect(()=>{
		dispatch(fetchMaterials()).then((res)=>{
			setMaterialGroups(res.payload.data)
		})
	}, [])

	return (
		<>
		<Grid container spacing={2} style={{marginBottom: '30px', position: 'relative'}}>
				<Grid item xs={12}>
					<Link to="/klub/admin">
						<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
							<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
							Vissza
						</Button>
					</Link>
					<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '10px'}}>
						Tananyagok
					</Typography>
					<img src={KlubLogoImg} style={{height: '120px', marginTop: '0', marginLeft: '10%', position: 'absolute', top: '15px', right: 0, zIndex: 100}} alt=""/>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<SortableContainer onSortStart={()=>{}} useDragHandle onSortEnd={({oldIndex, newIndex})=>{
									if (oldIndex===newIndex) return
									let _sGs = [...materialGroups]
									console.log(_sGs)
									_sGs = arrayMove(_sGs, oldIndex, newIndex).map((s, index)=>{
										return {...s, order: index}
									})
									dispatch(reorderMaterialGroups({oldIndex, newIndex}))
									console.log(_sGs)
									setMaterialGroups([])
									setMaterialGroups(_sGs)
									dispatch(fetchMaterials()).then((res)=>setMaterialGroups(res.payload.data))
									//setMaterialGroups([..._sGs])
								}
						}>
							{
								
								[...materialGroups].sort((a, b) => (a.order>b.order) ? 1 : -1).map((materialGroup, index)=>{
									console.log(materialGroup.tags)
									return (
										<>
											
											<SortableItem key={`item-${index}`} index={materialGroup.order}>
												<Grid container spacing={1}>
													<Grid item xs={1}>
														<DragHandle style={{margin: '15px 0 0 40px'}}/>
													</Grid>
													<Grid item xs={11}>
														<Autocomplete
															multiple
															options={[...new Set(materialGroups.reduce((acc, mg)=>{acc = acc.concat(mg.tags && mg.tags.length ? mg.tags.split(";") : []); return [...acc]}, new Array()))]}
															getOptionLabel={(option) => option}
															defaultValue={materialGroup.tags && materialGroup.tags.length ? materialGroup.tags.split(";") : []}
															filterSelectedOptions
															freeSolo
															disableClearable
															onChange={(e, value)=>{
																dispatch(alterMaterialGroup({id: materialGroup.id, tags: value.join(";")}))
															}}
															renderInput={(params) => (
																<TextField
																{...params}
																variant="outlined"
																label="Tagek"
																/>
															)}
														/>
													</Grid>
													<Grid item xs={1}></Grid>
													<Grid item xs={11}>
														<SortableContainer onSortEnd={({oldIndex, newIndex})=>{
															if (oldIndex===newIndex) return
															let _materials = materialGroup.materials
															
															_materials = arrayMove(_materials, oldIndex, newIndex).map((s, index)=>{
																return {...s, order: index}
															})

															let _sGs = [...materialGroups]
															_sGs[materialGroups.findIndex(sG=>sG.id===materialGroup.id)].materials = _materials
															dispatch(reorderMaterials({oldIndex, newIndex, materialGroupId: materialGroup.id}))
															setMaterialGroups([])
															setMaterialGroups(_sGs)
															dispatch(fetchMaterials()).then((res)=>setMaterialGroups(res.payload.data))
														}} onSortStart={()=>{}} useDragHandle>
															{materialGroup.materials.sort((a, b) => (a.order>b.order) ? 1 : -1).map((material, index2)=>{
																return (
																	<SortableItem key={`subitem-${index2}`} index={material.order}>
																		<Grid container style={{margin: '10px 0'}} spacing={1}>
																			<Grid item xs={1}>
																				<DragHandle style={{margin: '7px 0 0 60px'}}/>
																			</Grid>
																			<Grid item xs={5}>
																				<TextField variant="outlined" size="small" fullWidth defaultValue={material.name} onBlur={(e)=>{
																					dispatch(alterMaterial({id: material.id, name: e.target.value}))
																				}}/>
																			</Grid>
																			<Grid item xs={2}>
																				<TextField select variant="outlined" size="small" fullWidth defaultValue={material.type} onBlur={(e)=>{
																					dispatch(alterMaterial({id: material.id, type: e.target.value}))
																				}}>
																					{["pdf", "doc", "ppt", "trello", "video"].map((option) => (
																						<MenuItem key={option} value={option}>
																							{option}
																						</MenuItem>
																					))}
																				</TextField>
																			</Grid>
																			<Grid item xs={3}>
																				<TextField variant="outlined" size="small" fullWidth defaultValue={material.link} onBlur={(e)=>{
																					dispatch(alterMaterial({id: material.id, link: e.target.value}))
																				}}/>
																			</Grid>
																			<Grid item xs={1}>
																			<IconButton style={{}} onClick={()=>{
																					dispatch(deleteMaterial(material.id)).then(()=>dispatch(fetchMaterials()).then((res)=>{
																						setMaterialGroups(res.payload.data)
																					}))
																				}}>
																					<Delete style={{margin: '-5px'}} />
																				</IconButton>
																			</Grid>
																		</Grid>
																	</SortableItem>
																)
															})}
														</SortableContainer>
														<Grid container style={{marginBottom: '20px'}}>
															<Grid item xs={1}></Grid>
															<Grid item>
																<Button onClick={()=>dispatch(newMaterial(materialGroup.id)).then(()=>dispatch(fetchMaterials()).then((res)=>setMaterialGroups(res.payload.data)))} variant="contained" color="secondary" size="small">Hozzáadás</Button>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</SortableItem>
										</>
									)
								})
							}
						</SortableContainer>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button variant="outlined" onClick={()=>dispatch(newMaterialGroup()).then(()=>dispatch(fetchMaterials()))}>Csoport hozzáadása</Button>
				</Grid>
				</Grid>
			</>
	)
}

export default AdminTananyagok