import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import { Link } from 'react-router-dom';
import TananyagokImg from '../img/tananyagok.png'
import  { ItmpH2, ItmpBody } from '../ItmpTypography'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMaterials } from '../actions'

const Tananyagok = ()=>{

	const dispatch = useDispatch()
	const materials = useSelector(state=>state.materials)

	useEffect(()=>{
		dispatch(fetchMaterials())
	}, [])

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Link to="/klub">
					<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
						<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
						Vissza a Klub nyitóoldalára
					</Button>
				</Link>
			</Grid>
			<Grid item xs={12} md={9} style={{marginBottom: '-10px'}}>
				<ItmpH2>Tananyagok</ItmpH2>
				<Typography variant="body1" color="inherit">
					<p>Ahhoz, hogy jól tudjunk oktatni, szükségünk van megfelelő tananyagokra. Az itt található tananyagtartalmakat tanár kollégák bocsátották rendelkezésünkre, hogy bárki szabadon használhassa őket. A tudásmegosztás fontos, köszönjük mindenkinek, aki hasznos tananyagokat oszt meg velünk és segít minket ezen az úton is.</p>
					<p>A tananyagok feltöltése folyamatosan fog történni, addig türelmeteket kérjük.</p>
				</Typography>
			</Grid>
			<Grid item xs={12} md={3}>
					<img src={TananyagokImg} style={{width: '100%', marginTop: '40px'}} alt=""/>
			</Grid>
			<Grid item xs={12} md={12} style={{marginBottom: '-10px'}}>
				<Typography
					color="primary"
					variant="body1"
					style={{ padding: "30px 0 0 10px", fontSize: "23px" }}
				>
					Az informatikai szakképzési kerettantervekkel kapcsolatos legfontosabb jogszabályok
				</Typography>
				<Typography variant="body1">
					<ul className="link-ul">
						<li>
							<a href="https://www.nive.hu/Downloads/Szakkepzesi_dokumentumok/OKJ/DL.php?f=OKJ_150_2012_VII_6_Korm_rend_20160810.xlsx" target="_blank" data-content="https://www.nive.hu/Downloads/Szakkepzesi_dokumentumok/OKJ/DL.php?f=OKJ_150_2012_VII_6_Korm_rend_20160810.xlsx" data-type="external">Szakmák listája a hatályos OKJ szerint (nive.hu)</a>
						</li>
						<li>
							<a href="https://www.nive.hu/Downloads/Szakkepzesi_dokumentumok/rendeletek_es_kozlemenyek/DL.php?f=35_2016_VIII_31_NFM_rendelet.pdf" target="_blank" data-content="https://www.nive.hu/Downloads/Szakkepzesi_dokumentumok/rendeletek_es_kozlemenyek/DL.php?f=35_2016_VIII_31_NFM_rendelet.pdf" data-type="external">Szakmai és vizsgakövetelmények (NFM rendelet, nive.hu)</a>
						</li>
						<li>
							<a href="http://www.kozlonyok.hu/nkonline/MKPDF/hiteles/MK16130-2R.pdf" target="_blank" data-content="http://www.kozlonyok.hu/nkonline/MKPDF/hiteles/MK16130-2R.pdf" data-type="external">A rendelet 1-4. melléklete (kozlonyok.hu)</a>
						</li>
						<li>
							<a href="https://www.nive.hu/Downloads/jogszabalyok/rendeletek/DL.php?f=217-2012_VIII_9_Korm_rendelet.docx" target="_blank" data-content="https://www.nive.hu/Downloads/jogszabalyok/rendeletek/DL.php?f=217-2012_VIII_9_Korm_rendelet.docx" data-type="external">Szakképesítések szakmai követelménymoduljai (modulrendelet, nive.hu)</a>
						</li>
						<li>
							<a href="https://www.nive.hu/kerettanterv/files/DL.php?f=3_melleklet.zip" target="_blank" data-content="https://www.nive.hu/kerettanterv/files/DL.php?f=3_melleklet.zip" data-type="external">Szakgimnáziumokban oktatható alapszakképesítések szakmai kerettantervei (nive.hu)</a>
						</li>
						<li>
							<a href="http://kerettanterv.ofi.hu/20160825_szakgimnazium.doc" target="_blank" data-content="http://kerettanterv.ofi.hu/20160825_szakgimnazium.doc" data-type="external">Közismereti kerettanterv a szakgimnáziumok 9-12. évfolyama számára (ofi.hu)</a>
						</li>
					</ul>
				</Typography>
				<Typography
					color="primary"
					variant="body1"
					style={{ padding: "30px 0 20px 10px", fontSize: "23px" }}
				>
					Tanmenetek, útmutatók, tanári segédanyagok
				</Typography>
				{
					materials.map(materialGroup=>{
						if (!materialGroup.materials.length) return null
						return <>
							
							{materialGroup.tags.split(";").map(tag=>{
								if (!tag.length) return null
								return <Chip
										color={'primary'}
										label={tag}
										style={{marginBottom: '10px',  marginRight: '10px'}}/>
							})}
							<Grid container spacing={2} style={{marginBottom: '30px'}}>
							{materialGroup.materials.sort((a, b) => a.order - b.order).map(material=>{
								return <Grid item md={3} xs={6}>
											<Card style={{height: '100%'}} className="hover-underline">
												<CardActionArea style={{height: '100%'}}>
													<a style={{color: '#000'}} href={material.link} target="_blank" rel="noopener noreferrer">
														<CardContent style={{textAlign: 'center', height: '100%'}}>
															<img src={`/img/tananyagok/${material.type}.png`} alt={material.type} style={{height: '70px', marginBottom: '10px'}}/>
															<Typography variant="body1" align="center">
																{material.name}
															</Typography>
														</CardContent>
													</a>
												</CardActionArea>
											</Card>
										</Grid>
							})}
							</Grid>
						</>
					})
				}
			</Grid>
		</Grid>
	)
}

export default Tananyagok