import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import Profil from './Profil'
import Mentoralas from './Mentoralas'
import Tananyagok from './Tananyagok'
import Meetupok from './Meetupok'
import Szolgaltatasok from './Szolgaltatasok'
import SzolgaltatasTartalom from './SzolgaltatasTartalom'
import Admin from './admin'
import NotFound from '../NotFound'


const Klub = (props)=>{

	const loggedIn = useSelector(state=>Boolean(state.jwt))
	const isAdmin = useSelector(state=>Boolean(state.user.admin))
	if (!loggedIn && !props.location.pathname.startsWith("/klub/szolgaltatasok/") && !props.location.pathname.startsWith("/klub/kartya/")) return <Redirect to="/" />

	return (
		<>	
			<Grid container className="container" style={{zIndex: 50, paddingBottom: '20px'}}>
				<Switch>
					<Route path="/klub" exact>
						<Home />
					</Route>
					<Route path="/klub/profil" exact>
						<Profil />
					</Route>
					<Route path="/klub/mentoralas" exact>
						<Mentoralas />
					</Route>
					<Route path="/klub/szolgaltatasok" exact>
						<Szolgaltatasok location={props.location} />
					</Route>
					<Route path="/klub/szolgaltatasok">
						<SzolgaltatasTartalom pathname={props.location.pathname} />
					</Route>
					<Route path="/klub/tananyagok" exact>
						<Tananyagok />
					</Route>
					<Route path="/klub/meetupok" exact>
						<Meetupok />
					</Route>
					<Route path="/klub/admin">
						{isAdmin ? <Admin /> : <Redirect to="/klub" />}
					</Route>
					<Route path="/klub/kartya/:id" component={AutoOpenKartya} />
					<Route>
						<NotFound />
					</Route>
				</Switch>
			</Grid>
		</>
	)
}

const AutoOpenKartya = (props)=>{
	let id = props.match.params.id
	const loggedIn = useSelector(state=>Boolean(state.jwt))
	if (!loggedIn) return <Redirect to={{pathname: "/", state: {loginOpen: true, autoOpen: id}}} />
	if (id) return <Redirect to={{pathname: "/klub/szolgaltatasok", state: {autoOpen: id}}} />
	else return <NotFound />
}

export default Klub