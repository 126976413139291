import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import People from '@material-ui/icons/People'
import SignUpIcon from '@material-ui/icons/HowToReg'
import CompanyIcon from '@material-ui/icons/Business'

import CegImg from "../../img/ceg.png";
import KlubImg from '../../img/klub.png'
import KlubLogoImg from '../../img/klub-logo.png'
import MentoralasImg from '../../img/mentoralas.png'
import TanfolyamokImg from '../../img/tanfolyamok.png'
import TananyagokImg from '../../img/tananyagok.png'
import MeetupokImg from '../../img/meetupok.png'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'
import { fetchStats } from './actions'

const AdminHome = ()=>{
	const dispatch = useDispatch()
	const stats = useSelector(state=>state.admin.stats)
	useEffect(()=>{
		dispatch(fetchStats())
	}, [])
	return (
		<>
			<Grid container spacing={2} style={{marginBottom: '30px'}}>
				<Grid item xs={12} md={9} style={{}}>
					<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '50px'}}>Adminisztráció</Typography>
					<Typography variant="body1" align="justify" style={{padding: '30px 0 0 10px', fontSize: '17px'}}>
					</Typography>
					<Grid container spacing={1} >
						<Grid item md={4} xs={6}>
							<Card style={{backgroundColor: '#51bfcf', position: 'relative'}}>
								<CardContent style={{padding: '5px 10px 10px 20px'}}>

									<Typography align="right" gutterBottom variant="h2" component="h2" style={{fontWeight: 500, color: '#fff', margin: 0}}>
									&nbsp;
										{
											stats.userCount ? stats.userCount : <Skeleton height={80} width={140} style={{position: 'absolute', right: '12px', top: '-5px'}} />
										}
										
									</Typography>
									<Typography align="right" variant="h5" component="h5" style={{fontWeight: 500, color: '#fff', margin: '-5px 5px 0 0',}}>
										Tanár
									</Typography>
									<People style={{position: 'absolute', color: '#fff', top: '10px', fontSize: '110px', left: '10px'}} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item md={4} xs={6}>
							<Card style={{backgroundColor: '#f28d4f', position: 'relative'}}>
								<CardContent style={{padding: '5px 10px 10px 20px'}}>
									<Typography align="right" gutterBottom variant="h2" component="h2" style={{fontWeight: 500, color: '#fff', margin: 0}}>
										&nbsp;
										{stats.userServiceCount ? stats.userServiceCount : <Skeleton height={80} width={140} style={{position: 'absolute', right: '12px', top: '-5px'}} />}
									</Typography>
									<Typography align="right" variant="h5" component="h5" style={{fontWeight: 500, color: '#fff', margin: '-5px 5px 0 0',}}>
										Jelentkezés
									</Typography>
									<SignUpIcon style={{position: 'absolute', color: '#fff', top: '12px', fontSize: '105px', left: '0px'}} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item md={4} xs={6}>
							<Card style={{backgroundColor: '#15475c', position: 'relative'}}>
								<CardContent style={{padding: '5px 10px 10px 20px'}}>
									<Typography align="right" gutterBottom variant="h2" component="h2" style={{fontWeight: 500, color: '#fff', margin: 0}}>
										&nbsp;
										{stats.companyCount ? stats.companyCount : <Skeleton height={80} width={80} style={{position: 'absolute', right: '12px', top: '-5px'}} />}
									</Typography>
									<Typography align="right" variant="h5" component="h5" style={{fontWeight: 500, color: '#fff', margin: '-5px 5px 0 0',}}>
										Cég
									</Typography>
									<CompanyIcon style={{position: 'absolute', color: '#fff', bottom: '0px', fontSize: '100px', left: '5px'}} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<div style={{margin: '10px 0'}} />
						</Grid>
						<Grid item md={3} xs={6}>
							<Link to="/klub/admin/felhasznalok" onClick={()=>window.scrollTo(0,0)}>
								<Card>
									<CardActionArea>
										<CardContent>
											<img alt="" src={KlubImg} style={{width: '100%', margin: '10px 0'}}/>
											<Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
												Felhasználók
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Link>
						</Grid>
						<Grid item md={3} xs={6}>
							<Link to="/klub/admin/szolgaltatasok" onClick={()=>window.scrollTo(0,0)}>
								<Card>
									<CardActionArea>
										<CardContent>
											<img alt="" src={TanfolyamokImg} style={{width: '100%', margin: '10px 0'}}/>
											<Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
												Szolgáltatások
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Link>
						</Grid>
						<Grid item md={3} xs={6}>
							<Link to="/klub/admin/cegek" onClick={()=>window.scrollTo(0,0)}>
								<Card>
									<CardActionArea>
										<CardContent>
											<img alt="" src={CegImg} style={{width: '100%', margin: '10px 0'}}/>
											<Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
												Cégek
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">				
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Link>
						</Grid>
						<Grid item md={3} xs={6}>
							<Link to="/klub/admin/tananyagok" onClick={()=>window.scrollTo(0,0)}>
								<Card>
									<CardActionArea>
										<CardContent>
											<img alt="" src={TananyagokImg} style={{width: '100%', margin: '10px 0'}}/>
											<Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
												Tananyagok
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">				
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Link>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={3} xs={12}>
					<img src={KlubLogoImg} style={{width: '90%', marginTop: '215px', marginLeft: '5%'}} alt=""/>
				</Grid>
			</Grid>
		</>
	)
}

export default AdminHome