import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import  { ItmpH2, ItmpBody } from '../ItmpTypography'
import MentoralasFolyamatImg from '../img/mentoralas-folyamat.png'
import MentoralasImg from '../img/mentoralas.png'
import { useSelector, useDispatch } from 'react-redux'

const Mentoralas = (props)=>{
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Link to="/klub">
					<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
						<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
						Vissza a Klub nyitóoldalára
					</Button>
				</Link>
			</Grid>
			<Grid item xs={12} md={9} style={{marginBottom: '-10px'}}>
				<ItmpH2>Mentorálás</ItmpH2>
				<Typography variant="body1" color="inherit">
					<p>A mentorálás a cég és a tanár közös szakmai tevékenysége, amelynek során a partnercég lehetőséget biztosít a mentorált tanárnak, hogy legalább egyszer, de akár rendszeres időközönként is ellátogathasson hozzájuk, beszélgessenek, megismerhesse a működésüket, módszereiket, a használt technológiákat, termékeiket, szolgáltatásaikat, munkakultúrájukat.</p>
					<p>Ha szeretnél te is mentorálált lenni, látogasd meg a Szolgáltatás oldalt és jelentkezz!</p>
				</Typography>
			</Grid>
			<Grid item xs={12} md={3}>
					<img src={MentoralasImg} style={{width: '100%', marginTop: '40px'}} alt=""/>
			</Grid>
			<Grid item xs={12}>
				<img src={MentoralasFolyamatImg} style={{width: '100%', marginTop: '0px'}} alt=""/>
			</Grid>
			<Grid item xs={12} style={{padding: '50px 0'}}>
				<Link to="/klub/szolgaltatasok">
					<Button onClick={()=>window.scrollTo(0,0)} variant="contained" size="large" color="secondary" style={{maxWidth: "calc(100vw - 80px)", width: '400px', minHeight: '60px', margin: 'auto', display: 'block', fontSize: '20px'}}>Ugrás a Szolgáltatásokhoz</Button>
				</Link>
			</Grid>
		</Grid>
	)
}

export default Mentoralas