import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import KlubImg from './img/klub.png'
import ItmpLogo from './img/itmp-logo.png'
import ItmpKlubLogo from './img/klub-only-logo.png'
import TanarVagyokImg from './img/tanar.png'

import { useSelector, useDispatch } from 'react-redux'
import { userLogin, fetchProfile, logout, toggleStaySignedIn, userAuthRequest } from './actions'


const PopupAuth = (props) => {

	const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
	const [emailError, setEmailError] = React.useState(null)
	const [passwordError, setPasswordError] = React.useState(false)

	const [postUrl, setPostUrl] = React.useState("")
	const [baseUrl, setBaseUrl] = React.useState("")

	const loggedIn = useSelector(state=>Boolean(state.jwt))
	const staySignedIn = useSelector(state=>state.staySignedIn)


	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search)
		setPostUrl(urlParams.get("post"))
		setBaseUrl(urlParams.get("post").replace("http://", "").replace("https://", "").split("/")[0])
	}, [])

	useEffect(()=>{
        if (loggedIn) dispatch(fetchProfile())
    }, [loggedIn])

	const sendMessage = (message, targetOrigin) => {
		let dev = window.localStorage.dev === "true"
		let infotanariumUrl = dev ? "http://localhost:3000" : targetOrigin
		let target = window.opener
		console.log(message, targetOrigin)
		//alert(infotanariumUrl)
		target.postMessage(message, infotanariumUrl)
	}

    const nickName = useSelector(state=>state.profile.nickName)

	const dispatch = useDispatch()

	const onSubmit = (e)=>{
		e.preventDefault()
		dispatch(userLogin(email, password)).then(res=>{
			if (res.error){
				if (res.error.response.data.message==='WRONG_EMAIL') setEmailError('Helytelen email cím')
				else if (res.error.response.data.message==='NOT_ACTIVATED') setEmailError('Ez a fiók még nincs aktiválva.')
				else if (res.error.response.data.message==='WRONG_PASSWORD') setPasswordError(true)
			} else {

			}
		})
	}

	return (
		<form onSubmit={onSubmit} style={{width: '100%', overflow: 'hidden', backgroundColor: '#fff', minHeight: '100vh', boxSizing: 'border-box'}}>
			<div style={{backgroundColor: '#12485C', height: '52px', width: '100%', textAlign: 'center'}}>
				<img src={ItmpLogo} style={{height: '32px', marginTop: '10px'}} />
			</div>
			<Grid container spacing={2} style={{padding: '20px'}}>
				{ !loggedIn ? 
				<>
					<Grid item xs={12} style={{textAlign: 'center'}}>
						<img src={KlubImg} style={{height: '200px', margin: '-20px 0 -10px 0'}} />
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="h6" component="h2">Bejelentkezés</Typography>
					</Grid>
					<Grid item xs={12}> 
						<TextField 
							label="Email cím"
							variant="outlined"
							margin="none"
							value={email}
							onChange={(e)=>{setEmail(e.target.value); setEmailError(null)}}
							fullWidth
							required
							autoComplete="email"
							error={Boolean(emailError)}
							helperText={emailError}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField 
							label="Jelszó"
							variant="outlined"
							margin="none"
							value={password}
							onChange={(e)=>{setPassword(e.target.value); setPasswordError(false)}}
							fullWidth
							type="password"
							required
							autoComplete="password"
							error={passwordError}
							helperText={(passwordError) ? 'Helytelen jelszó' : null}
						/>
					</Grid>
					<Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
						<FormControlLabel
							control={
								<Checkbox checked={staySignedIn} onChange={()=>dispatch(toggleStaySignedIn())} value={staySignedIn} />
							}
							label={"Maradjak bejelentkezve"}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" variant="contained" fullWidth color="secondary" size="large">
							Bejelentkezés
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} style={{textAlign: 'center', paddingBottom: 0}}>
						<img src={ItmpKlubLogo} style={{height: '55px'}} />
					</Grid>
					</> : <>
						<Grid item xs={12}>
							<Typography style={{fontWeight: 600, fontSize: '25px'}} variant="h6" align="center">
								Üdv, {nickName}!
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								A(z) <b>{baseUrl}</b> webhely hozzáférést kér az ITMP Klub rendszerében szereplő adataidhoz.
							</Typography>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<img src={TanarVagyokImg} style={{height: '230px', marginTop: '-20px'}} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" align="center">
								Ezzel a következő adatokhoz adsz hozzáférést:<br/>
								<b>teljes név, e-mail cím, iskola, település</b>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={() => {
								dispatch(userAuthRequest(postUrl)).then(()=>{
									sendMessage("success", (new URL(postUrl)).origin)
									window.close()
								})
							}} variant="contained" fullWidth color="secondary" size="large">
								Jóváhagyás
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={() => window.close()} variant="outlined" fullWidth color="secondary" size="large">
								Mégsem
							</Button>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center', paddingTop: '15px', opacity: '0.8'}}>
							Nem te vagy {nickName}? <a className="underline" href="#" onClick={()=>dispatch(logout())}>Kijelentkezés</a>
						</Grid>
					</>
					 }
			</Grid>
		</form>
	)
}

export default PopupAuth