import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import MeeutupokImg from '../img/meetupok.png'
import  { ItmpH2, ItmpBody } from '../ItmpTypography'

const Meetupok = ()=>{
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Link to="/klub">
					<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
						<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
						Vissza a Klub nyitóoldalára
					</Button>
				</Link>
			</Grid>
			<Grid item xs={12} md={9} style={{marginBottom: '-10px'}}>
				<ItmpH2>Meetupok</ItmpH2>
				<Typography variant="body1" color="inherit">
				<b>Mi a meetup?</b>
				<br />
				A meetup olyan kötetlen hangulatú szakmai találkozó, ahol általában van néhány rövid előadás, amit kérdések, beszélgetések követnek és leginkább pogácsázás, szendvicsezés, sörözés kíséri. 
				<br />
				<br />
				<b><a href="https://www.meetup.com/Igy-neveld-az-infosodat/">Így neveld az infósodat!</a> meetup sorozatunk </b>
				<br />
				Az Így neveld az infósodat! meetup sorozatot 2016 óta szervezzük, ahol a tanárok és az IT cégek képviselői találkozhatnak egymással, megbeszélhetik egymással problémáikat, szakmai kérdéseiket, nagy fel- és ráismeréseiket. 
				<br />
				<br />
				Sok szeretettel várunk mindenkit, csatlakozzatok a <a href="https://www.meetup.com/Igy-neveld-az-infosodat/">meetup</a> oldalon a ’Join this group’ gombra történő kattintással! 
				</Typography>
			</Grid>
			<Grid item xs={12} md={3}>
					<img src={MeeutupokImg} style={{width: '100%', marginTop: '40px'}} alt=""/>
			</Grid>
		</Grid>
	)
}

export default Meetupok