import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import CegImg from "./img/ceg.png";

const Cegeknek = props => {
  return (
    <Grid container style={{ paddingTop: "50px" }} className="container">
      <Grid item xs={12} md={8} style={{}}>
        <Typography
          variant="h2"
          style={{ color: "#f68e54", fontWeight: "500" }}
        >
          Céget képviselek
        </Typography>
        <Typography
          color="primary"
          variant="body1"
          align="justify"
          style={{ padding: "30px 0 0 10px", fontSize: "23px" }}
        >
          Mit tehetünk mi?
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          style={{ padding: "10px 0 0 10px", fontSize: "17px" }}
        >
          A partner cég lehetőséget biztosít a mentorált tanárnak, hogy legalább
          egyszer, de akár rendszeres időközönként is ellátogathasson hozzájuk,
          beszélgessenek, megismerhessék a működésüket, módszereiket,
          termékeiket, szolgáltatásaikat, munkakultúrájukat.
          <br />
          <br />
          A tanár évente legalább egy napon keresztül részese lesz a vállalati
          működésnek, megtapasztalja milyen is a versenyszféra.
          <br />
          <br />
          Ezen kívül a cég önkéntes alapon, a maga szintjének és erejének
          megfelelően szakmai támogatást ad a mentorált tanárnak és vállalja,
          hogy ezt a kapcsolatot hosszabb időn keresztül fenntartja.
        </Typography>
      </Grid>
      <Hidden xsDown>
        <Grid item md={4}>
          <img
            src={CegImg}
            style={{ marginLeft: "10%", width: "90%", marginTop: "40px" }}
            alt="Céget képviselek"
          />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <img
            src={CegImg}
            style={{ width: "100%", marginTop: "40px" }}
            alt="Céget képviselek"
          />
        </Grid>
      </Hidden>
      <Grid item xs={4}></Grid>
      <Grid item xs={12} style={{ height: "0px" }} />
      <Grid item xs={12} style={{}}>
        <Typography
          color="primary"
          variant="body1"
          align="justify"
          style={{ padding: "30px 0 0 10px", fontSize: "23px" }}
        >
          És ez nekünk miért jó?
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          style={{ padding: "10px 0 0 10px", fontSize: "17px" }}
        >
          A mentoráló cégek:
          <ul>
            <li>
              közvetlen kapcsolatba kerülnek az oktatás szereplőivel egy
              országos hálózaton keresztül és véleményükkel, szakmai
              meglátásukkal közvetlenül befolyásolni is tudják azt, hogy mit és
              hogyan oktatnak a jövő nemzedékének
            </li>
            <li>
              a tanárok mentorálásával egyben motiválttá is teszik őket, akik
              ezt a szakmai segítséget átfordíthatják a gyerekek felé irányuló
              motiváltsággá, jókedvvé, ezáltal segítve elő, hogy kellő számú
              gyerek válassza hivatásául az IT szakmát, és a többiek is pozitív
              érzésekkel viszonyuljanak hozzá
            </li>
            <li>
              közvetlenül hozzájárulhatnak ahhoz, hogy a végzős diákok korszerű
              tudással felszerelkezve lépjenek a munkaerőpiacra és számuk
              megsokszorozódjon, akikből a cégek később megfelelő munkaerőt
              tudnak válogatni.
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ height: "20px" }} />
      <Grid item xs={12}>
        <Typography
          color="primary"
          variant="body1"
          align="justify"
          style={{ padding: "30px 0 0 10px", fontSize: "23px" }}
        >
          Minimum vállalások
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          style={{ padding: "10px 0 0 10px", fontSize: "17px" }}
        >
          A cég lehetővé teszi, hogy évente minimum egyszer a tanár
          ellátogathasson a céghez és részt vegyen egy nap tevékenységében, mint
          megfigyelő. (A cég és tanár párosítását az ITMP végzi, a tanár
          körülményeinek figyelembevételével.)
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          style={{ padding: "10px 0 0 10px", fontSize: "17px" }}
        >
          A mentor:
          <ul>
            <li>
              ezen alkalommal, bemutatja a tanárnak, mit csinálnak, milyen
              terméket/szolgáltatást fejlesztenek, illetve értékesítenek
            </li>
            <li>
              bemutatja, hogyan tartanak értekezletet, hogyan terveznek egy
              termékfunkciót, hogyan írnak üzleti-funkcionális specifikációt,
              technikai-kódolási specifikációt
            </li>
            <li>
              lehetővé teszi a tanár számára, hogy részt vegyen például napi
              „standup”-on (ha pl. Scrum módszertan szerint fejlesztő cégről van
              szó)
            </li>
            <li>
              infrastruktúra vagy projektcég esetén lehetővé teszi, hogy a tanár
              megfigyelje, hogyan terveznek ügyfélnél való megvalósítást,
              beüzemelést, rendszert, hogyan tervezik az erőforrásokat
            </li>
            <li>
              megismerteti a tanárral a versenykörnyezetét (az adott cég kikkel
              versenyez, kik az ügyfelei, mi az, ami versenyelőny, hogyan
              képeznek árat, milyen árbefolyásoló tényezők vannak, milyen
              tendereken/pályázatokon indultak korábban, milyen eredménnyel).
            </li>
            <li>
              elmondja, hogyan kezeli a cég a munkaerő kérdését (milyen munkaerő
              van, mennyiért, mi az, ami nincs, vagy nehezen elérhető, hogyan
              keresnek maguknak embert, mi az, amit maguknak kell megtanítani,
              hogyan megy a betanítás stb.).
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="primary"
          variant="body1"
          align="justify"
          style={{ padding: "30px 0 0 10px", fontSize: "23px" }}
        >
          Opcionális vállalások
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          style={{ padding: "0px 0 0 10px", fontSize: "17px" }}
        >
          A mentoráló cég:
          <ul>
            <li>
              finanszíroz évente egyszer egy 1-2 napos tréninget, vagy online
              kurzust a maga vagy a tanár szakmai területén, pár tízezer
              forintos tanfolyamtól kezdve a pár százezer forintosig bármilyen
              technológiai témában (Scrum/Agile, Python, SQL, Javascript,
              HTML5/CSS3, Azure/AWS, mobilfejlesztés, C#, Java stb.)
            </li>
            <li>
              finanszírozza a tanár számára egy szakmai konferencia belépőjét,
              pl. Craft, HTTP Alapítvány, HWSW, IVSZ, ITBusiness,
              BrainBarBudapest stb. által szervezett konferenciákra (a
              rendezvényre együtt mennek, a cég kapcsolatot biztosít, bemutatja
              a tanárt másoknak, segíti a tanár szakmai network-jének bővítését)
            </li>
            <li>
              közösen részt vesznek szakmai meetup-okon, ahova a cég dolgozói is
              járnak, nem hagyják egyedül a tanárt a kérdéseivel, megbeszélik az
              előadáson hallottakat
            </li>
            <li>
              lehetőséget biztosít a „gyárlátogatásra”, lehetővé teszi, hogy a
              tanár elvigye az osztályát a céghez, hogy a gyerekek belülről
              láthassák egy high-tech cég irodáját, a szakma megszerettetése
              céljából
            </li>
            <li>
              ellátogatnak az iskolába előadást tartani arról, amit csinálnak,
              és ez miért jó - a gyerekek így konkrét felhasználását látják az
              informatikának, lendületes előadókat hallgatva, akik jól
              beszélnek, lelkesek a szakmájukkal kapcsolatban és tudják
              motiválni a tanulókat.
            </li>
          </ul>
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ padding: "50px 0" }}>
        <Button
          onClick={() => props.setSignupOpen(true)}
          variant="contained"
          size="large"
          color="secondary"
          style={{
            maxWidth: "calc(100vw - 80px)",
            width: "400px",
            minHeight: "60px",
            margin: "auto",
            display: "block",
            fontSize: "20px"
          }}
        >
          Regisztrálom a cégemet
        </Button>
      </Grid>
    </Grid>
  );
};

export default Cegeknek;
