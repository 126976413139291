import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import { fetchServices, serviceSignup, cancelService } from '../actions';
import Parser from 'html-react-parser';
import { Chips } from "./Chips";
import { KartyaDialog } from "./KartyaDialog";

export const Kartya = (props) => {
	const service = props.service;
	const dispatch = useDispatch();
	const [dialogOpen, setDialogOpen] = useState(props.autoOpen);
	if (props.autoOpen)
		window.history.replaceState('', null);
	return (<>
		<Card style={{ height: '100%' }}>
			<CardActionArea  style={{ height: '100%' }} onClick={() => setDialogOpen(true)}>
				<div style={{height: '100%'}}>
				<CardMedia image={service.image} title="" style={{ height: '180px' }} />
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2" className="kartya-title">
						{service.name ? Parser(service.name) : null}
					</Typography>
					<Chips service={service} />
					{service.signedUp && !service.onWaitlist && service.signedUpDescription ?
						<><Typography variant="body2" style={{ margin: '15px 0 10px 0' }}>
							{Parser(service.signedUpDescription)}
						</Typography><Divider /></> : null}
					<Typography variant="body2" color="textSecondary" component="p">
						{service.description ? Parser(service.description) : null}
					</Typography>
				</CardContent>
				</div>
			</CardActionArea>
		</Card>
		<KartyaDialog open={dialogOpen} service={service} onClose={() => setDialogOpen(false)} signUp={(waitlistPriority, radioAnswer) => {
			dispatch(serviceSignup(service.id, waitlistPriority, radioAnswer))
				.then(() => dispatch(fetchServices()));
		}} cancelService={() => {
			dispatch(cancelService(service.id))
				.then(() => dispatch(fetchServices()));
		}} />
	</>);
};
