import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export const DialogProgress = (props) => {
	return (props.loading) ?
		<div style={{ position: 'absolute', backgroundColor: props.noBg ? 'auto' : 'rgba(0,0,0,0.3)', width: '100%', height: '100%', zIndex: 1000, ...props.style }}>
			<div style={{ zIndex: 1001, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
				<CircularProgress color="secondary" style={props.circleStyle} />
			</div>
		</div>
    : null
}