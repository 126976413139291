import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

import AdminHome from './AdminHome'
import AdminFelhasznalok from './AdminFelhasznalok'
import AdminCegek from './AdminCegek'
import AdminSzolgaltatasok from './AdminSzolgaltatasok'
import AdminTananyagok from './AdminTananyagok'
import NotFound from './../../NotFound'

const Admin = (props)=>{

	const loggedIn = useSelector(state=>Boolean(state.jwt))
	if (!loggedIn) return <Redirect to="/" />

	return (
		<>
			<Switch>
				<Route exact path="/klub/admin">
					<AdminHome />
				</Route>
				<Route exact path="/klub/admin/felhasznalok">
					<AdminFelhasznalok />
				</Route>
				<Route exact path="/klub/admin/cegek">
					<AdminCegek />
				</Route>
				<Route exact path="/klub/admin/szolgaltatasok">
					<AdminSzolgaltatasok />
				</Route>
				<Route exact path="/klub/admin/tananyagok">
					<AdminTananyagok />
				</Route>
				<Route>
					<NotFound />
				</Route>
			</Switch>
		</>
	)
}

export default Admin