import React from "react";
import Grid from "@material-ui/core/Grid";
import { ItmpH2, ItmpBody } from "./ItmpTypography";

const Elmenyek = props => {
  return (
    <Grid container style={{ paddingTop: "50px" }} className="container">
      <Grid item xs={12} style={{}}>
        <ItmpH2>Élmények</ItmpH2>
        <ItmpBody>
          <p>
            Ezen az oldalon az Infotanár Menor Programban részt vevő kollégák
            élménybeszámolóit tesszük közzé.
          </p>
          <h4>
            <b>
              Amikor a párhuzamos világok mégiscsak találkoznak avagy ne
              pánikolj, nincs rá semmi ok (így utólag !)
            </b>
          </h4>
          <p>
            Sziasztok, röviden szeretnék beszámolni a mentor cégemmel való első
            találkozásomról, mert olyan inspiráló és egyben megnyugtató is volt
            számomra, hogy úgy gondoltam, ezt mindenképpen megosztom
            mindenkivel, aki itt van.
          </p>
          <p>
            A Doctusoft-tal lettem összekapcsolva, ahonnan a HR-es Andreával
            gyors és kedves e-mail váltás után meg is állapodtunk egy
            időpontban, amikor várnak. Ekkor rögtön 3 kissé kellemetlen
            gondolatom is támadt: 1. lesz egy kötelező, udvarias, formális
            „pofavizit,” 2. miután megnéztem a honlapjukat (ami nagyon trendi,
            nagyon profi) kicsit be is rezeltem, hogy milyen kellemetlen lesz a
            sok „okos infós” (udvariasságból nem akarom leírni a kocka szót)
            között szakmailag kis tudású tanárként megjelenni, 3. ráadásul csak
            nagyon halvány elképzelésem volt a felhőalapú fejlesztésről, ami a
            fő profiljuk, és ez még tovább növelte bennem a bizonytalanságot,
            hogy vajon jó lesz-e ez az egész.
          </p>
          <p>
            Nos, a nagyon menő, nekem nagyon is XXI. századi irodába (igazából
            egy irodaház teljes emeletére) lépve az első pillanatban még bennem
            volt az a kellemetlen kis érzés, hogy mit is keresek én itt és vajon
            mit fogunk tudni ebből kihozni?
          </p>
          <p>
            Azonban Stéber Andrea (HR-es) olyan természetességgel és
            kedvességgel vezetett körbe, olyan profin oldotta fel bennem az
            összes feszültséget, hogy azért csak egy nagy köszönet járhat neki.
            Amit elmesélt a cégről, nekem teljesen UFO dolognak tűnt... Nem csak
            azért, mert még nem teljesen látom át ezt az egész felhő alapú
            fejlesztést és nem csak azért, mert azok a gépek, monitorok, irodák
            annyira futurisztikusan korszerűek, hanem azért is, mert az a fajta
            tudásmenedzsment és vállalati kultúra, amit ott kialakítottak
            maguknak, számomra (és talán mondhatom, hogy számunkra, tanárok
            számára) teljesen elképesztő volt. Nagyon sok időt, energiát,
            kapacitást fordítanak arra, hogy szabadon, folyamatosan fejlődjenek
            és egyre jobb, korszerűbb tudásbázissal rendelkezzenek és ezt
            amennyire lehet, szívesen meg is osztják.{" "}
          </p>
          <p>
            Mikor Andrea azt mondta, hogy az egyik vezetővel, Boscha Istvánnal
            is találkozni fogunk, még akkor is azt hittem, hogy egy rövid,
            udvarias bemutatkozás lesz, de egyáltalán nem ez történt.{" "}
          </p>
          <p>
            István olyan kedvesen, barátságosan, ugyanakkor érdeklődve és
            lelkesen állt az egészhez, ami engem teljesen ledöbbentett. Olyan
            mondatok, gondolatok hangzottak el, melyeket én addig csak
            előadásokon, ppt-ken láttam…{" "}
          </p>
          <p>
            Időtávokról, mérföldkövekről, sikerpontokról beszélgettünk, melyeket
            jó lenne elérnünk, minden mondaton, gondolaton az érződött, hogy
            valóban szeretnék, hogy ez az egész sikeres és hatékony legyen…{" "}
          </p>
          <p>
            Talán a legérthetőbb példa erre a mentalitásra az, hogy amikor szóba
            került az iskolákban található gépparkok kora és minősége (amit
            mindannyian ismerünk), akkor István ugyan először kissé
            megrökönyödött, de nem azt mondta, hogy „ááá…. ez így lehetetlen!”,
            hanem a következő pillanatban már azon ment az ötletelés, hogy
            hogyan is lehetne lehetőségeinkhez igazítani mindazt a tudást, amit
            át szeretnének adni.{" "}
          </p>
          <p>
            Ilyen jó hangulatú, hatékony és megoldásközpontú megbeszélésen már
            nagyon régen voltam. De a legjobb az egészben az az élmény és érzés
            volt, hogy azt érezhettem, hogy ők ezt szívesen, természetes
            odafigyeléssel teszik, nem tehernek, hanem érdekes és hasznos
            feladatnak tekintve a mentorálást.
          </p>
          <p>
            Ennek talán legékesebb bizonyítéka az, hogy a tanév végéig 2 heti
            rendszerességgel kaptam fix időpontot hozzájuk, amikor közösen
            dolgozhatunk azon, hogy a kerettantervhez igazodva, korszerű, friss,
            hasznos tudást adhassunk át a diákjainknak.
          </p>
          <p>
            Nézzétek… érzem, hogy ez az egész úgy hangzik, mint egy fizetett
            hirdetés vagy kortes beszéd az Infotanár Mentor Program mellett, de
            higgyétek el, nem az!
          </p>
          <p>
            Mindez tényleg így történt és én azt hiszem, hogy ilyen személyes,
            inspiráló, tudásátadáshoz kapcsolódó élmény nem túl gyakran történik
            meg velünk... : ){" "}
          </p>
          <p>
            <b>Virga Kriszta</b>
          </p>
        </ItmpBody>
      </Grid>
    </Grid>
  );
};

export default Elmenyek;
