import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { verifyActivationCode, setPassword as setPasswordAction } from './actions'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import ItmpLogo from './img/itmp-logo.png'

const useStyles = (error) => {
	return makeStyles ({
		logoArea: {
			width: '100%',
			height: '130px',
			backgroundColor: '#367f8a'
		},
		logo: {
			display: 'block',
			height: '80px',
			margin: '25px 0 0 24px'
		},
		dialogContent: {
			position: 'relative', 
			padding: 0, 
            overflowX: 'hidden',
            height: error ? '435px' : '415px'
		},
		dialogPaper: {
			margin: 0,
			width: 'calc(100% - 20px)',
			maxHeight: 'calc(100% - 20px)'
        },
        staySignedInCheckbox: {
            marginLeft: '-5px'
        },
		gridWrapper: {
            padding: '8px 24px', 
            width: '100%', 
            boxSizing: 'border-box',
        },
		loading: {
			left: 0,
			width: '100%',
			overflow: 'hidden',
			position: 'absolute',
			boxSizing: 'border-box',
			backgroundColor: 'rgba(255, 255, 255, 0.6)', //'rgba(0,0,0,0.35)',
			height: 'calc(100% - 130px)',
			zIndex: '1000',
			top: '130px'
		},
		progress: {
			position: 'absolute',
			left: 'calc(50% - 20px)',
			top: 'calc(50% - 20px)',
		},
	})()
}

const SetPassword = ()=>{
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [passwordAgain, setPasswordAgain] = useState('')
	const [loading, setLoading] = useState(true)
	const [passwordsDontMatch, setPasswordsDontMatch] = useState(false)
	const [error, setError] = useState(false)
	const [done, setDone] = useState(false)

	const dispatch = useDispatch()

	useEffect(()=>{
		let urlParams = new URLSearchParams(window.location.search)
		let activationCode = urlParams.get('q')
		dispatch(verifyActivationCode(activationCode)).then(res=>{
			setLoading(false)
			if (res.error) setError(true)
			else setEmail(res.payload.data.email)
		})
	}, [])

	const onSubmit = (e)=>{
		e.preventDefault()
		if (password!==passwordAgain) return setPasswordsDontMatch(true)
		setLoading(true)
		let urlParams = new URLSearchParams(window.location.search)
		let q = urlParams.get('q')
		dispatch(setPasswordAction(q, password)).then((res)=>{
			setLoading(false)
			if (res.error) return setError(true)
			else setDone(true)
		})
	}
	
	const classes = useStyles(passwordsDontMatch)

	if (done) return <Redirect to="/klub" />

	return (
			<Dialog fullWidth maxWidth="xs" open PaperProps={{className: classes.dialogPaper}}>
				<div className={classes.logoArea}>
					<img src={ItmpLogo} alt="ITMP" className={classes.logo}/>
				</div>
				{loading ? <div className={classes.loading}>
					<CircularProgress className={classes.progress} color="secondary" />
				</div> : null}
				<DialogContent className={classes.dialogContent}>
					<form onSubmit={onSubmit}>
						<div className={classes.gridWrapper}>
								<Grid container spacing={3}>
									{error ? 
									<>
										<Grid item xs={12}>
											<svg width="80px" height="80px" viewBox="0 0 400 400" style={{display: 'block', margin: '30px auto 20px', fill: '#f55'}}>
												<g id="svg_1">
													<path id="svg_2" d="m199.996,0c-110.283,0 -199.996,89.72 -199.996,200s89.713,200 199.996,200s200.004,-89.72 200.004,-200s-89.721,-200 -200.004,-200zm0,373.77c-95.816,0 -173.766,-77.954 -173.766,-173.77c0,-95.817 77.949,-173.769 173.766,-173.769c95.817,0 173.771,77.953 173.771,173.769c0.001,95.816 -77.955,173.77 -173.771,173.77z"/>
													<rect stroke="null" transform="rotate(-45 200,200) " rx="18" id="svg_6" height="38" width="254" y="181.000002" x="73.000001" fill-opacity="null" stroke-opacity="null" stroke-width="NaN" fill="null"/>
													<rect stroke="null" transform="rotate(-135 200,200) " rx="18" id="svg_7" height="38" width="254" y="181.000006" x="73.000009" fill-opacity="null" stroke-opacity="null" stroke-width="NaN" fill="null"/>
												</g>
											</svg>
											<Typography align="center" variant="h5" color="textPrimary" paragraph>
												Helytelen aktivációs kód
											</Typography>
											<Typography align="center" variant="body1" color="textPrimary">
												A link, amire kattintottál, már nem működik.
												<br />
												Amennyiben már hoztál létre jelszót, jelentkezz be a <Link to="/">itmp.hu</Link> oldalon.
												<br />
												<br />
											</Typography>
											<Typography align="center" variant="body1" color="textPrimary">
												Ha problémád akadt, írj az <a href="mailto:info@itmp.hu">info@itmp.hu</a> címre.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Link to="/">
											<Button variant="outlined" fullWidth color="secondary" size="large">
												Vissza az ITMP.hu-ra
											</Button>
											</Link>
										</Grid>
									</>
									:
									<>
									<Grid item xs={12} style={{margin: '20px 0 10px 0'}}>
										<Typography align="center" variant="body1" color="textPrimary">
											Fiókodat sikeresen aktiváltuk.
											<br />
											Kérjük válassz egy biztonságos és erős jelszót.
										</Typography>
									</Grid>
									<Grid item xs={12}> 
										<TextField 
											label="Email cím"
											variant="outlined"
											margin="none"
											value={email}
											fullWidth
											disabled
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField 
											label="Jelszó"
											variant="outlined"
											margin="none"
											value={password}
											onChange={(e)=>{setPassword(e.target.value); setPasswordsDontMatch(false)}}
											fullWidth
											type="password"
											autoComplete="new-password"
											required
											error={passwordsDontMatch}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField 
											label="Jelszó megerősítése"
											variant="outlined"
											margin="none"
											value={passwordAgain}
											onChange={(e)=>{setPasswordAgain(e.target.value); setPasswordsDontMatch(false)}}
											fullWidth
											type="password"
											autoComplete="new-password"
											required
											error={passwordsDontMatch}
											helperText={passwordsDontMatch ? 'A két jelszó nem egyezik meg' : null}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button type="submit" onClick={()=>{}} variant="contained" fullWidth color="secondary" size="large">
											Regisztráció befejezése
										</Button>
									</Grid>
									</>}
							</Grid>
						</div>
					</form>
				</DialogContent>
			</Dialog>
	)
}

export default SetPassword;