import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import KlubImg from '../img/klub.png'
import MentoralasImg from '../img/mentoralas.png'
import TanfolyamokImg from '../img/tanfolyamok.png'
import TananyagokImg from '../img/tananyagok.png'
import MeetupokImg from '../img/meetupok.png'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProfile } from '../actions'

const Home = ()=>{
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchProfile())
    }, [])
    const nickName = useSelector(state=>state.profile.nickName)
    const needsProfileCheck = useSelector(state=>state.profile.needsProfileCheck)
    return (
        <>
            <Grid container spacing={2} style={{marginBottom: '30px'}}>
                <Grid item md={4} xs={12}>
                    <img src={KlubImg} style={{width: '80%', marginTop: '40px', marginLeft: '10%'}} alt=""/>
                </Grid>
                <Grid item xs={12} md={8} style={{}}>
                    <Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '50px'}}>Üdv a Klubban{nickName ? ", "+ nickName : ""}!</Typography>
                    <Typography variant="body1" align="justify" style={{padding: '30px 0 0 10px', fontSize: '17px'}}>
                        Az ITMP Klub egy olyan közösség, amely tagjainak azonos a célja: az informatika oktatás megújítása.  
                        <br />
                        Tanárként és cégként is ugyanazt tartjuk fontosnak, hogy az informatikai oktatás XXI. századivá váljon és a tanárok könnyebben tudják átadni mindazon korszerű ismereteket a diákoknak, amelyekre a jövő munkaerőpiacának szüksége lesz.
                        <br />
                        <br />
                        Az <b>ITMP Klub</b> ennek érdekében <b>kizárólag ingyenes lehetőségeket nyújt</b> az informatika tanárok számára, <b>mert hiszünk abban, hogy a tanár fontos!</b>
                        <br />
                        Fontos, és nem csak azért, mert ő az egyik legmeghatározóbb tudásközvetítő, hanem azért is, mert az ő segítségével és támogatásával nevelődnek ki gyerekeinkből a jövő CEO-i, rendszermérnökei, kávéból is kódot előállító programozói… 
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: '20px'}}>
                <Grid item md={3} xs={6}>
                    <Link to="/klub/mentoralas" onClick={()=>window.scrollTo(0,0)}>
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <img alt="" src={MentoralasImg} style={{width: '100%', margin: '10px 0'}}/>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
                                        Mentorálás
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Link to="/klub/szolgaltatasok" onClick={()=>window.scrollTo(0,0)}>
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <img alt="" src={TanfolyamokImg} style={{width: '100%', margin: '10px 0'}}/>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
                                        Szolgáltatások
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Link to="/klub/tananyagok" onClick={()=>window.scrollTo(0,0)}>
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <img alt="" src={TananyagokImg} style={{width: '100%', margin: '10px 0'}}/>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
                                        Tananyagok
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">

                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Link to="/klub/meetupok" onClick={()=>window.scrollTo(0,0)}>
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <img alt="" src={MeetupokImg} style={{width: '100%', margin: '10px 0'}}/>
                                    <Typography gutterBottom variant="h5" component="h2" style={{fontWeight: 500}}>
                                        Meetupok
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                
            </Grid>
            <Dialog open={needsProfileCheck} maxWidth="xs" fullWidth>
				<DialogTitle>Adatellenőrzés szükséges</DialogTitle>
				<DialogContent>
					<DialogContentText style={{color: 'rgba(0,0,0,0.9)', textAlign: 'justify'}}>
                        <p style={{marginTop: 0}}>
                            Technikai okok miatt a profilodban korábban megadott 'iskola' bejegyzés értékét töröltük.
                        </p>
                        <p>
                            Kérjük, hogy az alábbi gomb megnyomása után megjelenő képernyőn <b>állítsd be, hogy mely oktatási intézményben dolgozol</b>.
                        </p>
                        <p>
                            Immár egy frissített, a szakképzési rendszer átalakulását is tükröző intézménylistából választhatsz.
                        </p>
                        <p>
						    Továbbá kérjük, hogy ellenőrizd meglévő adataid érvényességét is, illetve töltsd ki a hiányzó mezőket, <b>majd kattints a Mentés gombra</b>.
                        </p>
                        <p>
                            Profilodat a későbbiekben is bármikor szerkesztheted.
                        </p>
					</DialogContentText>
                    <Link to="/klub/profil">
						<Button size="large" color="secondary" variant="contained" fullWidth>
							Profil felülvizsgálata
						</Button>
					</Link>
				</DialogContent>
                <DialogActions />
			</Dialog>
        </>
    )
}

export default Home