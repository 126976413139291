export const fetchSchools = ()=>({
	type: 'FETCH_ADMIN_SCHOOLS',
	payload: {
		request: {
			url: '/admin/schools',
			method: 'get'
		}
	}
})

export const fetchUsers = ()=>({
	type: 'FETCH_ADMIN_USERS',
	payload: {
		request: {
			url: '/admin/users',
			method: 'get'
		}
	}
})

export const fetchUser = (id)=>({
	type: 'FETCH_ADMIN_USER',
	payload: {
		request: {
			url: '/admin/users/' + id,
			method: 'get'
		}
	}
})

export const fetchUserUpdate = (userId, user)=>({
	type: 'FETCH_ADMIN_USER_UPDATE',
	payload: {
		request: {
			url: '/admin/users/' + userId,
			method: 'put',
			data: user
		}
	}
})

export const fetchCompanies = ()=>({
	type: 'FETCH_ADMIN_COMPANIES',
	payload: {
		request: {
			url: '/admin/companies',
			method: 'get'
		}
	}
})

export const fetchMaterials = ()=>({
	type: 'FETCH_ADMIN_MATERIALS',
	payload: {
		request: {
			url: '/admin/materials',
			method: 'get'
		}
	}
})

export const newMaterial = (materialGroupId)=>({
	type: 'FETCH_ADMIN_NEW_MATERIALS',
	payload: {
		request: {
			url: '/admin/materials',
			method: 'post',
			data: {materialGroupId}
		}
	}
})

export const deleteMaterial = (id)=>({
	type: 'FETCH_ADMIN_DELETE_MATERIALS',
	payload: {
		request: {
			url: '/admin/materials',
			method: 'delete',
			data: {id}
		}
	}
})

export const alterMaterial = (data)=>({
	type: 'FETCH_ADMIN_ALTER_MATERIALS',
	payload: {
		request: {
			url: '/admin/materials',
			method: 'put',
			data
		}
	}
})


export const newMaterialGroup = ()=>({
	type: 'FETCH_ADMIN_NEW_MATERIALGROUPS',
	payload: {
		request: {
			url: '/admin/materials/groups',
			method: 'post',
			data: {}
		}
	}
})

export const deleteMaterialGroup = (id)=>({
	type: 'FETCH_ADMIN_DELETE_MATERIALGROUPS',
	payload: {
		request: {
			url: '/admin/materials/groups',
			method: 'delete',
			data: {id}
		}
	}
})

export const alterMaterialGroup = (data)=>({
	type: 'FETCH_ADMIN_ALTER_MATERIALGROUPS',
	payload: {
		request: {
			url: '/admin/materials/groups',
			method: 'put',
			data
		}
	}
})

export const reorderMaterials = (data)=>({
	type: 'FETCH_ADMIN_REORDER_MATERIALS',
	payload: {
		request: {
			url: '/admin/materials/reorder',
			method: 'post',
			data
		}
	}
})

export const reorderMaterialGroups = (data)=>({
	type: 'FETCH_ADMIN_REORDER_MATERIALGROUPS',
	payload: {
		request: {
			url: '/admin/materialgroups/reorder',
			method: 'post',
			data
		}
	}
})

export const fetchServices = ()=>({
	type: 'FETCH_ADMIN_SERVICES',
	payload: {
		request: {
			url: '/admin/servicegroups',
			method: 'get'
		}
	}
})

export const fetchService = (id)=>({
	type: 'FETCH_ADMIN_SERVICE',
	payload: {
		request: {
			url: '/admin/services/' + id,
			method: 'get'
		}
	}
})

export const archiveService = (id, archived = true)=>({
	type: 'FETCH_ADMIN_ARCHIVE_SERVICE',
	payload: {
		request: {
			url: '/admin/services/' + id + "/archive",
			method: 'post',
			data: {
				archived
			}
		}
	}
})



export const fetchServiceGroupUpdate = (serviceGroupId, data)=>({
	type: 'FETCH_ADMIN_SERVICEGROUP_UPDATE',
	payload: {
		request: {
			url: '/admin/servicegroups/' + serviceGroupId,
			method: 'put',
			data
		}
	}
})

export const setServiceGroupName = (serviceGroupId, newName)=>({
	type: 'ADMIN_SET_SERVICEGROUP_NAME',
	serviceGroupId,
	newName
})



export const fetchServiceFiles = (id)=>({
	type: 'FETCH_ADMIN_SERVICE_FILES',
	payload: {
		request: {
			url: '/admin/services/' + id + '/files',
			method: 'get'
		}
	}
})

export const saveService = (service)=>({
	type: 'FETCH_ADMIN_SAVE_SERVICE',
	payload: {
		request: {
			url: '/admin/services',
			method: 'put',
			data: service
		}
	}
})

export const fetchNewService = (serviceGroupId)=>({
	type: 'FETCH_ADMIN_NEW_SERVICE',
	payload: {
		request: {
			url: '/admin/services',
			method: 'post',
			data: {serviceGroupId}
		}
	}
})


export const fetchNewServiceGroup = (data)=>({
	type: 'FETCH_ADMIN_NEW_SERVICEGROUP',
	payload: {
		request: {
			url: '/admin/servicegroups',
			method: 'post',
			data
		}
	}
})

export const reorderServices = (data)=>({
	type: 'FETCH_ADMIN_REORDER_SERVICES',
	payload: {
		request: {
			url: '/admin/services/reorder',
			method: 'post',
			data
		}
	}
})

export const reorderServiceGroups = (data)=>({
	type: 'FETCH_ADMIN_REORDER_SERVICEGROUPS',
	payload: {
		request: {
			url: '/admin/servicegroups/reorder',
			method: 'post',
			data
		}
	}
})

export const deleteService = ()=>({
	type: 'FETCH_ADMIN_DELETE_SERVICE',
	payload: {
		request: {
			url: '/admin/services',
			method: 'delete'
		}
	}
})

export const activateUser = (userId)=>({
	type: 'FETCH_ADMIN_ACTIVATE_USER',
	payload: {
		request: {
			url: '/admin/users/activate',
			method: 'post',
			data: {userId}
		}
	}
})

export const disableUser = (userId)=>({
	type: 'FETCH_ADMIN_DISABLE_USER',
	payload: {
		request: {
			url: '/admin/users/disable',
			method: 'post',
			data: {userId}
		}
	}
})

export const deleteUser = (userId)=>({
	type: 'FETCH_ADMIN_DELETE_USER',
	payload: {
		request: {
			url: '/admin/users/delete',
			method: 'post',
			data: {userId}
		}
	}
})

export const deleteUserService = (userId, serviceId)=>({
	type: 'FETCH_ADMIN_DELETE_USER_SERVICE',
	payload: {
		request: {
			url: '/admin/userservice',
			method: 'delete',
			data: {userId, serviceId}
		}
	}
})

export const approveUserService = (userId, serviceId)=>({
	type: 'FETCH_ADMIN_APPROVE_USER_SERVICE',
	payload: {
		request: {
			url: '/admin/userservice/approve',
			method: 'post',
			data: {userId, serviceId}
		}
	}
})

export const rejectUserService = (userId, serviceId)=>({
	type: 'FETCH_ADMIN_REJECT_USER_SERVICE',
	payload: {
		request: {
			url: '/admin/userservice/reject',
			method: 'post',
			data: {userId, serviceId}
		}
	}
})

export const unapproveUserService = (userId, serviceId)=>({
	type: 'FETCH_ADMIN_UNAPPROVE_USER_SERVICE',
	payload: {
		request: {
			url: '/admin/userservice/unapprove',
			method: 'post',
			data: {userId, serviceId}
		}
	}
})

export const uploadServiceImage = (id, data)=>({
	type: 'FETCH_ADMIN_UPLOAD_SERVICE_IMAGE',
	payload: {
		request: {
			url: '/admin/services/' + id + '/upload/image',
			method: 'post',
			data
		}
	}
})

export const uploadServiceFile = (id, data)=>({
	type: 'FETCH_UPLOAD_SERVICE_FILE',
	payload: {
		request: {
			url: '/admin/services/' + id + '/upload',
			method: 'post',
			data
		}
	}
})

export const deleteServiceFile = (serviceId, fileId)=>({
	type: 'FETCH_DELETE_SERVICE_FILE',
	payload: {
		request: {
			url: '/admin/services/' + serviceId + '/files/' + fileId,
			method: 'delete'
		}
	}
})

export const fetchStats = ()=>({
	type: 'FETCH_ADMIN_STATS',
	payload: {
		request: {
			url: '/admin/stats',
			method: 'get'
		}
	}
})