import React from 'react';
import Typography from '@material-ui/core/Typography';

export const ItmpH2 = (props) => {
    return <Typography variant="h2" style={{color: '#f68e54', fontWeight: '500', marginBottom: '15px', ...props.style}} className={props.className?props.className:""}> {props.children} </Typography>
}

export const ItmpBody = (props) => {
    return <Typography variant="body1" align="justify" style={{padding: '30px 0 0 10px', fontSize: '17px', ...props.style}}> {props.children} </Typography>
}
