import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Parser from 'html-react-parser'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import KlubLogoImg from '../../img/klub-logo.png'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, activateUser, deleteUser, disableUser, fetchUserUpdate, fetchUser } from './actions'
import MaterialTable from 'material-table'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import Search from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import InputAdornment from '@material-ui/core/InputAdornment'
import Fuse from 'fuse.js'
import { DialogProgress } from './DialogProgress'

const verifyPersonalId = (pId)=>{
	pId = String(pId)
	let sum = 0
    pId.slice(0,-1).split("").map((n, key)=>{
        sum += (key+1)*Number(n)
    })
	if (sum%11 !== Number(pId.substr(-1)) || pId.length!==11) return false
	else return true
}

const localization = {
	header: {
		actions: ""
	},
	pagination: {
		labelDisplayedRows: '{from}-{to}, összesen {count}',
		labelRowsSelect: "sor"
	},
	body: {
		emptyDataSourceMessage: 'Nincs találat',
		filterRow: {
			filterTooltip: 'Filter'
		}
	},
	toolbar: {
		searchTooltip: "Keresés",
		searchPlaceholder: "Keresés"
	}
}

const AdminFelhasznalok = ()=>{
	const dispatch = useDispatch()
	const users = useSelector(state=>state.admin.users)
	const jwt = useSelector(state=>state.jwt)
	const serverUrl = useSelector(state=>state.serverUrl)
	const [loadingUsers, setLoadingUsers] = useState(false)
	const [selectedUserId, setSelectedUserId] = useState(null)

	const [searchValue, setSearchValue] = useState("")
	const [searcher, setSearcher] = useState(new Fuse(users, {keys: []}))

	useEffect(()=>{
		setLoadingUsers(true)
		dispatch(fetchUsers()).then(()=>{
			setLoadingUsers(false)
		})
	}, [])

	useEffect(()=>{
		let fuse = new Fuse(users.filter(s=>s.activated), {
			keys: ['fullName', 'email']
		})
		setSearcher(fuse)
	}, [users])

	let filteredUsers = searchValue.length ? searcher.search(searchValue).map(u=>u.item) : users
	console.log(searcher.search(searchValue))
	return (
		<>
		<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
			<Grid container spacing={2} style={{marginBottom: '30px', position: 'relative'}}>
				<Grid item xs={12}>
					<Link to="/klub/admin">
						<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
							<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
							Vissza
						</Button>
					</Link>
					<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '10px'}}>
						Felhasználók
						<a href={serverUrl + "/admin/users/export?token="+jwt}>
							<IconButton style={{marginLeft: '20px', padding: '7px 7px 12px 7px'}} variant="outlined" size="large" color="secondary">
								<SaveAlt style={{color: '#f68e54', height: '40px', width: '40px'}} />
							</IconButton>
						</a>
					</Typography>
					<img src={KlubLogoImg} style={{height: '120px', marginTop: '0', marginLeft: '10%', position: 'absolute', top: '15px', right: 0, zIndex: 100}} alt=""/>
				</Grid>
				<Grid item xs={12} md={12} style={{}}>
					<MaterialTable 
						columns={[
							{title: '#', field: 'id', width: 40, type: 'numeric'},
							{
								title: 'Név',
								field: 'fullName',
								width: 200,
							},
							{
								title: 'Email',
								field: 'email',
								width: 280,
								render: (user)=>{return <a href={"mailto:" + user.email}>{user.email}</a>}
							},
							{
								title: 'Telefon',
								field: 'phone',
								width: 130,
								render: (user)=>{return (user.completionCode && !user.schoolOm && !user.personalId) ? <span style={{color: '#fb7400'}}>Regisztráció&nbsp;még&nbsp;nincs&nbsp;befejezve</span> : user.phone}

							},
							{
								title: 'Okt. az.',
								field: 'personalId',
								width: 110,
								cellStyle:(pId)=>{
									return {color: verifyPersonalId(pId) ? '#005a00' : 'red'}
								}
							},
							{
								title: 'OM',
								field: 'schoolOm',
								width: 80,
								cellStyle:(sOm, u)=>{
									return {color:  (u.school&&u.school.om==sOm) ? '#005a00' : 'red'}
								},
								render: (user)=>{
									return (
										<Tooltip title={<span style={{fontSize: '12px', paddingTop: '3px'}}>{"[" + (user.school && user.school.om) + "] " + (user.school && user.school.name)}</span>} interactive arrow style={{maxWidth: 'none'}}>
											<Typography style={{fontSize: '14px', cursor: 'default'}}>{user.schoolOm}</Typography>
										</Tooltip>
									)
								}
							},
						  ]}
						  actions={[
							{
								icon: 'done',
								tooltip: 'Aktiválás',
								onClick: (event, user) => {
									setLoadingUsers(true)
									dispatch(activateUser(user.id)).then(()=>{
										dispatch(fetchUsers()).then(()=>{
											setLoadingUsers(false)
										})
									})
								}
							},
							{
								icon: 'clear',
								tooltip: 'Tiltás',
								onClick: (event, user) => {
									if (window.confirm(user.lastName + " " + user.firstName + " tiltása?")) {
										setLoadingUsers(true)
										dispatch(disableUser(user.id)).then(()=>{
											dispatch(fetchUsers()).then(()=>{
												setLoadingUsers(false)
											})
										})
									}
								}
							},
							{
								icon: 'delete',
								tooltip: 'Törlés',
								onClick: (event, user) => {
									if (window.confirm(user.lastName + " " + user.firstName + " törlése?")) {
										setLoadingUsers(true)
										dispatch(deleteUser(user.id)).then(()=>{
											dispatch(fetchUsers()).then(()=>{
												setLoadingUsers(false)
											})
										})
									}
								}
							},
							{
								icon: 'edit',
								tooltip: 'Szerkesztés',
								onClick: (event, u) => setSelectedUserId(u.id)
							}
						  ]}
						  options={{
							paging: false,
							pageSize: 20,
							tableLayout: "fixed",
							actionsColumnIndex: -1,
							search: false
						  }}
						  data={users.filter(s=>!s.activated&&!s.rejected)}
						  title="Nem aktivált felhasználók"
						  localization={localization}
						  isLoading={users.length===0||loadingUsers}
					/>
					<Paper style={{marginTop: '30px'}}>
					<Grid container style={{padding: '20px 24px'}}>
									<Grid item xs={8}>
										<Typography variant="h6">
											Aktivált felhasználók
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<TextField
											value={searchValue}
											onChange={(e)=>setSearchValue(e.target.value)}
											placeholder="Keresés"
											fullWidth
											style={{marginTop: '-5px'}}
											inputProps={{style:{padding: '10px 15px'}}}
											variant="outlined"
											// eslint-disable-next-line react/jsx-no-duplicate-props
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														{searchValue.length ? 
															<IconButton style={{marginRight: '-13px'}} onClick={()=>setSearchValue("")}>
																<Clear />
															</IconButton>
													:
														<Search style={{color: '#a2a2a2'}} />
														}
													</InputAdornment>
												)
											}}
										/>
									</Grid>
								</Grid>
						<MaterialTable 
							isLoading={users.length===0||loadingUsers}
							style={{marginTop: '30px'}}
							components={{
								Container: props=>(
									<>{props.children}</>
								)
							}}
							columns={[
								{title: '#', field: 'id', width: 40, type: 'numeric'},
								{
									title: 'Név',
									field: 'fullName',
									width: 200
								},
								{
									title: 'Email',
									field: 'email',
									width: 280,
									render: (user)=>{return <a href={"mailto:" + user.email}>{user.email}</a>}
								},
								{
									title: 'Telefon',
									field: 'phone',
									width: 130
								},
								{
									title: 'Intézmény',
									field: 'school.name',
									width: 300,
									cellStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
								}
							]}
							actions={[
								{
									icon: 'clear',
									tooltip: 'Tiltás',
									onClick: (event, user) => {
										if (window.confirm(user.lastName + " " + user.firstName + " tiltása?")) {
											setLoadingUsers(true)
											dispatch(disableUser(user.id)).then(()=>{
												dispatch(fetchUsers()).then(()=>{
													setLoadingUsers(false)
												})
											})
										}
									}
								},
								{
									icon: 'delete',
									tooltip: 'Törlés',
									onClick: (event, user) => {
										if (window.confirm(user.lastName + " " + user.firstName + " törlése?")) {
											setLoadingUsers(true)
											dispatch(deleteUser(user.id)).then(()=>{
												dispatch(fetchUsers()).then(()=>{
													setLoadingUsers(false)
												})
											})
										}
									}
								},
								{
									icon: 'edit',
									tooltip: 'Szerkesztés',
									onClick: (event, u) => setSelectedUserId(u.id)
								}
							]}
							options={{
								paging: true,
								pageSize: 20,
								pageSizeOptions: [5, 10, 20, 500, 2000],
								tableLayout: "fixed",
								actionsColumnIndex: -1,
								search: false,
								toolbar: false
							}}
							data={filteredUsers.filter(s=>s.activated)}
							title="Aktivált felhasználók"
							localization={localization}
						/>
					</Paper>
				</Grid>
			</Grid>
			<EditUserDialog onClose={()=>setSelectedUserId(null)} userId={selectedUserId} open={selectedUserId!==null}/>
		</>
	)
}

const EditUserDialog = (props)=>{
	const users = useSelector(state=>state.admin.users)
	const dispatch = useDispatch()
	useEffect(()=>{
		let user = users.find(u=>u.id==props.userId)
		if (!user) user = {}
		setFirstName(user.firstName)
		setLastName(user.lastName)
		setEmail(user.email)
		setPhone(user.phone)
		setZip(user.zip)
		setCity(user.city)
		setPersonalId(user.personalId)
		setCreatedAt(user.createdAt)
		setActivated(user.activated)
		setRejected(user.rejected)
		setFinalized(user.password)
		if (props.userId) {
			dispatch(fetchUser(props.userId)).then(res => {
				setServices(res.payload.data ? res.payload.data.services : [])
			})
		}
		
		if (typeof user.altalanosIskola!=='undefined') setCheckboxes([user.altalanosIskola, user.gimnazium, user.szakgimnazium, user.szakmaiTargy, user.felsooktatas])
	}, [props.userId, users])
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')
	const [personalId, setPersonalId] = useState('')
	const [createdAt, setCreatedAt] = useState(0)
	const [activated, setActivated] = useState(0)
	const [rejected, setRejected] = useState(0)
	const [finalized, setFinalized] = useState(0)
	const [services, setServices] = useState([])
	const [checkboxes, setCheckboxes] = useState([false,false,false,false,false])
	const [emailTaken, setEmailTaken] = useState(false)

	const [loading, setLoading] = useState(false)

	const saveUser = ()=>{
		setLoading(true)
		dispatch(fetchUserUpdate(props.userId,{
			firstName, 
			lastName,
			email,
			phone,
			zip,
			city,
		})).then(()=>{
			dispatch(fetchUsers()).then(()=>{
				setLoading(false)
				props.onClose()
			})
		})
	}

	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
			<DialogProgress loading={loading} />
			<DialogTitle>Felhasználó szerkesztése</DialogTitle>
				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={5}>
							<Grid container spacing={1}>
								<Grid item xs={6}> 
									<TextField 
										label="Vezetéknév"
										variant="standard"
										margin="none"
										value={lastName}
										onChange={(e)=>{setLastName(e.target.value)}}
										fullWidth
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField 
										label="Utónév"
										variant="standard"
										margin="none"
										value={firstName}
										onChange={(e)=>{setFirstName(e.target.value)}}
										fullWidth
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Email cím"
										variant="standard"
										margin="none"
										value={email}
										onChange={(e)=>{setEmail(e.target.value); setEmailTaken(false)}}
										fullWidth
										type="email"
										error={emailTaken}
										helperText={(emailTaken) ? "Ez az email cím már foglalt." : null}
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Telefonszám"
										variant="standard"
										margin="none"
										value={phone}
										onChange={(e)=>{setPhone(e.target.value)}}
										fullWidth
										type="tel"
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={5}> 
									<TextField 
										label="Irányítószám"
										variant="standard"
										margin="none"
										value={zip}
										onChange={(e)=>{setZip(e.target.value)}}
										fullWidth
										inputProps={{inputMode: "numeric"}}
										type="number"
										onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
								<Grid item xs={7}>
									<TextField 
										label="Település"
										variant="standard"
										margin="none"
										value={city}
										onChange={(e)=>{setCity(e.target.value)}}
										fullWidth
										InputLabelProps={{shrink: true}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={7}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<TextField 
												label="Oktatási azonosító"
												variant="standard"
												margin="none"
												value={personalId}
												fullWidth
												readOnly
												disabled
												InputLabelProps={{shrink: true}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField 
												label="Státusz"
												disabled
												variant="standard"
												margin="none"
												value={
													(activated&&finalized) ? 
														"Aktiválva és regisztrálva"
														: (activated) ? "Aktiválva, nem regisztrálva"
															: (rejected) ? 'Elutasítva' : 'Nincs aktiválva'

												}
												fullWidth
												readOnly
												InputLabelProps={{shrink: true}}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField 
												label="Regisztrált"
												variant="standard"
												margin="none"
												value={new Date(createdAt).toLocaleString()}
												fullWidth
												readOnly
												disabled
												InputLabelProps={{shrink: true}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<TableContainer style={{marginTop: '10px'}}>
										<Table size="small">
											<TableBody>
												{
													[["Általános iskola"], ["Gimnázium"], ["Technikum"], ["Szakmai tárgy"], ["Felsőoktatás"]].map((r,i)=>{
														return (
															<TableRow style={{height: '40.8px'}}>
																<TableCell>{r[0]}</TableCell>
																<TableCell style={{width: '24px'}}>
																	<Checkbox
																		disabled
																		style={{padding: 0}}
																		checked={checkboxes[i]}
																	/>
																</TableCell>
															</TableRow>
														)

													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}> 
								<TableContainer style={{marginTop: '10px'}}>
									<Table size="small" className="services-table">
										<TableHead>
											<TableRow>
												<TableCell style={{width: '10px'}}>#</TableCell>
												<TableCell>Név</TableCell>
												<TableCell style={{width: '170px'}}>Jelentkezés dátuma</TableCell>
												<TableCell align="center">Várólistán</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{services.map((service, index) => (
												<TableRow key={service.id}>
													<TableCell style={{width: '10px'}}>{index+1}</TableCell>
													<TableCell style={{maxWidth: '450px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{Parser(service.name)}</TableCell>
													<TableCell style={{width: '170px'}}>{new Date(service.user_service.createdAt).toLocaleString()}</TableCell>
													<TableCell align="center">{service.user_service.onWaitList ? 'X' : null}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="black" onClick={props.onClose}>
						Mégsem
					</Button>
					<Button variant="contained" color="secondary" onClick={saveUser}>
						Mentés
					</Button>
				</DialogActions>
			</Dialog>
	)
}

export default AdminFelhasznalok