import { createStore, applyMiddleware, compose } from 'redux'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware';

let serverUrl = (localStorage.dev=='true') ? 'http://localhost:5000/api' : 'https://itmp.hu/api'

const axiosClient = axios.create({
	baseURL: serverUrl,
	responseType: 'json'
})

const initialState = {
	emailTaken: null,
	schools: [],
	services: [],
	materials: [],
	subPageContent: {error: false},
	profile: {},
	jwt: (sessionStorage.jwt) ? sessionStorage.jwt : (localStorage.jwt) ? localStorage.jwt : null,
	user: {},
	serverUrl,
	staySignedIn: false,
	serviceFullDialogOpen: false,
	admin: {
		schools: [],
		users: [],
		services: [],
		companies: [],
		materials: [],
		serviceFiles: [],
		stats: {}
	}
}

if (initialState.jwt) initialState.user = JSON.parse(atob(initialState.jwt.split(".")[1]))

const reducer = (state = initialState, action)=>{
	switch (action.type){
		case 'TOGGLE_STAY_SIGNED_IN':
			return {...state, staySignedIn: !state.staySignedIn}
		case 'FETCH_LOGIN_SUCCESS':
				if (state.staySignedIn) localStorage.setItem('jwt', action.payload.data.jwt)
				else sessionStorage.setItem('jwt', action.payload.data.jwt)
			return {...state, jwt: action.payload.data.jwt, user: JSON.parse(atob(action.payload.data.jwt.split(".")[1]))}
		case 'FETCH_SET_PASSWORD_SUCCESS':
			if (state.staySignedIn) localStorage.setItem('jwt', action.payload.data.jwt)
			else sessionStorage.setItem('jwt', action.payload.data.jwt)
			return {...state, jwt: action.payload.data.jwt}
		case 'LOGOUT':
			if (sessionStorage.jwt) sessionStorage.removeItem('jwt')
			if (localStorage.jwt) localStorage.removeItem('jwt')
			return {...state, jwt: null}
		case 'FETCH_CHECK_EMAIL_TAKEN_SUCCESS':
			return {...state, emailTaken: action.payload.data.taken}
		case 'RESET_EMAIL_TAKEN':
			return {...state, emailTaken: null}
		case 'FETCH_SCHOOLS_SUCCESS':
			return {...state, schools: action.payload.data.map(i=>{return {id: i.id, label: `${i.name} (${i.city})`}})}
		case 'FETCH_SERVICES_SUCCESS':
			return {...state, services: action.payload.data}
		case 'FETCH_PROFILE_SUCCESS':
			return {...state, profile: action.payload.data}
		case 'FETCH_SERVICE_SIGNUP_FAIL':
			return {...state, serviceFullDialogOpen: true}
		case 'CLOSE_SERVICE_FULL_DIALOG':
			return {...state, serviceFullDialogOpen: false}
		case 'FETCH_MATERIALS_SUCCESS':
			return {...state, materials: action.payload.data}
		case 'FETCH_SUBPAGE_CONTENT_SUCCESS':
			return {...state, subPageContent: action.payload.data}
		case 'FETCH_SUBPAGE_CONTENT_FAIL':
			return {...state, subPageContent: {error: action.error.response.status, name: (action.error.response.data) ? action.error.response.data.name : null, id: (action.error.response.data) ? action.error.response.data.id : null}}
		case 'FETCH_ADMIN_SCHOOLS_SUCCESS':
			return {...state, admin: {...state.admin, schools: action.payload.data}}
		case 'FETCH_ADMIN_USERS_SUCCESS':
			return {...state, admin: {...state.admin, users: action.payload.data.map(u=>{return {...u, fullName: u.lastName + " " + u.firstName}})}}
		case 'FETCH_ADMIN_COMPANIES_SUCCESS':
			return {...state, admin: {...state.admin, companies: action.payload.data}}
		case 'FETCH_ADMIN_MATERIALS_SUCCESS':
			return {...state, admin: {...state.admin, materials: action.payload.data}}
		case 'FETCH_ADMIN_SERVICES_SUCCESS':
			return {...state, admin: {...state.admin, services: action.payload.data}}
		case 'FETCH_ADMIN_SERVICE_FILES_SUCCESS':
			return {...state, admin: {...state.admin, serviceFiles: action.payload.data}}
		case 'FETCH_ADMIN_STATS_SUCCESS':
			return {...state, admin: {...state.admin, stats: action.payload.data}}
		case 'FETCH_ADMIN_MATERIALS_SUCCESS':
            return {...state, admin: {...state.admin, materials: action.payload.data}}
		case 'ADMIN_SET_SERVICEGROUP_NAME':
			let _services = state.admin.services.map(s=>{
				if (s.id===action.serviceGroupId){
					s.name = action.newName
				}
				return s
			})
			return {...state, admin: {...state.admin, services: _services}}
		default: 
			return state
	}
}

const middlewareConfig = {
	interceptors: {
		request: [
			({ getState }, req)=>{
				req.headers.common['Authorization'] = 'Bearer ' + getState().jwt
				return req
			}
		]
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(
	applyMiddleware(axiosMiddleware(axiosClient, middlewareConfig))
))

export default store