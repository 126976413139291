import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Collapse from '@material-ui/core/Collapse'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'
import TanarImg from '../img/tanar.png'
import DropdownTextField from '../DropdownTextField'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProfile, saveProfile, fetchSchools, fetchCityName } from '../actions'
import { Link } from 'react-router-dom'

const Profil = (props)=>{
	const dispatch = useDispatch()
	const profile = useSelector(state=>state.profile)
	const schools = useSelector(state=>state.schools)

	const [schoolLabel, setSchoolLabel] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(()=>{
		Promise.all([
			dispatch(fetchSchools()),
			dispatch(fetchProfile())
		]).then((res)=>{
			let p = res[1].payload.data
			setEmail(p.email)
			setFirstName(p.firstName)
			setLastName(p.lastName)
			setNickName(p.nickName)
			setPhone(p.phone)
			setZip(p.zip)
			setCity(p.city)
			setSchoolId(p.schoolId)
			
			let school = res[0].payload.data.find(s=>s.id===p.schoolId)
			if (school) setSchoolLabel(`${school.name} (${school.city})`)
			else setSchoolLabel('')
			setAltalanosIskola(p.altalanosIskola)
			setGimnazium(p.gimnazium)
			setSzakgimnazium(p.szakgimnazium)
			setSzakmaiTargy(p.szakmaiTargy)
			setIdenErettsegi(p.idenErettsegi)
			setFelsooktatas(p.felsooktatas)
			

			setLoading(false)
		})
	}, [])



	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [nickName, setNickName] = useState('')
	const [phone, setPhone] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')
	const [schoolId, setSchoolId] = useState(null)
	const [altalanosIskola, setAltalanosIskola] = useState(false)
	const [gimnazium, setGimnazium] = useState(false)
	const [szakgimnazium, setSzakgimnazium] = useState(false)
	const [szakmaiTargy, setSzakmaiTargy] = useState(false)
	const [idenErettsegi, setIdenErettsegi] = useState(false)
	const [felsooktatas, setFelsooktatas] = useState(false)

	const [successDialogOpen, setSuccessDialogOpen] = useState(false)
	const [selectSchoolError, setSelectSchoolError] = useState(false)
	
	const onSubmit = (e)=>{
		e.preventDefault()
		if (!schoolId) return setSelectSchoolError(true)
		dispatch(saveProfile({
			firstName,
			lastName,
			nickName,
			phone,
			zip,
			city,
			schoolId,
			altalanosIskola,
			gimnazium,
			szakgimnazium,
			szakmaiTargy,
			idenErettsegi,
			felsooktatas
		})).then(()=>{
			dispatch(fetchProfile())
			setSuccessDialogOpen(true)
		})
	}

	return (
		<>
			<Grid container spacing={2} style={{marginBottom: '30px'}}>
				<Grid item md={8} xs={12} style={{}}>
					<form onSubmit={onSubmit}>
						<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '50px'}}>
							Saját profil
						</Typography>
						<Typography variant="body1" align="justify" style={{padding: '30px 0 40px 10px', fontSize: '17px'}}>
							Itt láthatod és szerkesztheted az adataidat. Kérjük, hogy ezeket az információkat mindig tartsd naprakészen, mert ez nagyban segíti a mi munkánkat is.
						</Typography>
						<Grid container spacing={2} style={{paddingLeft: '8px', position: 'relative'}}>
							{loading ? 
								<>
								<div style={{width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(255,255,255,0.7)', zIndex: 999}} />
								<div style={{width: '40px', height: '40px', position: 'absolute', left: '50%', top:'50%', zIndex: 1000, transform: 'translate(-50%, -50%)'}}>
									<CircularProgress color="secondary"/>
								</div>
								</> : null}
							<Grid item xs={12}>
								<TextField required value={email} disabled fullWidth variant="outlined" label="Email cím" />
							</Grid>
							<Grid item xs={6}>
								<TextField required value={lastName} onChange={e=>setLastName(e.target.value)} fullWidth variant="outlined" label="Vezetéknév" />
							</Grid>
							<Grid item xs={6}>
								<TextField required value={firstName} onChange={e=>setFirstName(e.target.value)} fullWidth variant="outlined" label="Utónév" />
							</Grid>
							<Grid item xs={12}>
								<TextField required value={nickName} onChange={e=>setNickName(e.target.value)} fullWidth variant="outlined" label="Milyen néven szólíthatunk?" />
							</Grid>
							<Grid item xs={12}>
								<TextField required value={phone} onChange={e=>setPhone(e.target.value)} fullWidth variant="outlined" label="Telefonszám" />
							</Grid>
							<Grid item xs={4}>
								<TextField
									onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
									type="number"
									required
									value={zip}
									onChange={e=>{
										setZip(e.target.value)
										if (e.target.value.length>=4){
											dispatch(fetchCityName(e.target.value)).then(res=>{
												setCity(res.payload.data.name)
											})
										}
									}}
									fullWidth
									variant="outlined"
									label="Irányítószám"
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField required value={city} onChange={e=>setCity(e.target.value)} fullWidth variant="outlined" label="Település" />
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12} style={{position: 'relative'}}>
								{schoolLabel !== null ? 
									<DropdownTextField
										defaultTextValue={schoolLabel}
										options={schools}
										onChange={value=>{setSchoolId(value); setSelectSchoolError(false)}}
										label="Iskola"
										error={selectSchoolError}
										helperText={selectSchoolError ? 'Kérjük, válassz iskolát a listából' : null} />
									:
									<TextField label="Iskola" disabled fullWidth variant="outlined" />
								}
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel control={<Checkbox checked={altalanosIskola} onChange={(e, c) => setAltalanosIskola(c)} color="secondary" />} label="Általános iskolában tanítok" />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel control={<Checkbox checked={gimnazium} onChange={(e, c) => setGimnazium(c)} color="secondary" />} label="Gimnáziumban tanítok" />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel control={<Checkbox checked={szakgimnazium} onChange={(e, c) => {setSzakgimnazium(c); if(!c) {setSzakmaiTargy(false); setIdenErettsegi(false)}}} color="secondary" />} label="Technikumban tanítok" />
							</Grid>
							
							<Grid item xs={12} style={{padding: '0'}}>
								<Collapse in={szakgimnazium}>
									<div style={{padding: '8px'}}>
									<FormControlLabel disabled={!szakgimnazium} control={<Checkbox checked={szakmaiTargy} onChange={(e, c) => {setSzakmaiTargy(c); if(!c) setIdenErettsegi(false)}} color="secondary" />} label="Technikumban szakmai informatikát tanítok" />
									</div>
								</Collapse>
							</Grid>
							{/* <Grid item xs={12}>
								<FormControlLabel disabled={!szakmaiTargy} control={<Checkbox checked={idenErettsegi} onChange={(e, c) => setIdenErettsegi(c)} color="secondary" />} label="Ebben a tanévben (2021/2022) érettségiztetek szakmai informatikából" />
							</Grid> */}
							<Grid item xs={12}>
								<FormControlLabel control={<Checkbox checked={felsooktatas} onChange={(e, c) => setFelsooktatas(c)} color="secondary" />} label="Felsőoktatásban tanítok" />
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Button type="submit" variant="contained" size="large" color="secondary" fullWidth>Mentés</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Hidden xsDown>
				<Grid item xs={4}>
					<img src={TanarImg} style={{
						width: '80%',
						marginTop: '60px',
						marginLeft: '10%'
					}} alt="" />
				</Grid>
				</Hidden>
			</Grid>
			<Dialog open={successDialogOpen} maxWidth="xs" fullWidth>
				<DialogTitle>Sikeres mentés</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Köszönjük, hogy naprakészen tartod adataidat.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="large" color="secondary" variant="outlined" onClick={()=>setSuccessDialogOpen(false)}>
							Vissza a profilomhoz
					</Button>
					<Link to="/klub">
						<Button onClick={()=>window.scrollTo(0,0)} size="large" color="secondary" variant="contained">
							Tovább a Klubba
						</Button>
					</Link>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Profil