import React from "react";
import Typography from "@material-ui/core/Typography";
import { ItmpH2, ItmpBody } from "./ItmpTypography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import TanarImg from "./img/tanar.png";
import KlubImg2 from "./img/klub-2.png";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const TanarVagyok = props => {
  const loggedIn = useSelector(state => Boolean(state.jwt));
  if (props.location.search=="?401")  return <Redirect to={{pathname: '/tanar-vagyok', state: {error: 401}}}/>
  //window.history.replaceState('', null)
  return (
    <Grid container style={{ paddingTop: "50px" }} className="container">
      {(props.location.state&&props.location.state.error==401) ? 
        <Grid item xs={12}>
          <div style={{marginTop: '-20px', marginBottom: '60px', backgroundColor: "#f55", color: '#fff', borderRadius: "5px", padding: "30px 0"}}>
            <Typography variant="h4" align="center">Ez a tartalom csak klubtagok számára elérhető.</Typography>
            <Typography variant="body1" align="center" style={{marginTop: '20px'}}>Regisztrálj most, és csatlakozz az ITMP Klubba!</Typography>
          </div>
        </Grid> : null
      }
      <Hidden xsDown>
        <Grid item md={4}>
          <img
            src={TanarImg}
            style={{ width: "90%", marginTop: "20px" }}
            alt="Tanár vagyok"
          />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <img
            src={TanarImg}
            style={{ width: "100%", marginTop: "40px" }}
            alt="Tanár vagyok"
          />
        </Grid>
      </Hidden>
      <Grid item md={8} xs={12} style={{}}>
        <ItmpH2>Tanár vagyok</ItmpH2>
        <Typography
          variant="body1"
          align="justify"
          style={{ fontSize: "17px" }}
        >
          Informatika tanárnak lenni az egyik legizgalmasabb tanári hivatás, de
          szinte mindannyian éreztük már, hogy:
          <ul>
            <li>betemet a sok újdonság</li>
            <li>
              fejleszteni kellene a tudásunkat, de nincs idő keresgélni,
              készülni
            </li>
            <li>
              nem tudjuk igazán, hol találni hiteles, megfelelő forrásokat
            </li>
            <li>
              mindig csak mondják, vagy valamilyen dokumentumban előírják, hogy
              mit és hogyan kell tanítani, de kevés benne a tapasztalatunk és
              rutinunk
            </li>
            <li>ráadásul mindezekben leginkább csak magunkra számíthatunk.</li>
          </ul>
          <b>Fontos, hogy tudd, hogy nem vagy ebben egyedül</b>, és minderre
          különböző megoldási lehetőségeket találhatsz az InfoTanár Mentor
          Program Klubjában (<b>ITMP Klub</b>)!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ height: "80px" }} />
      <Grid item xs={12} md={8} style={{}}>
        <ItmpH2>ITMP Klub</ItmpH2>
        <ItmpBody>
          <b>
            Az ITMP Klub tagjai olyan informatika szakos tanárok, akik tenni
            akarnak folyamatos szakmai fejlődésükért.
          </b>
          <br />
          <br />A támogatásoknak köszönhetően a klubtagsággal igénybe vehető,{" "}
          <b>teljesen ingyenes szolgáltatások</b> köre reményeink szerint
          folyamatosan bővülni fog, de az alábbiakra mindenképpen számíthatsz:
          <ul>
            <li>
              piacvezető cégek által kidolgozott, egyéni tanulásra alkalmas
              online kurzuscsomagokhoz juthatsz hozzá
            </li>
            <li>
              a legkiválóbb szakemberek által tartott tantermi felkészítésen
              fejlesztheted tovább tudásodat
            </li>
            <li>
              megkaphatod kollégáid kész tananyagait és Te is megoszthatod velük
              a sajátodat
            </li>
            <li>webinárokon, meetupokon és konferenciákon vehetsz részt</li>
            <li>
              kapcsolatba kerülhetsz IT cégekkel, megismerheted
              munkamódszereiket.
            </li>
          </ul>
          Mint írtuk,{" "}
          <b>
            minden szolgáltatásunk teljesen ingyenes informatika tanárok számára
          </b>
          , de szeretnénk, ha az ITMP Klubon keresztül megszerzett tudást és
          tapasztalatot megosztanátok a diákokkal, tanárkollégákkal, és
          beépítenétek oktatási tevékenységetekbe.
          <br />
          <br />
          Az ITMP Klubba egy két perc alatt kitölthető űrlap segítségével
          kérheted a felvételed. A megadott adatok alapján néhány munkaknapon belül ellenőrizzük, hogy
          jogosult vagy-e a tagságra, s ha minden rendben, akkor ezt követően
          már élvezheted is mindazon előnyöket, amelyeket a Klub nyújt.
        </ItmpBody>
      </Grid>
      <Hidden xsDown>
        <Grid item md={4}>
          <img
            src={KlubImg2}
            style={{ marginLeft: "10%", width: "90%", marginTop: "40px" }}
            alt=""
          />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <img
            src={KlubImg2}
            style={{ width: "100%", marginTop: "40px" }}
            alt=""
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} style={{ padding: "50px 0" }}>
        {loggedIn ? (
          <Button
            onClick={() => props.goToKlub()}
            variant="contained"
            size="large"
            color="secondary"
            style={{
              maxWidth: "calc(100vw - 80px)",
              width: "400px",
              height: "60px",
              margin: "auto",
              display: "block",
              fontSize: "20px"
            }}
          >
            Ugrás a Klubba
          </Button>
        ) : (
          <Button
            onClick={() => props.setSignupOpen(true)}
            variant="contained"
            size="large"
            color="secondary"
            style={{
              maxWidth: "calc(100vw - 80px)",
              width: "400px",
              minHeight: "60px",
              margin: "auto",
              display: "block",
              fontSize: "20px"
            }}
          >
            Jelentkezem az ITMP Klubba
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TanarVagyok;
