import React from 'react'
import Grid from '@material-ui/core/Grid'
import  { ItmpH2, ItmpBody } from './ItmpTypography'
import TanarImg from './img/tanar.png'

const Kapcsolat = (props)=>{
	return (
		<Grid container style={{paddingTop: '50px'}} className="container">
			<Grid item md={8} xs={12} style={{}}>
				<ItmpH2>Kapcsolat</ItmpH2>
				<ItmpBody style={{textAlign: 'left', overflow: 'hidden'}}>
					<p>Az Infotanár Mentor Program működtetését a <a className="underline" href="https://mimox.com/" target="_blank">Mimox Kft.</a>
						és a <a className="underline" href="https://http-alapitvany.hu" target="_blank">HTTP Alapítvány</a> munkatársai végzik.
						 </p>
					<p>Kérdés esetén várjuk üzenetedet az <a className="underline" href="mailto:info@itmp.hu">info@itmp.hu</a> e-mail címre.</p>
					<p>

					</p>
						Facebook oldalunk: <a className="underline" href="https://www.facebook.com/groups/infotanarmentorprogram/" target="_main">
						https://www.facebook.com/groups/infotanarmentorprogram/
					
						</a>
					<p>
						Meetup oldalunk: <a className="underline" href="https://www.meetup.com/Igy-neveld-az-infosodat/" target="_main">
						https://www.meetup.com/Igy-neveld-az-infosodat/
						</a>
					</p>
					<p>&nbsp;</p>		


					</ItmpBody>
			</Grid>
			<Grid item md={4} xs={12}>
				<img src={TanarImg} style={{width: '100%', margin: '20px 0 20px 0', transform: 'scaleX(1)'}} alt=""/>
			</Grid>
		</Grid>
	)
}

export default Kapcsolat