import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import Button from '@material-ui/core/Button'
import KlubLogoImg from '../../img/klub-logo.png'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import List from '@material-ui/icons/List'
import Add from '@material-ui/icons/Add'
import Done from '@material-ui/icons/Done'
import Close from '@material-ui/icons/Close'
import CheckCircle from '@material-ui/icons/CheckCircleOutline'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import Cancel from '@material-ui/icons/Cancel'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { fetchCompanies, fetchServices, uploadServiceImage, fetchServiceFiles, uploadServiceFile, deleteServiceFile, saveService, fetchServiceGroupUpdate, setServiceGroupName, fetchNewService, reorderServices, reorderServiceGroups, fetchNewServiceGroup, deleteUserService, approveUserService, rejectUserService, unapproveUserService, fetchService, archiveService } from './actions'
import MaterialTable from 'material-table'
import Parser from 'html-react-parser'
import Divider from '@material-ui/core/Divider'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Clear from '@material-ui/icons/Clear'
import TextEditor from './TextEditor'
import { Kartya } from '../Kartya'
import Dialog from '@material-ui/core/Dialog'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Skeleton from '@material-ui/lab/Skeleton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import FileCopy from '@material-ui/icons/FileCopy'
import Snackbar from '@material-ui/core/Snackbar'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import arrayMove from 'array-move'
import TextField from '@material-ui/core/TextField'
import { DialogProgress } from './DialogProgress';
import { SortableContainer, SortableItem, DragHandle } from './Sortables'


const localization = {
	header: {
		actions: ""
	},
	pagination: {
		labelDisplayedRows: '{from}-{to}, összesen {count}',
		labelRowsSelect: "sor"
	},
	body: {
		emptyDataSourceMessage: 'Nincs találat',
		filterRow: {
			filterTooltip: 'Filter'
		}
	},
	toolbar: {
		searchTooltip: "Keresés",
		searchPlaceholder: "Keresés",
		exportName: "Exportálás CSV-ként"
	}
}

let sgNameTimer = null

const AdminSzolgaltatasok = ()=>{
	const dispatch = useDispatch()
	//const serviceGroups = useSelector(state=>state.admin.services)
	const [serviceGroups, setServiceGroups] = useState([])
	const jwt = useSelector(state=>state.jwt)
	const serverUrl = useSelector(state=>state.serverUrl)
	const [loadingServices, setLoadingServices] = useState(false)
	const [movingServiceGroup, setMovingServiceGroup] = useState(false)
	const [loadingNewService, setLoadingNewService] = useState(false)
	const [selectedService, setSelectedService] = useState({})
	const [selectedServiceSignups, setSelectedServiceSignups] = useState({})
	const [newServiceGroupDialogOpen, setNewServiceGroupDialogOpen] = useState(false)
	const [archivesOpen, setArchivesOpen] = useState(false)


	useEffect(()=>{
		refreshServices()
	}, [])

	const refreshServices = ()=>{
		setLoadingServices(true)
		return new Promise((resolve, reject)=>{
			dispatch(fetchServices()).then((data)=>{
				setServiceGroups(data.payload.data)
				setLoadingServices(false)
				resolve(data)
			})
		})
	}

	const newService = (serviceGroupId)=>{
		setLoadingServices(true)
		setLoadingNewService(serviceGroupId)
		dispatch(fetchNewService(serviceGroupId)).then((res)=>{
			//console.log(res)
			refreshServices().then((data)=>{
				//console.log(data)
				//console.log(serviceGroups.find(g=>g.id===serviceGroupId))
				setSelectedService(data.payload.data.find(g=>g.id===serviceGroupId).services.find(s=>s.id===res.payload.data.id))
				setLoadingNewService(false)
			})
		})
	}

	
	return (
		<>
		<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
			<Grid container spacing={2} style={{marginBottom: '30px', position: 'relative'}}>
				<Grid item xs={12}>
					<Link to="/klub/admin">
						<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
							<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
							Vissza
						</Button>
					</Link>
					
					<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '10px'}}>
						Szolgáltatások
					</Typography>
					<img src={KlubLogoImg} style={{height: '120px', marginTop: '0', marginLeft: '10%', position: 'absolute', top: '15px', right: 0}} alt=""/>
				</Grid>
				<Grid item xs={12} md={12} style={{minHeight: '100px'}}>
					<DialogProgress loading={loadingServices} noBg style={{top: '80px'}}/>
					{/* <Editor
						onEditorStateChange={setEditorState}
						toolbar={{
							options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history'],
							inline: {
								options: ['bold', 'italic', 'underline', 'strikethrough']
							}
						}}
						editorState={editorState}
						toolbarOnFocus={false}
						editorStyle={{border: '1px solid #F1F1F1', padding: '0 10px'}}
					/>
					<textarea
							disabled
							value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
						/> */}
						<SortableContainer onSortStart={()=>{}} useDragHandle onSortEnd={({oldIndex, newIndex})=>{
								if (oldIndex===newIndex) return
								let _sGs = [...serviceGroups]
								_sGs = arrayMove(_sGs, oldIndex, newIndex).map((s, index)=>{
									return {...s, order: index}
								})
								dispatch(reorderServiceGroups({oldIndex, newIndex}))
								setServiceGroups(_sGs)
							}
						}>
							{serviceGroups.sort((a, b) => (a.order>b.order) ? 1 : -1).map((serviceGroup, index)=>{
								return <SortableItem key={`item-${index}`} index={serviceGroup.order}>
									<Grid container style={{marginTop: '40px'}}>
										<Grid item xs={1}>
											<DragHandle style={{margin: '10px 0 0 40px'}}/>
										</Grid>
										<Grid item xs={11} style={{minHeight: '75px'}}>
											<Typography color="primary" variant="body1" align="justify" style={{padding: '0', fontSize: '30px'}}>
												<TextEditor key={`item-editor-${index}-${serviceGroup.id}`} inline className="title-editor" initialValue={serviceGroup.name} onChange={(value)=>{
													window.clearTimeout(sgNameTimer)
													sgNameTimer = window.setTimeout(()=>{
														if (value!==serviceGroup.name){
															dispatch(fetchServiceGroupUpdate(serviceGroup.id, {name: value})).then(()=>{
																dispatch(setServiceGroupName(serviceGroup.id, value))
															})
														}
													}, 3000)
												}}
												onBlur={(e,editor,i)=>{
													let newName = editor.getContent()
													if (newName!==serviceGroup.name){
														dispatch(fetchServiceGroupUpdate(serviceGroup.id, {name: newName})).then(()=>{
															dispatch(setServiceGroupName(serviceGroup.id, newName))
														})
													}
												}}
												/>
											</Typography>
										</Grid>
										<Grid item xs={1}>
										</Grid>
										<Grid item xs={11} style={movingServiceGroup ? {height: 0, overflow: 'hidden'} : {}}>
											
											<SortableContainer useDragHandle distance={10} onSortEnd={({oldIndex, newIndex})=>{
												if (oldIndex===newIndex) return
												let _services = serviceGroup.services
												
												_services = arrayMove(_services, oldIndex, newIndex).map((s, index)=>{
													return {...s, order: index}
												})

												let _sGs = [...serviceGroups]
												_sGs[serviceGroups.findIndex(sG=>sG.id===serviceGroup.id)].services = _services
												dispatch(reorderServices({oldIndex, newIndex, serviceGroupId: serviceGroup.id}))
												setServiceGroups(_sGs)
											}
											}>
											<Grid container spacing={1}>
												<Paper  style={{width: '100%'}}>
												<Table>
													{serviceGroup.services.filter(s=>!s.archived).length ? <TableHead>
														<TableRow>
															<TableCell width={10}>
																
															</TableCell>
															<TableCell width={605}>
																Név
															</TableCell>
															<TableCell width={90}>
																
															</TableCell>
															<TableCell align="center"  width={80}>
																Létszám
															</TableCell>
															<TableCell width={140}>
																
															</TableCell>
														</TableRow>
													</TableHead> : null }
													<TableBody>
												
													{serviceGroup.services.filter(s=>s.id && !s.archived).sort((a, b) => (a.order>b.order) ? 1 : -1).map((service, sIndex)=>{
														return (
															<SortableItem key={service.id} index={service.order}>
																<TableRow>
																	<TableCell style={{padding: '0 0 0 15px'}} width={1}>
																		<DragHandle style={{marginTop: '5px', color: '#6d6d6d'}} />
																	</TableCell>
																	<TableCell className="service-table-title" width={605}>
																		{service.name ? Parser(service.name) : null}
																	</TableCell>
																	<TableCell align="center"  width={90} style={{padding: 0}}>
																		{service.hidden ? <Chip color="secondary" variant="default" label="Rejtett" size="small" style={{marginLeft: '5px'}}/> : null}
																		{!service.hidden ? service.active ? 
																			<Chip color="primary" variant="outlined" label="Elérhető" size="small" style={{marginLeft: '5px'}}/> :
																			<Chip color="secondary" variant="outlined" label="Nem elérhető" size="small" style={{marginLeft: '5px'}}/>
																			: null
																		}
																	</TableCell>
																	<TableCell align="center"  width={80}>
																		{service.userCount}
																	</TableCell>
																	<TableCell align="right" style={{padding: '0 10px 0 0'}}  width={140}>
																		<Tooltip title="Archiválás">
																			<IconButton onClick={() => dispatch(archiveService(service.id)).then(refreshServices)}><ArchiveIcon /></IconButton>
																		</Tooltip>
																		<Tooltip title="Szerkesztés">
																			<IconButton onClick={() => setSelectedService(service)}><Edit /></IconButton>
																		</Tooltip>
																		<Tooltip title="Jelentkezések">
																			<IconButton style={{marginLeft: 0}} onClick={() => setSelectedServiceSignups(service)}><List /></IconButton>
																		</Tooltip>
																	</TableCell>
																</TableRow>
															</SortableItem>
														)
														return (
															<SortableItem key={service.id} index={service.order}>
																{/* <Grid item xs={12}>
																	<Card style={{ height: '100%', maxHeight: '180px', overflow: 'hidden'}}>
																		<CardActionArea>
																			<Grid container>
																				<Grid item xs={2} onClick={() => setSelectedService(service)}>
																					<CardMedia image={service.image} title="" style={{ height: '70px' }} />
																				</Grid>
																				<Grid item xs={8} onClick={() => setSelectedService(service)}>
																					<CardContent style={{paddingBottom: 0}}>
																						<Typography gutterBottom variant="h5" component="h2" className="kartya-title">
																							{service.name ? Parser(service.name) : null}
																						</Typography>
																						<Typography variant="body2" color="textSecondary" component="p">
																							{service.description ? Parser(service.description) : null}
																						</Typography>
																					</CardContent>
																				</Grid>
																				<Grid item xs={2} style={{padding: '10px'}}>
																					<div style={{margin: 'auto'}}>
																					<Typography>

																					</Typography>
																					<Button color="secondary" fullWidth variant="contained">Jelentkezések</Button>
																					</div>
																				</Grid>
																			</Grid>
																		</CardActionArea>
																	</Card>
																</Grid> */}
															</SortableItem>
														)
													})}
													<TableRow>
														<TableCell width={10}>
														</TableCell>
														<TableCell width={616}>
															<Button onClick={()=>newService(serviceGroup.id)} color="secondary" variant="contained">
																<DialogProgress loading={loadingNewService===serviceGroup.id} circleStyle={{marginTop: '7px', width: '25px', height: '25px', color: '#fff'}}/>
																<Add />
																Új szolgáltatás
															</Button>
														</TableCell>
														<TableCell>
															
														</TableCell>
													</TableRow>
													</TableBody>
													</Table>
													</Paper>
												</Grid>	
											</SortableContainer>
											{/* <Grid item xs={12} style={{marginTop: '10px'}}>
												<Card style={{ height: '100%', position:'relative' }}>
													<DialogProgress loading={loadingNewService} />
													<CardActionArea onClick={()=>newService(serviceGroup.id)}>
														<Grid container>
																<CardContent style={{padding: '10px', width: '100%'}}>
																	
																	<Typography variant="h6" align="center" style={{width: '100%'}}>
																		Új szolgáltatás
																	</Typography>
																</CardContent>
														</Grid>
													</CardActionArea>
												</Card>
											</Grid> */}
										</Grid>
										
									</Grid>
									
								</SortableItem>
							})}
						</SortableContainer>
						{loadingServices ? null :
							<>
								<Divider style={{margin: '20px 0'}}/>
								<Button variant="contained" fullWidth color="secondary" size="large" style={{}} onClick={()=>setNewServiceGroupDialogOpen(true)}>Új kategória</Button>
								<Divider style={{margin: '20px 0'}}/>
								<Paper style={{padding: '20px'}}>
									<Typography variant="h5" style={{color: '#f68e54', fontWeight: '500', cursor: 'pointer'}} onClick={()=>setArchivesOpen(!archivesOpen)}>
										Archivált szolgáltatások
										
										<span style={{margin: '20px 10px 0 10px'}}>
											{!archivesOpen ? <ExpandMore /> : <ExpandLess />}
										</span>
									</Typography>
										{archivesOpen ? 
											<Table style={{marginTop: '20px'}}>
												<TableBody>
													{
														serviceGroups.map(serviceGroup => {
															return (<>
																{
																	serviceGroup.services.filter(s=>s.archived).map(service => {
																		return (
																			<TableRow>
																				<TableCell width={20} style={{padding: '0 0 0 15px'}}>
																					<Tooltip title="Visszaállítás">
																						<IconButton onClick={()=>dispatch(archiveService(service.id, false)).then(refreshServices)}><UnarchiveIcon /></IconButton>
																					</Tooltip>
																				</TableCell>
																				<TableCell style={{padding: '0 0 0 15px'}}>
																					{service.name ? Parser(service.name) : null}
																				</TableCell>
																			</TableRow>
																		)
																	})
																}
															</>)
														})
													}
												</TableBody>
											</Table>
										: null}
								</Paper>
							</>
						}
						

				</Grid>
			</Grid>
			<EditServiceDialog refreshServices={refreshServices} open={Boolean(selectedService.id)} service={selectedService} onClose={()=>setSelectedService({})} serviceGroups={serviceGroups.map(s=>{return {id: s.id, name: s.name}})}/>
			<NewServiceGroupDialog refreshServices={refreshServices} open={newServiceGroupDialogOpen} onClose={()=>setNewServiceGroupDialogOpen(false)}/>
			<ServiceSignupsDialog refreshServices={refreshServices} open={Boolean(selectedServiceSignups.id)} service={selectedServiceSignups} onClose={()=>setSelectedServiceSignups({})}/>
		</>
	)
}

const ServiceSignupsDialog = (props)=>{

	const dispatch = useDispatch()
	const [users, setUsers] = useState([])
	const [service, setService] = useState({})
	const [loading, setLoading] = useState(false)
	const jwt = useSelector(state=>state.jwt)
	const serverUrl = useSelector(state=>state.serverUrl)


	const getServiceSignups = ()=>{
		if (props.service.id){
			setLoading(true)
			dispatch(fetchService(props.service.id)).then(res=>{
				let service = res.payload.data
				setService(service)
				setUsers(service.users)
				setLoading(false)
			})
		} else {
			setService({})
			setUsers([])
		}
	}

	useEffect(getServiceSignups, [props.service])
	return (
		<Dialog maxWidth="lg" fullWidth open={props.open} onClose={props.onClose}>
			<DialogProgress loading={loading&&users.length!==0} />
			<DialogTitle className="signups-dialog-title">
				<b>Jelentkezések: </b>{props.service.name ? Parser(props.service.name) : null}
				<a href={serverUrl + "/admin/services/export/" + props.service.id + "?token="+jwt}>
					<Tooltip title="Export letöltése">
						<IconButton style={{margin: '-5px 0px 0px 10px'}} variant="outlined" size="large" color="secondary">
							<SaveAlt style={{color: '#f68e54', height: '30px', width: '30px'}} />
						</IconButton>
					</Tooltip>
				</a>
			</DialogTitle>
			<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>{props.onClose()}}>
				<Clear />
			</IconButton>
			<DialogContent style={{overflowY: loading&&users.length===0 ? 'hidden' : 'scroll'}}>
				<TableContainer>
				<Table size="small">
						<TableHead>
							<TableCell>#</TableCell>
							<TableCell>Név</TableCell>
							<TableCell>Becenév</TableCell>
							{/* <TableCell><b>Intézmény</b></TableCell> */}
							<TableCell>Email</TableCell>
							<TableCell>Telefon</TableCell>
							<TableCell width={160}>Jelentkezés dátuma</TableCell>
							{service.radio ? <TableCell>Radio Válasz</TableCell> : null}
							{service.waitlistAvailable ? <TableCell>Várólistán</TableCell> : null}
							{service.waitlistPriorityAvailable ? <TableCell>Prioritás</TableCell> : null}
							<TableCell width={service.needsApproval ? 160 : 30}></TableCell>
						</TableHead>
						<TableBody>
							{loading&&users.length===0 ? 
								[...Array(20).keys()].map((x, index) => {
									return (
										<TableRow>
											{[...Array(7).keys()].map((y, index2) => {
												return <TableCell><Skeleton /></TableCell>;
											})}
										</TableRow>
									);
								}) : null
							}
						{
							users.sort((a, b)=>{return new Date(a.user_service.createdAt) - new Date(b.user_service.createdAt)})
							.map((user, key)=>{
								return <tr key={key} style={service.needsApproval ? {backgroundColor: (user.user_service.approved) ?  "rgba(0, 90, 0, 0.05)" : (user.user_service.rejected) ? "rgba(255,0,0,0.05)" : "initial"} : (user.user_service.onWaitlist ? {backgroundColor: 'rgba(0,0,0,0.1)'} : {})}>
									<TableCell>{key+1}.</TableCell>
									<TableCell><Link to={"/admin/user/" + user.id}>{user.lastName} {user.firstName}</Link></TableCell>
									<TableCell>{user.nickName}</TableCell>
									{/* <TableCell>{user.school ? user.school.name : null}</TableCell> */}
									<TableCell><a href={"mailto:" + user.email}>{user.email}</a></TableCell>
									<TableCell>{user.phone}</TableCell>
									<TableCell>{new Date(user.user_service.createdAt).toLocaleString()}</TableCell>
									{service.radio ?
										<TableCell>
											{service["radio" + user.user_service.radioAnswer + "Text"]}
										</TableCell>
									: null}
									{service.waitlistAvailable ? <TableCell align="center">{user.user_service.onWaitlist ? 'X' : null}</TableCell> : null}
									{service.waitlistPriorityAvailable ? <TableCell align="center">{user.user_service.waitlistPriority ? 
										<span style={{color: "rgba(0, 90, 0, 0.8)"}}>Igen</span>
										: <span style={{color: "rgba(255, 0, 0, 0.8)"}}>Nem</span> }</TableCell> : null}
									<TableCell style={{padding: '0 10px 0 0'}}>
										{service.needsApproval ? <>
											<Tooltip title="Jóváhagyás">
												<IconButton style={{padding: '4px'}} onClick={()=>{
														dispatch(user.user_service.approved ? unapproveUserService(user.id, service.id) : approveUserService(user.id, service.id)).then(res=>{
															let _users = [...users]
															let _user = _users.find(u=>u.id == user.id)
															_user.user_service.approved = res.payload.data.approved
															_user.user_service.rejected = res.payload.data.rejected
															setUsers(_users)
														})
												}}>
													{user.user_service.approved ? <CheckCircle style={{color: "rgba(0, 90, 0, 0.9)", transform: "scale(1.2)"}} /> : <Done />}
												</IconButton>
											</Tooltip>
											<Tooltip title="Elutasítás">
												<IconButton style={{padding: '4px'}} onClick={()=>{
														dispatch(user.user_service.rejected ? unapproveUserService(user.id, service.id) : rejectUserService(user.id, service.id)).then(res=>{
															let _users = [...users]
															let _user = _users.find(u=>u.id == user.id)
															_user.user_service.approved = res.payload.data.approved
															_user.user_service.rejected = res.payload.data.rejected
															setUsers(_users)
														})
												}}>
													{user.user_service.rejected ? <CancelOutlined style={{color: "rgba(255, 0, 0, 0.8)", transform: "scale(1.2)"}} /> : <Close />}
												</IconButton>	
											</Tooltip>
										</> : null}
										<Tooltip title="Törlés">
											<IconButton style={{padding: '4px'}} onClick={()=>{
												if (window.confirm(user.lastName + " " + user.firstName + " jelentkezésének törlése?")){
													dispatch(deleteUserService(user.id, service.id)).then(getServiceSignups)
													
												}
											}}>
												<Delete style={{}} />
											</IconButton>
										</Tooltip>
									</TableCell>
								</tr>
							})
						}
						</TableBody>
					</Table>
					</TableContainer>
			</DialogContent>
		</Dialog>
	)
}

const NewServiceGroupDialog = (props)=>{

	const [name, setName] = useState("Új kategória")
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	return (
		<Dialog maxWidth="md" fullWidth open={props.open} onClose={()=>{setName("Új kategória"); props.onClose()}}>
			<DialogProgress loading={loading} />
			<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>{setName("Új kategória"); props.onClose()}}>
				<Clear />
			</IconButton>
			<DialogTitle>Új kategória</DialogTitle>
			<DialogContent>
				<Typography variant="body1"style={{padding: '0', fontSize: '14px', color: "#585858"}}>
					Név
				</Typography>
				<Typography color="primary" variant="body1" align="justify" style={{padding: '0', fontSize: '30px'}}>
					<TextEditor inline className="editor title-editor" value={name} onChange={(value)=>setName(value)} />
				</Typography>
				<Divider />
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={()=>{setName("Új kategória"); props.onClose()}}>Mégsem</Button>
				<Button variant="contained" color="secondary" onClick={()=>{
					setLoading(true)
					dispatch(fetchNewServiceGroup({name})).then(()=>{
						props.refreshServices().then(()=>{
							props.onClose()
							setLoading(false)
						})
					})
				}}>Mentés</Button>
			</DialogActions>
		</Dialog>
	)
}

const EditServiceDialog = (props)=>{
	let service = props.service

	const dispatch = useDispatch()

	const [active, setActive] = useState("")
	const [hidden, setHidden] = useState("")
	const [name, setName] = useState("")
	const [description, seTableCell] = useState("")
	const [image, setImage] = useState("")
	const [text, setText] = useState("")
	const [limit, setLimit] = useState("")
	const [type, setType] = useState("")
	const [codeTableName, setCodeTableName] = useState("")
	const [codeDescription, setCodeDescription] = useState("")
	const [needsApproval, setNeedsApproval] = useState("")
	const [waitlistAvailable, setWaitlistAvailable] = useState("")
	const [waitlistPriorityAvailable, setWaitlistPriorityAvailable] = useState("")
	const [waitlistPriorityDescription, setWaitlistPriorityDescription] = useState("")
	const [alwaysAskPriority, setAlwaysAskPriority] = useState("")
	const [radio, setRadio] = useState("")
	const [radio1Text, setRadio1Text] = useState("")
	const [radio2Text, setRadio2Text] = useState("")
	const [radio3Text, setRadio3Text] = useState("")
	const [radio4Text, setRadio4Text] = useState("")
	const [radio5Text, setRadio5Text] = useState("")
	const [cancelable, setCancelable] = useState("")
	const [emailSubject, setEmailSubject] = useState("")
	const [emailText, setEmailText] = useState("")
	const [cancelEmailSubject, setCancelEmailSubject] = useState("")
	const [cancelEmailText, setCancelEmailText] = useState("")
	const [waitlistEmailSubject, setWaitlistEmailSubject] = useState("")
	const [waitlistEmailText, setWaitlistEmailText] = useState("")
	const [signedUpDescription, setSignedUpDescription] = useState("")
	const [hasSubPage, setHasSubPage] = useState("")
	const [subPagePublic, setSubPagePublic] = useState("")
	const [subPageUrl, setSubPageUrl] = useState("")
	const [subPageText, setSubPageText] = useState("")
	const [serviceGroupId, setServiceGroupId] = useState("")

	const [specialOpen, setSpecialOpen] = useState(false)
	const [subPageOpen, setSubPageOpen] = useState(false)
	const [filesOpen, setFilesOpen] = useState(false)
	const [emailsOpen, setEmailsOpen] = useState(false)

	const [confirmCloseOpen, setConfirmCloseOpen] = useState(false)

	const [uploadAsImage, setUploadAsImage] = useState(false)

	const [loading, setLoading] = useState(false)
	const [oldService, setOldService] = useState({})

	const [snackbarOpen, setSnackbarOpen] = useState(false)

	useEffect(()=>{
		if (props.service.id){
			setLoading(true)
			dispatch(fetchServiceFiles(props.service.id))
			dispatch(fetchService(props.service.id)).then(res=>{
				let service = res.payload.data
				service.serviceGroupId = service.servicegroupId
				delete service.servicegroupId
				setOldService(service)
				setActive(service.active)
				setHidden(service.hidden)
				setName(service.name)
				seTableCell(service.description)
				setImage(service.image)
				setText(service.text)
				setLimit(service.limit)
				setType(service.type)
				setCodeTableName(service.codeTableName)
				setCodeDescription(service.codeDescription)
				setNeedsApproval(service.needsApproval)
				setWaitlistAvailable(service.waitlistAvailable)
				setWaitlistPriorityAvailable(service.waitlistPriorityAvailable)
				setWaitlistPriorityDescription(service.waitlistPriorityDescription)
				setAlwaysAskPriority(service.alwaysAskPriority)
				setRadio(service.radio)
				setRadio1Text(service.radio1Text)
				setRadio2Text(service.radio2Text)
				setRadio3Text(service.radio3Text)
				setRadio4Text(service.radio4Text)
				setRadio5Text(service.radio5Text)
				setCancelable(service.cancelable)
				setEmailSubject(service.emailSubject)
				setEmailText(service.emailText)
				setCancelEmailSubject(service.cancelEmailSubject)
				setCancelEmailText(service.cancelEmailText)
				setWaitlistEmailSubject(service.waitlistEmailSubject)
				setWaitlistEmailText(service.waitlistEmailText)
				setSignedUpDescription(service.signedUpDescription)
				setHasSubPage(service.hasSubPage)
				setSubPagePublic(service.subPagePublic)
				setSubPageUrl(service.subPageUrl)
				setSubPageText(service.subPageText)
				setServiceGroupId(service.serviceGroupId)
				setLoading(false)
			})
		}
	}, [props.service])

	useEffect(()=>{
		if (hasSubPage && !subPageUrl){
			let parsed = Parser(name)
			let url
			if (typeof parsed.props.children === "string") url = parsed.props.children
			else if(typeof parsed.props.children.props.children === "string") url = parsed.props.children.props.children
			else url = "uj-szolgaltatas"
			setSubPageUrl(url.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replaceAll(" ", "-"))
		}
			
	}, [hasSubPage])

	const getDifference = ()=>{
		let newService = {
			id: service.id,
			active, 
			hidden,
			name, 
			description, 
			image,
			text,
			emailText,
			emailSubject,
			limit,
			type,
			codeTableName,
			codeDescription,
			needsApproval,
			waitlistAvailable,
			waitlistPriorityAvailable,
			waitlistPriorityDescription,
			alwaysAskPriority,
			radio,
			radio1Text,
			radio2Text,
			radio3Text,
			radio4Text,
			radio5Text,
			cancelable,
			cancelEmailSubject,
			cancelEmailText,
			waitlistEmailSubject,
			waitlistEmailText,
			signedUpDescription,
			subPageUrl,
			hasSubPage,
			subPagePublic,
			subPageText,
			serviceGroupId
		}
		let diff = {}
		Object.keys(newService).map(key=>{
			if (newService[key]!==oldService[key]) diff[key] = newService[key]
		})
		//console.log(props.service.serviceGroupId, newService.serviceGroupId)
		//delete diff.serviceGroupId
		return diff
		//dispatch(saveService(newService))
	}

	const onSaveService = ()=>{
		let diff = getDifference()
		console.log(diff)
		if (!Object.keys(diff).length) return props.onClose()
		setLoading(true)
		dispatch(saveService({...diff, id: props.service.id})).then(()=>{
			props.refreshServices().then(()=>{
				props.onClose()
				setConfirmCloseOpen(false)
				setLoading(false)
			})
		})
	}

	const serviceFiles = useSelector(state=>state.admin.serviceFiles)

	return (
		<Dialog maxWidth="lg" fullWidth open={props.open} onClose={()=>{
			if (Object.keys(getDifference()).length) setConfirmCloseOpen(true)
			else props.onClose()
		}} disableEnforceFocus >
			<DialogProgress loading={loading}/>
			<Dialog open={confirmCloseOpen}>
				<DialogProgress loading={confirmCloseOpen&&loading}/>
				<DialogTitle>Változások elvetése?</DialogTitle>
				<DialogContent>
					Nem mentett változtatásaid vannak. Biztosan elveted ezeket a módosításokat?
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={()=>{props.onClose(); setConfirmCloseOpen(false)}}>
						Változások elvetése
					</Button>
					<Button variant="outlined" color="secondary" onClick={()=>setConfirmCloseOpen(false)}>
						Vissza
					</Button>
					<Button variant="contained" color="secondary" onClick={onSaveService}>
						Mentés és bezárás
					</Button>
				</DialogActions>
			</Dialog>
			<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>{
				if (Object.keys(getDifference()).length) setConfirmCloseOpen(true)
				else props.onClose()
			}}>
				<Clear />
			</IconButton>
			<DialogTitle>
				Szolgáltatás szerkesztése
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={8} style={{maxHeight: 'calc(100vh - 180px)', overflowY: 'scroll', paddingBottom: '20px'}}>
						<Typography variant="body1"style={{padding: '0', fontSize: '14px', color: "#585858"}}>
							Cím
						</Typography>
						<Typography variant="body1" align="justify" style={{padding: '0', fontSize: '20px'}}>
							<TextEditor inline className="service-title-editor" value={name} onChange={value=>setName(value)}/>
						</Typography>
						<Divider />
						<Grid container>
							<Grid xs={4}>
							<FormControlLabel
								style={{margin: '10px 0 0 0'}}
									control={
										<Switch checked={active} onChange={(e, c)=>setActive(c)} />
									}
									label="Elérhető"
								/>
								<br />
								<FormControlLabel
								style={{margin: '0 0'}}
									control={
										<Switch checked={hidden} onChange={(e, c)=>setHidden(c)} />
									}
									label="Rejtett"
								/>
							</Grid>
							<Grid xs={8} className="parser-remove-format">
								<FormControl variant="outlined" fullWidth style={{margin: '10px 0 0 0'}}>
									<InputLabel>Kategória</InputLabel>
									<Select
										value={serviceGroupId}
										onChange={(e) => setServiceGroupId(e.target.value)}
									>
										{ props.serviceGroups.map(serviceGroup => 
											<MenuItem value={serviceGroup.id}>{Parser(serviceGroup.name)}</MenuItem>
										)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						
						<Divider style={{margin: '10px 0'}} />
						<Button variant="contained" color="secondary" onClick={()=>setSpecialOpen(true)}>Speciális beállítások</Button>
						<Button variant="contained" color="secondary" style={{margin: '0 10px'}} onClick={()=>setSubPageOpen(true)}>Aloldal konfigurálása</Button>
						<Button variant="contained" color="secondary" style={{margin: '0'}} onClick={()=>setFilesOpen(true)}>Fileok</Button>
						<Button variant="contained" color="secondary" style={{margin: '0 10px'}} onClick={()=>setEmailsOpen(true)}>Emailek szerkesztése</Button>
						<Divider style={{margin: '10px 0'}} />
						<Typography variant="body1"style={{padding: '0', fontSize: '14px', color: "#585858", marginTop: '15px'}}>
							Rövid leírás
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							<TextEditor className="" value={description} onChange={value=>seTableCell(value)}/>
						</Typography>
						<TextField
							InputProps={{
								endAdornment: <label style={{marginRight: '-8px'}} htmlFor="image-upload">
													<IconButton color="inherit" aria-label="upload picture" component="span">
														<CloudUpload />
													</IconButton>
												</label>,
							}}
							style={{marginTop: '15px'}}
							variant="outlined"
							fullWidth
							label="Kép"
							InputLabelProps={{shrink: Boolean(image)}}
							value={image}
							onChange={(e)=>setImage(e.target.value)}
						/>
						<input accept="image/*" style={{display: 'none'}} id="image-upload" type="file" 
						onChange={()=>{
							const files = document.getElementById("image-upload").files
							const data = new FormData()
							for(let i = 0; i<files.length; i++) {
								data.append('file', files[i])
							}
							dispatch(uploadServiceImage(service.id, data)).then((result)=>{
								console.log(result)
								setImage("/klub/letoltesek/images/" + result.payload.data[0].originalname.replace(`\\`, "/"))
								document.getElementById("image-upload").value=""
							})
						}}/>
						<Typography variant="body1"style={{padding: '0', fontSize: '14px', color: "#585858", marginTop: '10px'}}>
							Hosszú leírás
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							<TextEditor className="" value={text} onChange={value=>setText(value)}/>
						</Typography>
						<Typography variant="body1"style={{padding: '0', fontSize: '14px', color: "#585858", marginTop: '10px'}}>
							Szöveg csak jelentkezetteknek
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							<TextEditor className="" value={signedUpDescription} onChange={value=>setSignedUpDescription(value)}/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<div style={{width: '360px', margin: '0 auto'}}>
							<h3>Kártya előnézet</h3>
							
							<Kartya service={{...service,
								name,
								description,
								image,
								text,
								active,
								limit,
								type,
								needsApproval,
								waitlistAvailable,
								waitlistPriorityAvailable,
								waitlistPriorityDescription,
								alwaysAskPriority,
								radio,
								radio1Text,
								radio2Text,
								radio3Text,
								radio4Text,
								radio5Text,
								cancelable,
								signedUpDescription,
								subPageUrl,
								hasSubPage,
								subPagePublic,
								subPageText
								}} />
							<OutlinedInput
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={()=>{
												document.getElementById("kartya-url").select()
												document.execCommand("copy")
												setSnackbarOpen(true)
											}}
										>
											<FileCopy />
										</IconButton>
									</InputAdornment>
								}
								value={'https://itmp.hu/klub/kartya/' + service.id}
								style={{color: '#000', marginTop: '15px', maxWidth: '100%', width: '600px', left: '50%', transform: 'translateX(-50%)', paddingRight: '5px'}}
								readOnly
								className="readonly-input"
								id="kartya-url"
								inputProps={{style:{textAlign: 'center', color: '#000'}}}
								onClick={(e)=>{
									document.getElementById("kartya-url").select()
							}}/>
						</div>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="secondary" onClick={onSaveService}>Mentés</Button>
			</DialogActions>

			<Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={(e,r)=>{if (r!=="clickaway")setSnackbarOpen(false)}} message="Vágólapra másolva">
			</Snackbar>

			<Dialog maxWidth="xs" fullWidth open={specialOpen} onClose={()=>setSpecialOpen(false)} disableEnforceFocus>
				<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>setSpecialOpen(false)}>
					<Clear />
				</IconButton>
				<DialogTitle>Speciális beállítások</DialogTitle>
				<DialogContent>
					<FormControlLabel
						style={{margin: '0px 0 10px 0'}}
						control={
							<Switch checked={limit!==null} onChange={(e, c)=>setLimit(c ? 100 : null)} />
						}
						label="Létszámlimit"
					/>
					<div style={{overflow: 'hidden', paddingTop: limit!==null ? '5px' : 0, height: limit!==null ? (waitlistAvailable) ? (waitlistPriorityAvailable) ? '285px' :'156px' :'110px' : 0, transition: 'all 0.1s ease-in-out'}}>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{shrink: true}}
							onBlur={(e)=>{
								if (!Boolean(Number(e.target.value))) setLimit(null)
							}}
							label="Létszámlimit"
								value={limit}
								onChange={(e)=>setLimit(e.target.value)}
						/>
						<FormControlLabel
							style={{margin: '10px 0 0 0'}}
							control={
								<Switch checked={waitlistAvailable} onChange={(e, c)=>setWaitlistAvailable(c)} />
							}
							label="Várólistás"
						/>
						<br/>
						<FormControlLabel
							style={{margin: '10px 0 10px 0'}}
							control={
								<Switch checked={waitlistPriorityAvailable} onChange={(e, c)=>setWaitlistPriorityAvailable(c)} />
							}
							label="Várólista-prioritás lehetőség"
						/>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{shrink: true}}
							onBlur={(e)=>{
								if (!waitlistPriorityDescription) setWaitlistPriorityAvailable(false)
							}}
							label="Prioritás-checkbox szöveg"
							value={waitlistPriorityDescription}
							onChange={(e)=>setWaitlistPriorityDescription(e.target.value)}
						/>
						<FormControlLabel
							style={{margin: '15px 0 0 0'}}
							control={
								<Switch checked={alwaysAskPriority} onChange={(e, c)=>setAlwaysAskPriority(c)} />
							}
							label="A checkbox mindig megjelenik (akkor is, ha van még hely)"
						/>
					</div>
					<Divider style={{margin: '10px 0'}} />
					<FormControlLabel
						style={{margin: '10px 0 0 0'}}
						control={
							<Switch checked={needsApproval} onChange={(e, c)=>setNeedsApproval(c)} />
						}
						label="Jelentkezés után admin általi jóváhagyás szükséges"
					/>
					<Divider style={{margin: '10px 0 0 0'}} />
					<FormControlLabel
						style={{margin: '10px 0 0 0'}}
						control={
							<Switch checked={cancelable} onChange={(e, c)=>setCancelable(c)} />
						}
						label="A jelentkezés visszavonható"
					/>
					<Divider style={{margin: '10px 0 0 0'}} />
					<FormControlLabel
						style={{margin: '10px 0 0 0'}}
						control={
							<Switch checked={type===1} onChange={(e, c)=>setType(c ? 1 : 0)} />
						}
						label="Kuponkódok"
					/>
					<div style={{overflow: 'hidden', paddingTop: type===1 ? '5px' : 0, height: type===1 ? '238px' : 0, transition: 'all 0.1s ease-in-out'}}>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{shrink: true}}
							onBlur={(e)=>{
								if (!codeTableName) setType(0)
							}}
							label="Kódokat tartalmazó adatbázis tábla neve"
								value={codeTableName}
								onChange={(e)=>setCodeTableName(e.target.value)}
						/>
						<Typography variant="body1" style={{padding: '0', fontSize: '14px', color: "#585858", marginTop: '10px'}}>
							Útmutató a kód beváltásához
						</Typography>
						<TextEditor init={{height: 150}} className="" value={codeDescription} onChange={value=>setCodeDescription(value)}/>
					</div>
					<Divider style={{margin: '10px 0'}} />
					<Button style={{marginBottom: '15px'}} fullWidth variant="contained" color="secondary" onClick={()=>setSpecialOpen(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>

			<Dialog maxWidth="md" fullWidth open={subPageOpen} onClose={()=>setSubPageOpen(false)} disableEnforceFocus>
				<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>setSubPageOpen(false)}>
					<Clear />
				</IconButton>
				<DialogTitle>Aloldal beállításai</DialogTitle>
				<DialogContent>
					<FormControlLabel
						style={{margin: '0px 0 0 0'}}
						control={
							<Switch checked={hasSubPage} onChange={(e, c)=>setHasSubPage(c)} />
						}
						label="Aloldal engedélyezése"
					/>
					<div style={{overflow: 'hidden', paddingTop: hasSubPage ? '15px' : 0, height: hasSubPage ? '485px' : 0, transition: 'all 0.1s ease-in-out'}}>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{shrink: true}}
							onBlur={(e)=>{
								if (!subPageUrl) setHasSubPage(false)
							}}
							label="Aloldal URL-je"
							value={subPageUrl}
							onChange={(e)=>setSubPageUrl(e.target.value)}
						/>
						<FormControlLabel
							style={{margin: '10px 0 0 0'}}
							control={
								<Switch checked={subPagePublic} onChange={(e, c)=>setSubPagePublic(c)} />
							}
							label="Publikus"
						/>
						<Typography variant="body1" style={{padding: '0', fontSize: '14px', color: "#585858", marginTop: '10px'}}>
							Tartalom
						</Typography>
						<TextEditor init={{height: 350}} className="" value={subPageText} onChange={value=>setSubPageText(value)}/>
					</div>
					<Divider style={{margin: '10px 0'}} />
					<Button style={{marginBottom: '15px'}} fullWidth variant="contained" color="secondary" onClick={()=>setSubPageOpen(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>

			<Dialog maxWidth="md" fullWidth open={emailsOpen} onClose={()=>setEmailsOpen(false)} disableEnforceFocus>
				<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>setEmailsOpen(false)}>
					<Clear />
				</IconButton>
				<DialogTitle>Emailek szerkesztése</DialogTitle>
				<DialogContent>
					<Typography variant="body1" style={{padding: '0', fontSize: '18px', color: "#000", marginBottom: '10px'}}>
						Email jelentkezéskor
					</Typography>
					<TextField
						variant="outlined"
						fullWidth
						InputLabelProps={{shrink: true}}
						onBlur={(e)=>{
							//if (!emailSubject) setHasSubPage(false)
						}}
						label="Tárgy"
						value={emailSubject}
						onChange={(e)=>setEmailSubject(e.target.value)}
					/>
					<TextEditor noUrlConvert init={{height: 350}} className="" value={emailText} onChange={value=>setEmailText(value)}/>
					<Divider style={{margin: '10px 0'}} />
					<Typography variant="body1" style={{padding: '0', fontSize: '18px', color: "#000", marginBottom: '10px'}}>
						Email visszavonáskor
					</Typography>
					<TextField
						variant="outlined"
						fullWidth
						InputLabelProps={{shrink: true}}
						onBlur={(e)=>{
							//if (!emailSubject) setHasSubPage(false)
						}}
						label="Tárgy"
						value={cancelEmailSubject}
						onChange={(e)=>setCancelEmailSubject(e.target.value)}
					/>
					<TextEditor noUrlConvert init={{height: 350}} className="" value={cancelEmailText} onChange={value=>setCancelEmailText(value)}/>
					<Divider style={{margin: '10px 0'}} />
					{waitlistAvailable ? 
					<>
						<Typography variant="body1" style={{padding: '0', fontSize: '18px', color: "#000", marginBottom: '10px'}}>
							Email várólistára kerüléskor
						</Typography>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{shrink: true}}
							onBlur={(e)=>{
								//if (!emailSubject) setHasSubPage(false)
							}}
							label="Tárgy"
							value={waitlistEmailSubject}
							onChange={(e)=>setWaitlistEmailSubject(e.target.value)}
						/>
						<TextEditor noUrlConvert init={{height: 350}} className="" value={waitlistEmailText} onChange={value=>setWaitlistEmailText(value)}/>
						<Divider style={{margin: '10px 0'}} />
					</> : null }
					<Button style={{marginBottom: '15px'}} fullWidth variant="contained" color="secondary" onClick={()=>setEmailsOpen(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>

			<Dialog maxWidth="md" fullWidth open={filesOpen} onClose={()=>setFilesOpen(false)} disableEnforceFocus>
				<IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>setFilesOpen(false)}>
					<Clear />
				</IconButton>
				<DialogTitle>Fileok kezelése</DialogTitle>
				<DialogContent>
				
				<input type="file" class="form-control" multiple id="file-uploader" onChange={(e)=>{
					let files = e.target.files
					let filesOutput = ""
					for(let i = 0; i<files.length; i++) {
						if (i!==0) filesOutput += ", "
						filesOutput += files[i].name
					}
					document.getElementById("files-output").value=filesOutput
				}} style={{display: 'none'}} />

				<Grid container style={{marginBottom: '15px', paddingRight: '15px'}}>
					<Grid item xs={8}>
						<TextField
							onClick={()=>document.getElementById("file-uploader").click()}
							InputProps={{
								endAdornment: <label style={{marginRight: '-14px'}} htmlFor="file-uploader">
													<Button  style={{width: '215px', height: '40px'}} variant="outlined" color="primary">Fileok kiválasztása</Button>
												</label>,
								readOnly: true,
								disabled: true
							}}
							id="files-output"
							size="small"
							style={{marginTop: '0'}}
							variant="outlined"
							fullWidth
							defaultValue={""}
							placeHolder="Nincs kiválasztva fájl"
						/>
					</Grid>
					<Grid item xs={1}>
						<FormControlLabel
							style={{margin: '0'}}
							control={<Checkbox checked={uploadAsImage} onChange={(e)=>setUploadAsImage(e.target.checked)}/>}
							label="Kép"
						/>
					</Grid>
					<Grid item xs={3}>
						<Button fullWidth style={{height: '40px', lineHeight: '30px', marginLeft: '15px'}} onClick={()=>{
							const files = document.getElementById("file-uploader").files
							const data = new FormData()
							if (!files.length) return
							for(let i = 0; i<files.length; i++) {
								data.append('file', files[i])
							}
							let uploadAction = (uploadAsImage) ? uploadServiceImage : uploadServiceFile
							dispatch(uploadAction(props.service.id, data)).then(()=>{
								dispatch(fetchServiceFiles(props.service.id))
								setUploadAsImage(false)
								document.getElementById("file-uploader").value=""
								document.getElementById("files-output").value=""
							})
						}} variant="contained" color="secondary"><CloudUpload style={{marginRight: '10px'}} />Feltöltés</Button>
					</Grid>
				</Grid>
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Név</TableCell>
									<TableCell>URL</TableCell>
									{/* <TableCell>Fájl a lemezen</TableCell> */}
									<TableCell align="right" width={50}>Méret</TableCell>
									<TableCell align="right" width={20}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{serviceFiles.map((serviceFile, key)=>{
									let origin = window.location.origin
									let publicPath = (serviceFile.image) ? `${origin}/klub/letoltesek/images/${serviceFile.filename}` : `${origin}/klub/szolgaltatasok/${subPageUrl}/letoltesek/${serviceFile.filename}`
									return <TableRow key={key}>
												<TableCell>{serviceFile.filename}</TableCell>
												<TableCell><a href={publicPath} target="_blank">{publicPath}</a></TableCell>
												{/* <TableCell>{serviceFile.path}</TableCell> */}
												<TableCell align="right">{Math.round(serviceFile.size/1024)} KB</TableCell>
												<TableCell align="right">
													<IconButton
														style={{padding: '5px'}}
														onClick={() => {
															if (window.confirm("File törlése?")) {
																dispatch(deleteServiceFile(props.service.id, serviceFile.id)).then(() => {
																	dispatch(fetchServiceFiles(props.service.id))
																})
															}
														}}
													><Delete /></IconButton>
												</TableCell>
											</TableRow>
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Button style={{margin: '25px 0 15px 0'}} fullWidth variant="contained" color="secondary" onClick={()=>setFilesOpen(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>

		</Dialog>
	)
}

export default AdminSzolgaltatasok