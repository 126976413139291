export const checkEmailTaken = (email)=>({
    type: 'FETCH_CHECK_EMAIL_TAKEN',
    payload: {
        request: {
            url: '/user/checkemailtaken',
            method: 'post',
            data: {email}
        }
    }
})

export const fetchSchools = ()=>({
    type: 'FETCH_SCHOOLS',
    payload: {
        request: {
            url: '/schools',
            method: 'get'
        }
    }
})

export const resetEmailTaken = (email)=>({
    type: 'RESET_EMAIL_TAKEN'
})

export const createUser = (data)=>({
    type: 'FETCH_CREATE_USER',
    payload: {
        request: {
            url: '/user/create',
            method: 'post',
            data
        }
    }
})

export const createCompany = (data)=>({
    type: 'FETCH_CREATE_COMPANY',
    payload: {
        request: {
            url: '/company/create',
            method: 'post',
            data
        }
    }
})

export const fetchCityName = (zip)=>({
    type: 'FETCH_CITY_NAME',
    payload: {
        request: {
            url: '/util/zip?q=' + zip,
            method: 'get',
        }
    }
})

export const verifyActivationCode = (q)=>({
    type: 'FETCH_VERIFY_ACTIVATION_CODE',
    payload: {
        request: {
            url: '/user/verifyactivationcode',
            method: 'post',
            data: {q}
        }
    }
})

export const verifyCompletionCode = (q)=>({
    type: 'FETCH_VERIFY_COMPLETION_CODE',
    payload: {
        request: {
            url: '/user/verifycompletioncode',
            method: 'post',
            data: {q}
        }
    }
})

export const verifyResetCode = (q)=>({
    type: 'FETCH_VERIFY_RESET_CODE',
    payload: {
        request: {
            url: '/user/verifyresetcode',
            method: 'post',
            data: {q}
        }
    }
})

export const setPassword = (q, password)=>({
    type: 'FETCH_SET_PASSWORD',
    payload: {
        request: {
            url: '/user/setpassword',
            method: 'post',
            data: {q, password}
        }
    }
})

export const completeSignup = (q, data)=>({
    type: 'FETCH_COMPLETE_SIGNUP',
    payload: {
        request: {
            url: '/user/complete-signup',
            method: 'post',
            data: {q, ...data}
        }
    }
})

export const userLogin = (email, password)=>({
    type: 'FETCH_LOGIN',
    payload: {
        request: {
            url: '/user/login',
            method: 'post',
            data: {email, password}
        }
    }
})

export const userAuthRequest = (url)=>({
    type: 'FETCH_AUTH_REQUEST',
    payload: {
        request: {
            url: '/user/auth-request',
            method: 'post',
            data: {url}
        }
    }
})

export const passwordResetRequest = (email, password)=>({
    type: 'FETCH_PASSWORD_REQUEST_RESET',
    payload: {
        request: {
            url: '/user/password-reset-request',
            method: 'post',
            data: {email}
        }
    }
})

export const resetPassword = (code, password)=>({
    type: 'FETCH_RESET_PASSWORD',
    payload: {
        request: {
            url: '/user/password-reset',
            method: 'post',
            data: {q: code, password}
        }
    }
})

export const toggleStaySignedIn = ()=>({
    type: 'TOGGLE_STAY_SIGNED_IN'
})

export const logout = (email)=>({
    type: 'LOGOUT'
})

export const fetchServices = ()=>({
    type: 'FETCH_SERVICES',
    payload: {
        request: {
            url: '/services',
            method: 'get'
        }
    }
})

export const fetchMaterials = ()=>({
    type: 'FETCH_MATERIALS',
    payload: {
        request: {
            url: '/materials',
            method: 'get'
        }
    }
})

export const fetchSubPageContent = (url)=>({
    type: 'FETCH_SUBPAGE_CONTENT',
    payload: {
        request: {
            url: '/materials/services/' + url,
            method: 'get'
        }
    }
})

export const fetchProfile = ()=>({
    type: 'FETCH_PROFILE',
    payload: {
        request: {
            url: '/profile',
            method: 'get'
        }
    }
})

export const saveProfile = (profile)=>({
    type: 'FETCH_SAVE_PROFILE',
    payload: {
        request: {
            url: '/profile',
            method: 'put',
            data: profile
        }
    }
})

export const serviceSignup = (id, waitlistPriority=false, radioAnswer=null)=>({
    type: 'FETCH_SERVICE_SIGNUP',
    payload: {
        request: {
            url: '/services/signup',
            method: 'post',
            data: {serviceId: id, waitlistPriority, radioAnswer}
        }
    }
})

export const cancelService = (id)=>({
    type: 'FETCH_CANCEL_SERVICE',
    payload: {
        request: {
            url: '/services/cancel',
            method: 'post',
            data: {serviceId: id}
        }
    }
})

export const closeServiceFullDialog = (email)=>({
    type: 'CLOSE_SERVICE_FULL_DIALOG'
})

export const fetchServiceFiles = (id)=>({
    type: 'FETCH_SERVICE_FILES',
    payload: {
        request: {
            url: '/services/' + id + '/files',
            method: 'get'
        }
    }
})

export const fetchDownloadToken = ()=>({
    type: 'FETCH_DOWNLOAD_TOKEN',
    payload: {
        request: {
            url: '/materials/downloadtoken',
            method: 'get'
        }
    }
})

