import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const Mentorcegek = (props)=>{

	const highlghted = {
		text: "Kiemelt támogatónk:",
		images: [
			{url: "https://aliz.ai", src: "/img/mentorcegek/aliz.png", center: true, highlghted: true},
		]
	}

	const images = [
		{divider: true},
		{url: "http://netacademia.hu", src: "/img/mentorcegek/netacademia.png"},
		{url: "http://microsoft.hu", src: "/img/mentorcegek/microsoft.png"},
		{url: "http://logmein.hu", src: "/img/mentorcegek/logmein.png"},
		{url: "https://www.greenfoxacademy.com", src: "/img/mentorcegek/gfa.png", center: true},
		{divider: true},
		/* {url: "http://www.doctusoft.hu", src: "/img/mentorcegek/docusoft.png"}, */
		{url: "https://thesmart.academy", src: "/img/mentorcegek/thesmartacademy.png"},
		{url: "http://searchexplained.com", src: "/img/mentorcegek/searchexplained.png"},
		{url: "http://www.liligo.com", src: "/img/mentorcegek/liligo.png"},
		{url: "http://www.justdoo.com", src: "/img/mentorcegek/justdoo.png"},
		{url: "http://www.dolphio.hu", src: "/img/mentorcegek/dolphio.png"},
		{url: "http://gizmodo.com/careers", src: "/img/mentorcegek/gizmodo.png"},
		{url: "http://www.bull.hu", src: "/img/mentorcegek/atos.png"},
		{url: "http://imind.hu", src: "/img/mentorcegek/imind.png"},
		{url: "http://powerednow.com", src: "/img/mentorcegek/powered_now.png"},
		{url: "http://www.p92rdi.hu", src: "/img/mentorcegek/p92rdi.png"},
		{url: "http://www.epam.com", src: "/img/mentorcegek/epam.png"},
		{url: "http://handinscan.com", src: "/img/mentorcegek/handinscan.png"},
		{url: "http://cloudera.com", src: "/img/mentorcegek/cloudera.png"},
		{url: "http://dexter.hu", src: "/img/mentorcegek/dexter.png"},
		{url: "http://joszaki.hu/", src: "/img/mentorcegek/joszaki.png"},
		{url: "http://www.webstar.hu", src: "/img/mentorcegek/webstar.png"},
		{url: "http://gamesforbusiness.eu", src: "/img/mentorcegek/gfb.png"},
		{url: "http://symbion.hu", src: "/img/mentorcegek/symbion.png"},
		{url: "http://bt.com", src: "/img/mentorcegek/bt.png"},
		{url: "http://different.hu", src: "/img/mentorcegek/diffdev.png"},
		{url: "http://ixenit.com/hu/kezdolap", src: "/img/mentorcegek/ixenit.png"},
	]

	return (
		<div style={{padding: '0 0 50px 0', backgroundColor: 'rgba(54,127,138,0.2)'}} className="container">
			<Grid container spacing={12} style={{backgroundColor: 'rgba(246,142,84,0.15)', padding: '50px 0 20px 0'}}>
				<Grid item xs={12} style={{padding: '0 20px'}}>
					<Typography align="center" variant="h6" style={{fontWeight: 700, color: "#000"}}>{highlghted.text}</Typography>
				</Grid>
				{highlghted.images.map(image=>{
					if (image.divider) return <Grid item xs={12}><Divider style={{marginBottom: '20px'}} /></Grid>
					return (
						<>
						{ image.center && <Grid item xs={0} md={4} /> }
						<Grid item xs={12} md={4} style={{padding: '20px'}}>
							<a href={image.url} target="_blank" rel="noopener noreferrer">
								<img src={image.src} alt="" style={{width: '80%', display: 'block', margin: 'auto'}}/>
							</a>
						</Grid>
						</>
					)
				})}
			</Grid>
			<Grid container spacing={12}>
				{images.map(image=>{
					if (image.divider) return <Grid item xs={12}><Divider style={{marginBottom: '20px'}} /></Grid>

					return (
						<>
						{ image.center && <Grid item xs={0} md={4} /> }
						<Grid item xs={12} md={4} style={{padding: '20px'}}>
							<a href={image.url} target="_blank" rel="noopener noreferrer">
								<img src={image.src} alt="" style={{width: '80%', display: 'block', margin: 'auto'}}/>
							</a>
						</Grid>
						</>
					)
				})}
			</Grid>
		</div>
	)
}

export default Mentorcegek