import React from 'react';
import Chip from '@material-ui/core/Chip';

export const Chips = (props) => {
	const service = props.service;
	return <>
		{service.full && !service.signedUp ?
			<Chip color={'secondary'} label={'Betelt'} style={{ marginBottom: '10px', marginRight: '10px' }} />
			: null}

		{((service.signedUp && !service.onWaitlist) || (!service.full && !service.signedUp && service.active)) ?
			<Chip color={(service.signedUp) ? (service.approved || service.rejected) ? ((service.approved) ? 'primary' : 'secondary') : 'secondary' : 'default'} label={(service.signedUp) ? (service.approved || service.rejected) ? ((service.approved) ? 'Jelentkezve' : 'Elutasítva') : 'Jóváhagyás alatt' : 'Elérhető'} style={{ marginBottom: '10px', marginRight: '10px' }} />
			: null}
		{(!service.active && !service.signedUp && !service.onWaitlist) ?
			<Chip variant="outlined" color={'secondary'} label={'Nem elérhető'} style={{ marginBottom: '10px', marginRight: '10px' }} />
			: null}
		{service.full && service.waitlistAvailable && !service.signedUp && service.active ?
			<Chip variant="outlined" color={'primary'} label={'Várólista elérhető'} style={{ marginBottom: '10px', marginRight: '10px' }} />
			: null}
		{service.onWaitlist ?
			<Chip color={'primary'} label={'Várólistán'} style={{ marginBottom: '10px', marginRight: '10px' }} />
			: null}
		{!service.signedUp && service.needsApproval && !service.full ?
			<Chip variant="outlined" label="Jóváhagyás szükséges" style={{ marginBottom: '10px', color: '#777' }} />
			: null}
	</>;
};
