import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import Button from '@material-ui/core/Button'
import KlubLogoImg from '../../img/klub-logo.png'
import SaveAlt from '@material-ui/icons/SaveAlt'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCompanies } from './actions'
import MaterialTable from 'material-table'

const localization = {
	header: {
		actions: ""
	},
	pagination: {
		labelDisplayedRows: '{from}-{to}, összesen {count}',
		labelRowsSelect: "sor"
	},
	body: {
		emptyDataSourceMessage: 'Nincs találat',
		filterRow: {
			filterTooltip: 'Filter'
		}
	},
	toolbar: {
		searchTooltip: "Keresés",
		searchPlaceholder: "Keresés",
		exportName: "Exportálás CSV-ként"
	}
}

const AdminCegek = ()=>{
	const dispatch = useDispatch()
	const companies = useSelector(state=>state.admin.companies)
	const jwt = useSelector(state=>state.jwt)
	const serverUrl = useSelector(state=>state.serverUrl)
	const [loadingCompanies, setLoadingCompanies] = useState(false)
	useEffect(()=>{
		setLoadingCompanies(true)
		dispatch(fetchCompanies()).then(()=>{
			setLoadingCompanies(false)
		})
	}, [])
	return (
		<>
		<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
			<Grid container spacing={2} style={{marginBottom: '30px', position: 'relative'}}>
				<Grid item xs={12}>
					<Link to="/klub/admin">
						<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
							<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
							Vissza
						</Button>
					</Link>
					
					<Typography variant="h3" style={{color: '#f68e54', fontWeight: '500', marginTop: '10px'}}>
						Cégek
						<a href={serverUrl + "/admin/users/export?token="+jwt}>
							<IconButton style={{marginLeft: '20px', padding: '7px 7px 12px 7px'}} variant="outlined" size="large" color="secondary">
								<SaveAlt style={{color: '#f68e54', height: '40px', width: '40px'}} />
							</IconButton>
						</a>
					</Typography>
					<img src={KlubLogoImg} style={{height: '120px', marginTop: '0', marginLeft: '10%', position: 'absolute', top: '15px', right: 0}} alt=""/>
				</Grid>
				<Grid item xs={12} md={12} style={{}}>
					<MaterialTable 
						columns={[
							{title: '#', field: 'id', width: 40, type: 'numeric'},
							{
								title: 'Név',
								field: 'name',
								width: 180,
								render: (company)=>{return <a target="_blank" rel="noopener noreferrer" href={(company.website.indexOf('http://')===-1&&company.website.indexOf('https://')===-1) ? "http://" + company.website : company.website}>{company.name}</a>}
							},
							{title: 'Kapcsolattartó', field: 'contactName', width: 140},
							{
								title: 'Email',
								field: 'contactEmail',
								width: 280,
								render: (company)=>{return <a href={"mailto:" + company.contactEmail}>{company.contactEmail}</a>}
							},
							{
								title: 'Telefon',
								field: 'contactPhone',
								width: 130
							},
							{
								title: 'Regisztrált',
								field: 'createdAt',
								width: 170,
								render: (company)=>{return new Date(company.createdAt).toLocaleString()},
							}
						  ]}
						  actions={[
							{
								icon: 'edit',
								tooltip: 'Szerkesztés',
								disabled: true,
								onClick: (event, rowData) => alert("Szerk. " + rowData.id)
							}
						  ]}
						  options={{
							paging: false,
							tableLayout: "fixed",
							actionsColumnIndex: -1,
							search: false,
							toolbar: false,
						  }}
						  data={companies}
						  title="Jelentkezett cégek"
						  localization={localization}
						  isLoading={companies.length===0||loadingCompanies}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default AdminCegek