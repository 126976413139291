import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import { userLogin, toggleStaySignedIn, passwordResetRequest } from './actions'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'


const useStyles = (error) => {
	return makeStyles ({
		logoArea: {
			width: '100%',
			height: '130px',
			backgroundColor: '#367f8a'
		},
		logo: {
			display: 'block',
			height: '80px',
			margin: '25px 0 0 24px'
		},
		dialogContent: {
			position: 'relative', 
			padding: 0, 
            overflowX: 'hidden',
            height: error ? '655px' : '635px'
		},
		dialogPaper: {
			margin: 0,
			width: 'calc(100% - 20px)',
			maxHeight: 'calc(100% - 20px)'
        },
        staySignedInCheckbox: {
            marginLeft: '-5px'
        },
		gridWrapper: {
            padding: '8px 24px', 
            width: '100%', 
            boxSizing: 'border-box',
        }
	})()
}

const Login = (props)=>{
	

	const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
	const staySignedIn = useSelector(state=>state.staySignedIn)
	
	const [emailError, setEmailError] = React.useState(null)
	const [passwordError, setPasswordError] = React.useState(false)
	const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false)
	const dispatch = useDispatch()

	const onSubmit = (e)=>{
		e.preventDefault()
		dispatch(userLogin(email, password)).then(res=>{
			if (res.error){
				if (res.error.response.data.message==='WRONG_EMAIL') setEmailError('Helytelen email cím')
				else if (res.error.response.data.message==='NOT_ACTIVATED') setEmailError('Ez a fiók még nincs aktiválva.')
				else if (res.error.response.data.message==='WRONG_PASSWORD') setPasswordError(true)
			} else {
				//alert(res.payload.data.jwt)
			}
		})
	}

	const onPasswordResetSubmit = (e)=>{
		e.preventDefault()
		dispatch(passwordResetRequest(email)).then(res=>{
			if (res.error&&res.error.response&&res.error.response.data){
				if (res.error.response.data.message==='WRONG_EMAIL') setEmailError('Helytelen email cím')
				else if (res.error.response.data.message==='NOT_ACTIVATED') setEmailError('Ez a fiók még nincs aktiválva.')
				else if (res.error.response.data.message==='WRONG_PASSWORD') setPasswordError(true)
			} else {
				setResetPasswordOpen(false)
				props.passwordResetRequestSuccess()
			}
		})
	}
	
	const classes = useStyles(passwordError || Boolean(emailError))

	const loggedIn = useSelector(state=>Boolean(state.jwt))
    if (loggedIn&&props.open) {
		if (props.location && props.location.state && props.location.state.autoOpen) return <Redirect to={{pathname: "/klub/szolgaltatasok", state: {autoOpen: props.location.state.autoOpen}}} />
		else return <Redirect to="/klub/" />
	}

	return (
			<>
			<Dialog onClose={()=>setResetPasswordOpen(false)} fullWidth maxWidth="xs" open={resetPasswordOpen} PaperProps={{className: classes.dialogPaper}}>
				<DialogContent className={classes.dialogContent} style={{paddingTop: '10px', height: '200px'}}>
					<form onSubmit={onPasswordResetSubmit}>
						<div className={classes.gridWrapper}>
							<Typography variant="h6" component="h2" style={{marginBottom: '15px'}}>Jelszó helyreállítása</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12}> 
									<TextField 
										label="Email cím"
										variant="outlined"
										margin="none"
										value={email}
										onChange={(e)=>{setEmail(e.target.value); setEmailError(null)}}
										fullWidth
										required
										autoComplete="email"
										error={Boolean(emailError)}
										helperText={emailError}
									/>
								</Grid>
								<Grid item xs={6}>
									<Button onClick={()=>setResetPasswordOpen(false)} variant="outlined" fullWidth color="secondary" size="large">
										Mégsem
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button type="submit" onClick={()=>{}} variant="contained" fullWidth color="secondary" size="large">
										Küldés
									</Button>
								</Grid>
							</Grid>
						</div>
					</form>
				</DialogContent>
			</Dialog>
			<Dialog onClose={props.onClose} fullWidth maxWidth="xs" open={props.open} PaperProps={{className: classes.dialogPaper}} on>
				{/* <div className={classes.logoArea}>
					<img src={ItmpLogo} alt="ITMP" className={classes.logo}/>
				</div> */}
				<DialogContent className={classes.dialogContent} style={{paddingTop: '10px'}}>
					<form onSubmit={onSubmit}>
						<div className={classes.gridWrapper}>
						{/* <Typography variant="h6" component="h2" style={{marginBottom: '15px'}}>ITMP Klub</Typography> */}
						<Typography variant="h6" component="h2" style={{marginBottom: '15px'}}>Bejelentkezés</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12}> 
									<TextField 
										label="Email cím"
										variant="outlined"
										margin="none"
										value={email}
										onChange={(e)=>{setEmail(e.target.value); setEmailError(null)}}
										fullWidth
										required
										autoComplete="email"
										error={Boolean(emailError)}
										helperText={emailError}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Jelszó"
										variant="outlined"
										margin="none"
										value={password}
										onChange={(e)=>{setPassword(e.target.value); setPasswordError(false)}}
                                        fullWidth
										type="password"
										required
										autoComplete="password"
										error={passwordError}
										helperText={(passwordError) ? 'Helytelen jelszó' : null}
									/>
								</Grid>
								<Typography style={{paddingLeft: '15px'}}>
									<a className="underline" href="#" onClick={()=>setResetPasswordOpen(true)}>Elfelejtett jelszó</a>
								</Typography>
                                <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
									<FormControlLabel
                                        className={classes.staySignedInCheckbox}
										control={
											<Checkbox checked={staySignedIn} onChange={()=>dispatch(toggleStaySignedIn())} value="staySignedIn" />
										}
										label={"Maradjak bejelentkezve"}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button type="submit" onClick={()=>{}} variant="contained" fullWidth color="secondary" size="large">
										Bejelentkezés
									</Button>
								</Grid>
							</Grid>
							<Divider style={{margin: '20px 0'}}/>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h6" component="h2" style={{marginBottom: '15px'}}>Még nincs fiókod?</Typography>
									<Typography style={{marginBottom: '15px'}}>Jelentkezz most, hogy kiélvezd a klubtagság számtalan előnyét!</Typography>
									<Typography>
										<span style={{color: 'red'}}>Fontos figyelmeztetés:</span> a klubba csak informatika tanárok regisztrációját várjuk,
										diákok jelentkezését nem tudjuk fogadni!
									</Typography>
								</Grid>
								<Grid item xs={7}>
									<Button onClick={()=>{props.onClose(); props.history.push('/tanar-vagyok')}} variant="outlined" fullWidth color="secondary" size="large">
										Tudj meg többet
									</Button>
								</Grid>
								<Grid item xs={5}>
									<Button onClick={()=>{props.onClose(); props.openSignup()}} variant="contained" fullWidth color="secondary" size="large">
										Jelentkezem
									</Button>
								</Grid>
							</Grid>
						</div>
					</form>
				</DialogContent>
			</Dialog>
			</>
	)
}

export default Login;