import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { Chips } from "./Chips";

export const KartyaDialog = (props) => {
	const service = props.service;
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
	const [waitlistPriority, setWaitlistPriority] = useState(service.waitlistPriority);
	const [radioAnswer, setRadioAnswer] = useState(service.radioAnswer);
	
	return (<Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={props.onClose} PaperProps={{ style: { margin: '10px', width: '100%' } }}>
		<DialogTitle>
			<span  className="kartya-title kartya-dialog">
				{service.name ? Parser(service.name) : null}
			</span>
			<IconButton aria-label="close" style={{ position: 'absolute', right: '7px', top: '7px' }} onClick={props.onClose}>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
		<DialogContent style={{paddingTop: '0px'}}>
			<img alt="" src={service.image} style={{ width: 'calc(100% + 48px)', marginBottom: '10px', marginLeft: '-24px' }} />
			<Chips service={service} />
			{service.type === 1 && service.approved ? <div>
				<Typography variant="h6" style={{ marginBottom: '5px' }} align="center">
					A te egyedi kódod:
					</Typography>
				<OutlinedInput value={service.code} fullWidth readOnly inputProps={{ style: { textAlign: 'center' } }} onFocus={(e) => e.target.select()} />
				<Typography variant="body1" style={{ margin: '15px 0' }}>
					{Parser(service.codeDescription)}
				</Typography>
				<Divider />
			</div> : null}
			{service.hasSubPage && service.subPageUrl && service.signedUp ?
				<Link to={"/klub/szolgaltatasok/" + service.subPageUrl} onClick={()=>{window.scrollTo(0,0); window.setTimeout(()=>window.scrollTo(0,0), 500)}}><Button fullWidth variant="contained" color="secondary" size="large" style={{ margin: '20px 0 15px 0' }}>
					Tartalom megtekintése
					</Button></Link>
				: null}
			{service.full && service.waitlistAvailable && !service.signedUp && service.active ?
				<><Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
					<b>A regisztrációs létszám betelt, várólistára lehet jelentkezni.</b>
				</Typography><Divider /></> : null}
			{!service.active && !service.signedUp ?
				<><Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
					<b>Erre a szolgáltatásra már nem tudunk több jelentkezést fogadni.</b>
				</Typography><Divider /></> : null}
			{service.signedUp && !service.onWaitlist && service.signedUpDescription ?
				<><Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
					{Parser(service.signedUpDescription)}
				</Typography><Divider /></> : null}
			<Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
				{service.description ? Parser(service.description) : null}
			</Typography>
			<Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
				{service.text ? Parser(service.text) : null}
			</Typography>
			{!service.active && !service.signedUp ?
				<><Divider /><Typography variant="body1" style={{ margin: '15px 0 10px 0' }}>
					<b>Erre a szolgáltatásra már nem tudunk több jelentkezést fogadni.</b>
				</Typography></> : null}
			{service.radio && service.active ?
				<>
					<RadioGroup value={radioAnswer} onChange={(e) => setRadioAnswer(e.target.value)}>
						<FormControlLabel value="1" disabled={service.signedUp} control={<Radio />} label={service.radio1Text} />
						<FormControlLabel value="2" disabled={service.signedUp} control={<Radio />} label={service.radio2Text} />
						{service.radio3Text ?
							<FormControlLabel value="3" disabled={service.signedUp} control={<Radio />} label={service.radio3Text} /> : null}
						{service.radio3Text ?
							<FormControlLabel value="4" disabled={service.signedUp} control={<Radio />} label={service.radio4Text} /> : null}
						{service.radio3Text ?
							<FormControlLabel value="5" disabled={service.signedUp} control={<Radio />} label={service.radio5Text} /> : null}
					</RadioGroup>
				</>
				: null}
			{service.waitlistPriorityAvailable && (service.full || service.alwaysAskPriority) && service.active ?
				<>
					{service.radio ? <Divider style={{ margin: '10px 0' }} /> : null}
					<FormControlLabel control={<Checkbox checked={waitlistPriority} onChange={(e, c) => setWaitlistPriority(c)} color="secondary" disabled={service.signedUp} />} label={service.waitlistPriorityDescription} />
				</>
				: null}
			{(!service.signedUp && service.active)
				?
				<Button disabled={(service.full && !service.waitlistAvailable) || (service.radio && !radioAnswer)} onClick={() => setConfirmOpen(true)} fullWidth variant="contained" color="secondary" size="large" style={{ margin: '20px 0 15px 0' }}>
					{service.full ? (service.waitlistAvailable) ? 'Várólistára jelentkezem' : 'Betelt' : 'Jelentkezem'}
				</Button>
				: null}
			{(service.signedUp && service.cancelable) ?
				<Button onClick={() => setCancelConfirmOpen(true)} fullWidth variant="outlined" color="secondary" size="large" style={{ margin: '20px 0 15px 0' }}>Jelentkezés visszavonása</Button>
				: null}
			<Dialog onClose={() => setConfirmOpen(false)} open={confirmOpen} maxWidth="xs" fullWidth style={{ zIndex: 99999 }}>
				<DialogTitle>Jelentkezés megerősítése</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Biztosan szeretnél jelentkezni erre a szolgáltatásra?
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setConfirmOpen(false)} size="large" color="secondary" variant="outlined">
						Mégsem
						</Button>
					<Button onClick={() => { props.signUp(waitlistPriority, radioAnswer); setConfirmOpen(false); }} size="large" color="secondary" variant="contained">
						Jelentkezem
						</Button>
				</DialogActions>
			</Dialog>
			<Dialog onClose={() => setCancelConfirmOpen(false)} open={cancelConfirmOpen} maxWidth="xs" fullWidth style={{ zIndex: 99999 }}>
				<DialogTitle>Visszavonás megerősítése</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Biztosan vissza szeretnéd vonni jelentkezésedet erre a szolgáltatásra?
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCancelConfirmOpen(false)} size="large" color="secondary" variant="outlined">
						Mégsem
						</Button>
					<Button onClick={() => { props.cancelService(); setCancelConfirmOpen(false); }} size="large" color="secondary" variant="contained">
						Visszavonom
						</Button>
				</DialogActions>
			</Dialog>
		</DialogContent>
	</Dialog>);
};
