import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SetPassword from './SetPassword'
import ResetPassword from './ResetPassword'
import CompleteSignup from './CompleteSignup'
import PopupAuth from './PopupAuth'

import Home from './Home'

import { Provider } from 'react-redux'
import store from './store'


const theme = createMuiTheme({
	palette: {
		primary: {main: '#367f8a'},
		secondary: {main: '#f55'},
	}
})

const App = ()=>{

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Router basename="/">
					<Switch>
						<Route path="/jelentkezes-folytatas" exact component={CompleteSignup} />
						<Route path="/jelentkezes-veglegesites" exact component={SetPassword} />
						<Route path="/jelszo-helyreallitas" exact component={ResetPassword} />
						<Route path="/auth" exact component={PopupAuth} />
						<Route path="/" component={Home} />
					</Switch>
				</Router>
			</ThemeProvider>
		</Provider>
	)
}

export default App