import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import DropdownTextField from './DropdownTextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector, useDispatch } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha"

import { fetchSchools, checkEmailTaken, fetchCityName, createUser } from './actions'
import { Redirect } from 'react-router-dom'

const useStyles = (currentStep, error=false) => {
    let animTime = '0.2s'
	let gridWrapper = {
		position: 'absolute', 
		overflow: 'hidden', 
		padding: '8px 24px', 
		width: '100%', 
		boxSizing: 'border-box', 
		left: (currentStep===0) ? 0 : '-100%'
	}
	return makeStyles ({
		logoArea: {
			width: '100%',
			height: '130px',
			backgroundColor: '#367f8a'
		},
		logo: {
			display: 'block',
			height: '80px',
			margin: '25px 0 0 24px'
		},
		dialogContent: {
			position: 'relative', 
			height: (error) ? '510px' : '490px',
			overflowY: (currentStep===2) ? 'hidden' : 'auto',
			padding: 0, 
			overflowX: 'hidden',
		},
		dialogPaper: {
			margin: 0,
			width: 'calc(100% - 20px)',
			maxHeight: 'calc(100% - 20px)'
		},
		privacyPolicyCheckbox: {
			paddingLeft: '5px'
		},
		loading: {
			left: 0,
			width: '100%',
			overflow: 'hidden',
			position: 'absolute',
			boxSizing: 'border-box',
			backgroundColor: 'rgba(255, 255, 255, 0.6)', //'rgba(0,0,0,0.35)',
			height: '100%',
			zIndex: '1000',
			top: '0'
		},
		progress: {
			position: 'absolute',
			left: 'calc(50% - 20px)',
			top: 'calc(50% - 20px)',
		},
		gridWrapper,
		gridWrapper1: {
			...gridWrapper,
			left: (currentStep===1) ? 0 : (currentStep<1) ? '100%' : '-100%'
		},
		gridWrapper2: {
			...gridWrapper,
			left: (currentStep===2) ? 0 : (currentStep<2) ? '100%' : '-100%',
			overflowY: 'hidden'
		},
		gridWrapper3: {
			...gridWrapper,
			left: (currentStep===3) ? 0 : (currentStep<3) ? '100%' : '-100%'
		},
	})()
}

const recaptchaRef = React.createRef()

const Signup = (props)=>{
	const [currentStep, setCurrentStep] = useState(0)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')
	const [schoolOm, setSchoolOm] = useState('')
	const [school, setSchool] = useState(null)
	const [personalId, setPersonalId] = useState('')
	const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
	
	const [loading, setLoading] = useState(false)

	const [emailTaken, setEmailTaken] = useState(false)
	const [selectSchoolError, setSelectSchoolError] = useState(false)

	const [recaptcha, setRecaptcha] = useState(false)

	const schoolOptions = useSelector(state=>state.schools)
	const dispatch = useDispatch()

	const classes = useStyles(currentStep, emailTaken + selectSchoolError)

	const nextStep = ()=>{
		if (currentStep===0){
			setLoading(true)
			dispatch(checkEmailTaken(email)).then(res=>{
				setLoading(false)
				if(res.payload.data.taken) return setEmailTaken(true)
				else setCurrentStep(currentStep+1)
			})
		}
		else if (currentStep===1){
			
			if (!school) return setSelectSchoolError(true)
			else {
				setLoading(true)
				recaptchaRef.current.execute()
			}
			return
		}
		else setCurrentStep(currentStep+1)
	}

	useEffect(()=>{
		if (recaptcha) {
			let data = {
				recaptcha,
				firstName,
				lastName,
				email,
				phone,
				zip,
				city,
				schoolOm,
				personalId,
				schoolId: school
			}
			dispatch(createUser(data)).then(()=>{
				setLoading(false)
				setCurrentStep(2)
			})
		}
	}, [recaptcha])

	useEffect(()=>{
		dispatch(fetchSchools())
	}, [dispatch])

	useEffect(()=>{
		if (zip.length>=4){
			dispatch(fetchCityName(zip)).then(res=>{
				setCity(res.payload.data.name)
			})
		}
	}, [zip, dispatch])

	const loggedIn = useSelector(state=>Boolean(state.jwt))
//    if (loggedIn) return <Redirect to="/klub" />

	return (
			<Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onClose} PaperProps={{className: classes.dialogPaper}}>
				{/* <div className={classes.logoArea}>
					<img src={ItmpLogo} alt="ITMP" className={classes.logo}/>
				</div> */}
				{loading ? <div className={classes.loading}>
					<CircularProgress className={classes.progress} color="secondary" />
				</div> : null}
				<DialogTitle>ITMP Klub - Jelentkezés</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					
					<form onSubmit={(e)=>{e.preventDefault(); nextStep()}}>
						<div className={classes.gridWrapper}>

							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography>
										<span style={{color: 'red'}}>Fontos figyelmeztetés:</span> a klubba csak informatika tanárok regisztrációját várjuk,
										diákok jelentkezését nem tudjuk fogadni!
									</Typography>
								</Grid>
								<Grid item xs={6}> 
									<TextField 
										label="Vezetéknév"
										variant="outlined"
										margin="none"
										value={lastName}
										onChange={(e)=>{setLastName(e.target.value)}}
										fullWidth
										required
										name="lname"
										autoComplete="family-name"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField 
										label="Utónév"
										variant="outlined"
										margin="none"
										value={firstName}
										onChange={(e)=>{setFirstName(e.target.value)}}
										fullWidth
										required
										name="fname"
										autoComplete="given-name"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Email cím"
										variant="outlined"
										margin="none"
										value={email}
										onChange={(e)=>{setEmail(e.target.value); setEmailTaken(false)}}
										fullWidth
										type="email"
										required
										name="email"
										autoComplete="email"
										error={emailTaken}
										helperText={(emailTaken) ? "Ez az email cím már foglalt." : null}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Telefonszám"
										variant="outlined"
										margin="none"
										value={phone}
										onChange={(e)=>{setPhone(e.target.value)}}
										fullWidth
										type="tel"
										required
										name="phone"
										autoComplete="tel"
									/>
								</Grid>
								<Grid item xs={5}> 
									<TextField 
										label="Irányítószám"
										variant="outlined"
										margin="none"
										value={zip}
										onChange={(e)=>{setZip(e.target.value)}}
										fullWidth
										inputProps={{inputMode: "numeric"}}
										required
										name="zip"
										autoComplete="postal-code"
										type="number"
										onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
									/>
								</Grid>
								<Grid item xs={7}>
									<TextField 
										label="Település"
										variant="outlined"
										margin="none"
										value={city}
										onChange={(e)=>{setCity(e.target.value)}}
										fullWidth
										required
										name="city"
										autoComplete="address-level2"
									/>
								</Grid>
								<Grid item xs={6}>
									<Button onClick={()=>props.onClose()} variant="outlined" fullWidth color="secondary" size="large">
										Mégsem
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button type="submit" variant="contained" fullWidth color="secondary" size="large">
										Tovább
									</Button>
								</Grid>
							</Grid>
						</div>
					</form>
					<form onSubmit={(e)=>{e.preventDefault(); nextStep()}}>
						<div className={classes.gridWrapper1}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField 
										label="Iskola OM azonosítója"
										variant="outlined"
										margin="none"
										value={schoolOm}
										onChange={(e)=>{setSchoolOm(e.target.value)}}
										fullWidth
										inputProps={{inputMode: "numeric"}}
										required
										type="number"
										onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
									/>
								</Grid>
								<Grid item xs={12}>
                                    <DropdownTextField
										label="Iskola"
										options={schoolOptions}
										required
										onChange={(value)=>{setSchool(value); setSelectSchoolError(false)}}
										error={selectSchoolError}
										helperText={selectSchoolError ? 'Kérjük, válassz iskolát a listából' : null}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Személyes oktatási azonosító"
										variant="outlined"
										margin="none"
										value={personalId}
										onChange={(e)=>{setPersonalId(e.target.value)}}
										fullWidth
										inputProps={{inputMode: "numeric"}}
										required
										type="number"
										onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControlLabel
										className={classes.privacyPolicyCheckbox}
										control={
											<Checkbox checked={privacyPolicyChecked} onChange={()=>setPrivacyPolicyChecked(!privacyPolicyChecked)} value="privacyPolicy" />
										}
										label={<span>Elolvastam és elfogadom az <a href="https://itmp.hu/ITMP-adatkezelesi-tajekoztato.pdf" target="_blank" rel="noopener">Adatkezelési Tájékoztató</a>ban foglaltakat.</span>}
									/>
									<ReCAPTCHA onChange={setRecaptcha} ref={recaptchaRef} sitekey="6LdDGrAUAAAAABeGI_8gfOFrmxXsancRH6C5Khjy" size="invisible"/>
								</Grid>
								<Grid item xs={6}>
									<Button onClick={()=>{setCurrentStep(0); setSelectSchoolError(false)}} variant="outlined" fullWidth color="secondary" size="large">
										Vissza
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button disabled={!privacyPolicyChecked} onClick={()=>{}} type="submit" variant="contained" fullWidth color="secondary" size="large">
										Regisztráció
									</Button>
								</Grid>
							</Grid>
						</div>
					</form>
					<div className={classes.gridWrapper2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<svg width="80px" height="80px" viewBox="0 0 400 400" style={{display: 'block', margin: '30px auto 20px', fill: '#367f8a'}}>
									<g>
										<path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77
											C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769
											C373.768,295.816,295.812,373.77,199.996,373.77z"/>
										<path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003
											c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842
											c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546
											C285.828,129.402,277.523,129.402,272.406,134.526z"/>
									</g>
								</svg>
								<Typography align="center" variant="h5" color="textPrimary" paragraph>
									Köszönjük jelentkezésedet!
								</Typography>
								<Typography align="center" variant="body1" color="textPrimary">
									Jelentkezésedet sikeresen megkaptuk.
									<br />
									Adataidat ellenőrizzük, ez néhány munkanapot vesz igénybe, utána e-mailben értesítünk a további lépésekről. 
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button onClick={()=>{props.onClose()}} variant="outlined" fullWidth color="secondary" size="large">
									Bezárás
								</Button>
							</Grid>
						</Grid>
					</div>
				</DialogContent>
			</Dialog>
	)
}

export default Signup;