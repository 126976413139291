import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchServices, closeServiceFullDialog } from '../actions'
import Parser from 'html-react-parser';
import  { ItmpH2, ItmpBody } from '../ItmpTypography'
import SzolgaltatasokImg from '../img/tanfolyamok.png'
import { Kartya } from './Kartya';

const Szolgaltatasok = (props)=>{

	const dispatch = useDispatch()
	const services = useSelector(state=>state.services)

	useEffect(()=>{
		dispatch(fetchServices())
	}, [])
	const serviceFullDialogOpen = useSelector(state=>state.serviceFullDialogOpen)

	return (
		<>
		<ServiceFullDialog service={{}} open={serviceFullDialogOpen} onClose={()=>dispatch(closeServiceFullDialog())}/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Link to="/klub">
						<Button style={{marginTop: '10px'}} variant="outlined" size="large" color="secondary">
							<KeyboardBackspace style={{marginRight: '10px', marginLeft: '-7px'}} />
							Vissza a Klub nyitóoldalára
						</Button>
					</Link>
				</Grid>
				<Grid item md={9} xs={12} style={{marginBottom: '-10px'}}>
					<ItmpH2 style={{fontSize: '45px'}}>Szolgáltatások</ItmpH2>
					<Typography variant="body1" color="inherit">
						A klubtagsággal igénybe vehető, <b>teljesen ingyenes szolgáltatások</b> köre folyamatosan bővülni fog, de az alábbiakra mindenképpen számíthatsz: 
						<ul>
							<li>
								egyéni tanulásra alkalmas online kurzuscsomagokhoz juthasz hozzá 
							</li>
							<li>
								a legkiválóbb szakemberek által tartott tantermi oktatássorozaton fejlesztheted tovább tudásodat 
							</li>
							<li>
								webinárokon, meetupokon és konferenciákon vehetsz részt
							</li>
						</ul>
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<img src={SzolgaltatasokImg} style={{width: '100%', marginTop: '40px'}} alt=""/>
				</Grid>
				
				
				{services.filter(serviceGroup=>serviceGroup.services.length).map(serviceGroup=>{
					return (
						<>
							<Grid item xs={12} style={{marginBottom: '-10px'}}>
							<Typography color="primary" variant="body1" align="justify" style={{padding: '30px 0 0 10px', fontSize: '30px'}} className="editor title-editor">
								{/* <Typography variant="h6" color="inherit"> */}
									{Parser(serviceGroup.name)}
								</Typography>
							</Grid>
							{serviceGroup.services.sort((a, b) => (a.order>b.order) ? 1 : -1).map(service=>{
								return (
									<Grid item md={(serviceGroup.services.length%4===0) ? 4 : 4} xs={12}>
										<Kartya
											service={service}
											autoOpen={props.location.state && props.location.state.autoOpen==service.id}
										/>
									</Grid>
								)
							})}
						</>
					)
				})}
			</Grid>
		</>
	)
}

const ServiceFullDialog = (props)=>{
	const service = props.service
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
		>
		<DialogTitle>Ez a szolgáltatás betelt</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Sajnos erre a szolgáltatásra nem tudunk több jelentkezést fogadni.
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose} color="primary" autoFocus>
				OK
			</Button>
		</DialogActions>
	  </Dialog>
	)
}

export default Szolgaltatasok